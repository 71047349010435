import React, { useEffect, useState } from "react";
import img1 from "../../webflowimages/hero-image-1.png";

import appimg from "../../webflowimages/app-store.c8032fb40c95347c535dc2e01fd9de80.svg";
import appimg2 from "../../webflowimages/play-store.d559e139c90be1ea1ade3ccdd21f26ee.svg";

import img2 from "../../webflowimages/hero-image-1-p-500.png";
import img3 from "../../webflowimages/hero-image-1-p-800.png";
import img4 from "../../webflowimages/hero-image-1-p-1080.png";
// import img5 from '.../../webflowimages/hero-image-1-p-1600.png';
import img6 from "../../webflowimages/hero-image-1.png";

import logoimg from "../../webflowimages/curebay_logo.png";
import img500 from "../../webflowimages/1-p-500.png";
import img600 from "../../webflowimages/1.png";
import playstore from "../../webflowimages/play-store.d559e139c90be1ea1ade3ccdd21f26ee.svg";
import appstore from "../../webflowimages/app-store.c8032fb40c95347c535dc2e01fd9de80.svg";
import Frame27 from "../../webflowimages/Frame-27.svg";
import { APP_ROUTES } from "../../constants/approutes";
import Footer from "../../components/webflow/footer";
import { useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import services from "../../store/services";
import { getReviewsContent } from "../../store/actions/reviews";
import { getDoctorslist } from "../../store/actions/doctors";
import {
  fetchDoctorsData,
  getDoctorsSliderDetails,
} from "../../store/actions/doctors";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import arrowicon from "../../webflowimages/arrow-right-1.svg";
import Carouselnew from "react-multi-carousel";
import { Dialog } from "primereact/dialog";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@mui/material";
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";
import { Carousel } from "primereact/carousel";
import axios from "axios";
import notfoundpage from "../../assets/images/notfoundpage.svg";
import { useDispatch } from "react-redux";
import { getToken } from "../../store/actions/useractions";
import vdoimg from "../../assets/images/vdoimg.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Homepagenew = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tokenFromURL = urlSearchParams.get("refresh_token");
  const [titanAuthprogress, setTitanAuthProgress] = useState({
    loading: true,
    authStatus: false,
  });
  const [reviewsData, setReviewsData] = useState([]);
  const [datapartner, setDatapartner] = useState([]);
  const [list, setList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-100);
  const [doctordata, setDoctordata] = useState();
  const navigate = useNavigate();
  const [screen, setscreen] = useState(window.innerWidth);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchToken() {
      dispatch(getToken());
    }
    fetchToken();
  }, [dispatch]);

  useEffect(() => {
    async function docList() {
      const res = await getDoctorslist();
      if (res?.status === 200 && res?.data) {
        setList(res?.data);
      }
    }
    docList();

    async function docData() {
      const res = await fetchDoctorsData();
      if (res?.status === 200 && res?.data?.data[0]) {
        setDoctordata(res?.data?.data[0]);
      }
    }
    docData();
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const homePageContent = useSelector(
    (state) => state.HomePageReducer.homePageContent
  );

  console.log(homePageContent, "videoUrl");
  const { statisticsCount } = useSelector((state) => state.HomePageReducer);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide4, setCurrentSlide4] = useState(0);
  const [currentSlide1, setCurrentSlide1] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const reviewResponse = await getReviewsContent();
      if (
        reviewResponse.status === 200 &&
        Array.isArray(reviewResponse?.data?.data)
      ) {
        setReviewsData(reviewResponse.data.data[0]);
        // nloop = (reviewResponse?.data?.data[0]?.reviewsData?.attributes?.ThirdComponent?.video_content?.length)
      }
    }
    fetchData();
  }, []);

  const slides = [
    {
      title: homePageContent?.data?.[0]?.attributes?.seccomp?.Title,
      description: homePageContent?.data?.[0]?.attributes?.seccomp?.description,
      imgUrl: homePageContent?.data?.[0]?.attributes?.seccomp?.img_url,
      alt: "Accessing Health Status Reports on CureBay Totalcare App",
    },
    {
      title: homePageContent?.data?.[0]?.attributes?.seccomp?.Title2,
      description:
        homePageContent?.data?.[0]?.attributes?.seccomp?.description2,
      imgUrl: homePageContent?.data?.[0]?.attributes?.seccomp?.img_url2,
      alt: "Virtually connecting with a team of specialist from CureBay Totalcare App",
    },
    {
      title: homePageContent?.data?.[0]?.attributes?.seccomp?.Title3,
      description:
        homePageContent?.data?.[0]?.attributes?.seccomp?.description3,
      imgUrl: homePageContent?.data?.[0]?.attributes?.seccomp?.img_url3,
      alt: "Monitoring Health Status Report on Real Time basis through CureBay Totalcare App",
    },
  ];

  const handleAccoridan = (index) => {
    if (index == selectedIndex) {
      setSelectedIndex(-1000);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleLeftArrowClick = () => {
    if (currentSlide === 0) {
      setCurrentSlide(slides.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (currentSlide === slides.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const slides4 = [
    {
      iframeSrc:
        "https://www.youtube.com/embed/r2mXub7WPkU?si=IuIQfxpiuhGcGFJS",
    },
    {
      iframeSrc:
        "https://www.youtube.com/embed/r2mXub7WPkU?si=ZrHp_eV5B_8FBXjL",
    },
  ];

  // const handleLeftArrowClick4 = () => {
  //   if (currentSlide4 === 0) {
  //     setCurrentSlide4(slides4.length - 1);
  //   } else {
  //     setCurrentSlide4(currentSlide4 - 1);
  //   }
  // };

  // const handleRightArrowClick4 = () => {
  //   if (currentSlide4 === slides4.length - 1) {
  //     setCurrentSlide4(0);
  //   } else {
  //     setCurrentSlide4(currentSlide4 + 1);
  //   }
  // };

  const handleLeftArrowClick4 = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + slides4.length) % slides4.length
    );
  };

  const handleRightArrowClick4 = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides4.length);
  };

  const slides1 = [
    {
      title: homePageContent?.data?.[0]?.attributes?.thrdcomp?.title,
      description:
        homePageContent?.data?.[0]?.attributes?.thrdcomp?.description,
      imgUrl: homePageContent?.data?.[0]?.attributes?.thrdcomp?.image_url,
      alt: "CureBay Staff Nurse checking vitals of the patient's at a CureBay eClinic",
    },
    {
      title: homePageContent?.data?.[0]?.attributes?.thrdcomp?.title2,
      description:
        homePageContent?.data?.[0]?.attributes?.thrdcomp?.description2,
      imgUrl: homePageContent?.data?.[0]?.attributes?.thrdcomp?.image_url2,
      alt: "A senior Partner Doctor & Staff nurse monitoring health status of a patient",
    },
    {
      title: homePageContent?.data?.[0]?.attributes?.thrdcomp?.title3,
      description:
        homePageContent?.data?.[0]?.attributes?.thrdcomp?.description3,
      imgUrl: homePageContent?.data?.[0]?.attributes?.thrdcomp?.image_url3,
      alt: "Trained Curebay Totalcare eClinic staffs",
    },
  ];

  const handleLeftArrowClick1 = () => {
    if (currentSlide1 === 0) {
      setCurrentSlide1(slides1.length - 1);
    } else {
      setCurrentSlide1(currentSlide1 - 1);
    }
  };

  const handleRightArrowClick1 = () => {
    if (currentSlide1 === slides1.length - 1) {
      setCurrentSlide1(0);
    } else {
      setCurrentSlide1(currentSlide1 + 1);
    }
  };

  const slides2 = [
    {
      imgUrl: homePageContent?.data?.[0]?.attributes?.Tenth?.image_url1,
    },
    {
      imgUrl: homePageContent?.data?.[0]?.attributes?.Tenth?.image_url2,
    },
    {
      imgUrl: homePageContent?.data?.[0]?.attributes?.Tenth?.image_url1,
    },
  ];

  const handleLeftArrowClick2 = () => {
    if (currentSlide2 === 0) {
      setCurrentSlide2(slides2.length - 1);
    } else {
      setCurrentSlide2(currentSlide2 - 1);
    }
  };

  const handleRightArrowClick2 = () => {
    if (currentSlide2 === slides2.length - 1) {
      setCurrentSlide1(0);
    } else {
      setCurrentSlide2(currentSlide2 + 1);
    }
  };

  useEffect(() => {
    const getAllData = async () => {
      const res = await services.get("/service-partners?populate=deep");
      setDatapartner(res?.data?.data);
    };
    getAllData();
  }, []);
  const eliteDoc = list.filter((value) => (value.doctorType = "null"));

  const onReadMoreClick = (plan_id) => {
    switch (plan_id) {
      case 1:
        navigate(APP_ROUTES.GENERAL_HEALTH);
        break;
      case 4:
        navigate(APP_ROUTES.CANCERSCREENING_CARE);
        break;
      case 3:
        navigate(APP_ROUTES.DIABETICS_CARE);
        break;
      case 5:
        navigate(APP_ROUTES.ORTHOPEDICS_CARE);
        break;
      case 6:
        navigate(APP_ROUTES.WOMENSHEALTH_CARE);
        break;
      default:
        break;
    }

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const [getdoctordata, setGetdoctorData] = useState([]);
  const getAllData = async () => {
    const res = await services.get("doctors-pages?populate=deep");
    setGetdoctorData(res?.data?.data[0].attributes);
  };
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const [individualdocDescription, setindividualdocDescription] = useState();
  const [visible, setVisible] = useState(false);
  const individualDescription = (e) => {
    setindividualdocDescription(e);
    setVisible(true);
  };

  const productTemplate = (value) => {
    return (
      <div className="flex gap-4">
        <div role="listitem" className="w-dyn-item">
          <div className="card testimonial-card mr-2">
            <img
              src={value?.image_url}
              alt={value?.alt}
              className="avatar-image sm:h-[200px];
                    sm:w-[200px]"
            />
            <div className="mg-bottom-8px">
              <div className="text-200 bold color-neutral-800">
                {value?.name}{" "}
              </div>
            </div>
            <div className="text-seegreen font-bold">{value?.specliality} </div>
            <div className="text-seegreen font-bold">
              {" "}
              <span className="">
                {" "}
                Experience: {""}
                {value?.experience}
              </span>{" "}
            </div>

            <div className=" font-bold color-neutral-700 text-sm">
              {" "}
              <span className="">
                {" "}
                Qualification: {""}
                {value?.qualification}
              </span>{" "}
            </div>

            <div className="text-200 medium color-accent-1">
              <strong className="bold-text-6">
                <div className="sm:h-14 h-auto overflow-hidden">
                  <span className="">{value?.description}</span>
                </div>
                <div className="sm:block hidden mt-4 text-center text-base">
                  <span
                    onClick={() => individualDescription(value?.description)}
                    className="text-blue font-bold cursor-pointer text-center"
                  >
                    Read more...
                  </span>
                </div>
              </strong>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(title === "Yearly" ? true : false);
    const handleOnClick = () => {
      setIsActive(!isActive);
      // setShow(!show)
    };
    return (
      <div
        className="accordion-item-wrapper v2 "
        style={{ display: "block", borderTop: "0" }}
      >
        <div
          className="flex cursor-pointer justify-between items-center md:py-2 w-full"
          onClick={handleOnClick}
        >
          <div class="accordion-content-wrapper v2">
            {" "}
            <div class="accordion-header">
              {" "}
              <h2 class="accordion-title display-4 text-left">{title}</h2>
            </div>
          </div>
          <div className="md:flex justify-end md:text-3xl text-sm font-semibold  text-brand-primary sm:w-[44px]">
            {/* <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}> */}
            {isActive ? (
              <div className="accordion-side right-side">
                <div className="accordion-icon-wrapper">
                  <div className="accordion-btn-line v2 horizontal"></div>
                </div>
              </div>
            ) : (
              <div className="accordion-side right-side">
                <div className="accordion-icon-wrapper">
                  <div className="accordion-btn-line v2 vertical"></div>
                  <div className="accordion-btn-line v2 horizontal"></div>
                </div>
              </div>
            )}
            {/* </IconButton> */}
          </div>
        </div>
        {isActive ? <div className="w-full text-left">{content}</div> : null}
      </div>
    );
  };
  const redirectToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.curebay.totalcare",
      "_blank"
    );
  };

  const redirectToAppStore = () => {
    window.open(
      "https://apps.apple.com/in/app/curebay-medicare/id6459267188",
      "_blank"
    );
  };

  // titan integration part

  // const handletitanAuthorization = async () => {
  //   // setTitalAuthLoading(true)
  //   let selectedpatient = localStorage.getItem("selectedpatient");
  //   let requestBody = {};
  //   requestBody = {
  //     refresh_token: tokenFromURL,
  //     patientCode: selectedpatient
  //   };
  //   let titanres = await axios
  //     .post(
  //       `${process.env.REACT_APP_MEDICARE_BASEURL}titan/token`,
  //       requestBody
  //     )
  //   console.log(titanres, "response1")
  //   console.log(titanres?.data, "response2")
  //   console.log(titanres?.data?.responseMessage, "response3")
  //   console.log(titanres?.data?.responseCode, "response4")
  //   if (titanres?.data?.responseCode == '200') {

  //     setTitanAuthProgress({ ...titanAuthprogress, authStatus: true })
  //     localStorage.removeItem("selectedpatient");
  //   }
  //   setTitanAuthProgress({ ...titanAuthprogress, loading: false })
  // };

  //new code
  const handletitanAuthorization = async () => {
    let selectedpatient = localStorage.getItem("selectedpatient");
    let requestBody = {
      refresh_token: tokenFromURL,
      patientCode: selectedpatient,
    };
    try {
      let titanres = await axios.post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}titan/token`,
        requestBody
      );
      console.log(titanres, "response1");
      console.log(titanres?.data, "response2");
      console.log(titanres?.data?.responseMessage, "response3");
      console.log(titanres?.data?.responseCode, "response4");
      if (titanres?.data?.responseCode === "200") {
        setTitanAuthProgress({ authStatus: true, loading: false });
        localStorage.removeItem("selectedpatient");
      } else {
        console.error(
          "Titan authorization failed:",
          titanres?.data?.responseMessage
        );
      }
    } catch (error) {
      console.error("Error faced during Titan authorization:", error);
    }
  };
  useEffect(() => {
    if (tokenFromURL) {
      handletitanAuthorization();
    }
  }, [tokenFromURL]);

  // if (tokenFromURL && !titanAuthprogress.loading) {
  //   console.log(titanAuthprogress.loading, titanAuthprogress.authStatus, "loading")
  //   return <div className="pt-[100px] text-center">
  //     <img src={notfoundpage} alt="" className="w-100 h-100" />
  //     {titanAuthprogress.authStatus ? <p className=" text-2xl font-bold text-[#013f71]">Authrization Complete</p> : <p className=" text-2xl font-bold text-[#013f71]">Something went wrong please try after sometime</p>}
  //     <button onClick={() => navigate(APP_ROUTES.MAINDASHBOARD)} className="bg-[#013f71]  text-white px-[28px] py-[8px] rounded-2xl">Go to Home</button>
  //   </div>
  // }

  //new
  if (tokenFromURL && !titanAuthprogress.loading) {
    console.log(
      titanAuthprogress.loading,
      titanAuthprogress.authStatus,
      "loading"
    );
    return (
      <div className="pt-[100px] text-center">
        <img src={notfoundpage} alt="" className="w-100 h-100" />
        {titanAuthprogress.authStatus ? (
          <p className="text-2xl font-bold text-[#013f71]">
            Authorization Complete
          </p>
        ) : (
          <p className="text-2xl font-bold text-[#013f71]">
            Something went wrong, please try after some time
          </p>
        )}
        <button
          onClick={() => navigate(APP_ROUTES.MAINDASHBOARD)}
          className="bg-[#013f71] text-white px-[28px] py-[8px] rounded-2xl"
        >
          Go to Home
        </button>
      </div>
    );
  }

  // if (tokenFromURL && titanAuthprogress.loading) {
  //   return <div className="pt-[100px] text-center">
  //     <div className="fixed inset-0 flex items-center justify-center z-50">
  //       <div className="absolute inset-0 bg-gray-900 opacity-75" />
  //       <div className="relative">
  //         <div className="w-20 h-20 border-4 border-t-4 border-gray-200 rounded-full animate-spin" />
  //       </div>
  //     </div>
  //   </div>
  // }

  //new
  if (tokenFromURL && titanAuthprogress.loading) {
    return (
      <div className="pt-[100px] text-center">
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
          <div className="relative">
            <div className="w-20 h-20 border-4 border-t-4 border-gray-200 rounded-full animate-spin" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="page-wrapper">
        <section className="home-banner min-h-[70vh] my-8 ">
          <div className="text-center bg-[#BFE4FF] ">
            <h4 className="text-[#004171] sm:text-xl text-sm font-medium text-center mb-0 py-[10px]">
              Our Care plans start from{" "}
              <span className="text-[#49B37C]">₹1,000</span> Monthly
            </h4>
          </div>
          <div className="container mx-auto" style={{ marginTop: "0px" }}>
            <div className="bg-[#004171]  absolute left-0 md:w-[70%] min-h-[75vh] sm:rounded-e-xl"></div>
            <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center">
              <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 sm:rounded-2xl bg-[#004171]">
                <span className=" text-secondaryColor text-sm md:text-base font-medium  mb-1 md:mb-3">
                  {homePageContent?.data?.[0]?.attributes?.FirstComp?.Heading}
                </span>
                <h1 className="  text-white text-2xl md:text-[45px] lg-text-[48px] leading-[1.176em] font-bold mb-5 mt-4">
                  {homePageContent?.data?.[0]?.attributes?.FirstComp?.name}
                </h1>
                <p className="text-white  font-extralight text-sm md:text-base mt-2">
                  {
                    homePageContent?.data?.[0]?.attributes?.FirstComp
                      ?.description1
                  }
                </p>
                <div className="sm:buttons-row flex sm:items-center gap-3 my-7">
                  {/* <a
                      href={APP_ROUTES.NEWAPPOINMENT}
                      className="btn-primary button-row "
                    >
                      <span className="doctr-custom-icon btn-icon-left"></span>
                      Book a Health Assessment
                    </a> */}
                  <a href={APP_ROUTES.CAREPLANNEW} className="btn-secondary2  ">
                    <span className="doctr-custom-icon btn-icon-left"></span>
                    Care Plans
                  </a>
                </div>
              </div>
              {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                              <div className="w-full md:w-[90%] h-[300px] md:h-[350px] rounded banner-bg"></div>
                          </div> */}
              <div className="z-1 relative flex sm:justify-end justify-center ">
                <div className="mt-10 md:mt-0 md:w-[90%]">
                  <div className="relative z-[10]">
                    <video
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
                      className="w-full rounded-[20px] "
                      src={
                        homePageContent?.data?.[0]?.attributes?.FirstComp
                          ?.videoUrl
                      }
                      controls="controls"
                      allowFullScreen
                      poster={vdoimg}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="swipicon">
                          <img src={Swipicon} alt="images" />
                      </div> */}
          </div>
        </section>

        <section className="home-v1-hero-wrapper ">
          <div className="container-default position-relative oldplandesign">
            <div className="section hero home-v1">
              <div className="w-layout-grid grid-2-columns home-hero-grid-2-col v1">
                <div
                  data-w-id="3f79dbeb-9853-ec21-8eda-6185c24f2e29"
                  // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                  className="home-hero-picture v1 sm:hidden block"
                  data-aos="fade-left"
                >
                  <img
                    src={
                      homePageContent?.data?.[0]?.attributes?.FirstComp
                        ?.image_url
                    }
                    alt=""
                    className="fit-cover _w-h-100"
                  />
                </div>

                <div
                  id="w-node-_3f79dbeb-9853-ec21-8eda-6185c24f2e0b-4d0e8e3c"
                  data-aos="fade-right"
                  // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                  className="inner-container minw-480px _100---tablet"
                >
                  <div className="subtitle left-align sm:mt-8">
                    {" "}
                    {homePageContent?.data?.[0]?.attributes?.FirstComp?.Heading}
                  </div>
                  <h1 className="display-1 mg-bottom-8px">
                    {homePageContent?.data?.[0]?.attributes?.FirstComp?.name}
                    {/* Best Healthcare you can provide your loved ones from miles away. */}
                  </h1>
                  <div className="inner-container _518px _100---tablet">
                    <p className="mg-bottom-32px mg-bottom-20px-tablet">
                      {
                        homePageContent?.data?.[0]?.attributes?.FirstComp
                          ?.description1
                      }
                      {/* CureBay specializes in personalized healthcare for the elderly. We ensure trained personnel reach your aging parents in remote areas in Odisha, monitoring their health. We provide tailored treatments, quality healthcare backed by cutting edge technology, allowing you to oversee their well-being from wherever you are. */}
                    </p>
                  </div>
                  <div className="buttons-row mg-bottom-52px mg-bottom-32px-tablet">
                    {/* <a
                      href={APP_ROUTES.NEWAPPOINMENT}
                      className="btn-primary button-row "
                    >
                      <span className="doctr-custom-icon btn-icon-left"></span>
                      Book a Health Assessment
                    </a> */}
                    <a
                      href={APP_ROUTES.CAREPLANNEW}
                      className="btn-secondary  "
                    >
                      <span className="doctr-custom-icon btn-icon-left"></span>
                      Care Plans
                    </a>
                  </div>
                  <div>
                    <div className="mg-bottom-24px mg-bottom-16px-tablet" />
                  </div>
                </div>
                <div
                  data-w-id="3f79dbeb-9853-ec21-8eda-6185c24f2e29"
                  // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                  className="home-hero-picture v1  hidden sm:block"
                  data-aos="fade-left"
                >
                  <img
                    src={
                      homePageContent?.data?.[0]?.attributes?.FirstComp
                        ?.image_url
                    }
                    alt="CureBay Totalcare specializes in remote healthcare for elderly care in Odisha"
                    className="fit-cover _w-h-100"
                  />
                </div>
              </div>
            </div>
            <div className="half-bg-right home-v1-bg-right" />
          </div>
          <div className="section bg-neutral-200">
            <div className="container-default w-container">
              <div
                data-w-id="2fee9129-3374-696b-a2ac-4be19141e705"
                data-aos="slide-up"
                className="inner-container _596px center"
              >
                <div className="text-center mg-bottom-40px">
                  <h2 className="display-2 mg-bottom-0 center">
                    {homePageContent?.data?.[0]?.attributes?.seccomp?.Heading}
                    {/* Monitor Health Status Reports Remotely */}
                  </h2>
                </div>
              </div>
              <div className="slider-wrapper arrows-bottom-center-mbl mg-bottom-40px w-slider">
                <div className="slider-mask overflow-visible w-slider-mask">
                  <div className="w-slide" data-aos="slide-up">
                    <div className="inner-container _986px center">
                      <div className="card testimonial-card-container">
                        <div className="inner-container _460px _100---tablet">
                          <div className="inner-container _440px _100---tablet">
                            <h3 className="heading-h2-size mg-bottom-16px text-center sm:text-left">
                              {slides[currentSlide].title}
                            </h3>
                          </div>
                          <p className="mg-bottom-32px text-left">
                            {slides[currentSlide].description}
                          </p>
                        </div>
                        <div className="inner-container _296px">
                          <img
                            src={slides[currentSlide].imgUrl}
                            alt={slides[currentSlide].alt}
                            className="image fit-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="btn-circle-secondary slider-arrow left-arrow---center w-slider-arrow-left"
                  data-aos="slide-up"
                  onClick={handleLeftArrowClick}
                >
                  <div className="line-rounded-icon"></div>
                </div>
                <div
                  className="btn-circle-secondary slider-arrow right-arrow---center w-slider-arrow-right"
                  onClick={handleRightArrowClick}
                >
                  <div className="line-rounded-icon"></div>
                </div>
              </div>
              <div
                data-w-id="2fee9129-3374-696b-a2ac-4be19141e743 "
                //   style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                className="buttons-row center"
              >
                <img
                  src={appstore}
                  onClick={redirectToAppStore}
                  alt="Download CureBay Totalcare App from ios App store"
                  className="image-9 cursor-pointer"
                  data-aos="fade-right"
                />
                <img
                  src={playstore}
                  onClick={redirectToPlayStore}
                  alt="Download CureBay Totalcare App from android Play store"
                  className="cursor-pointer"
                  data-aos="fade-left"
                />
              </div>
            </div>
          </div>
          <div className="section bg-neutral-200">
            <div className="container-default w-container">
              <div
                data-w-id="6e73a80e-9b4e-3c3d-5de8-2fa6f8cf1d7e"
                className="inner-container _574px center"
                data-aos="slide-up"
              >
                <div className="text-center mg-bottom-40px">
                  <h2 className="display-2 mg-bottom-0 center">
                    {homePageContent?.data?.[0]?.attributes?.thrdcomp?.Heading}
                  </h2>
                </div>
              </div>
              <div
                data-delay={4000}
                data-animation="cross"
                className="slider-wrapper arrows-bottom-center-mbl mg-bottom-40px w-slider"
                data-autoplay="false"
                data-easing="ease"
                data-hide-arrows="false"
                data-disable-swipe="false"
                data-w-id="6e73a80e-9b4e-3c3d-5de8-2fa6f8cf1d84"
                data-autoplay-limit={0}
                data-nav-spacing={3}
                data-duration={500}
                data-infinite="true"
              >
                <div className="slider-mask overflow-visible w-slider-mask">
                  <div className="w-slide">
                    <div
                      className="inner-container _986px center"
                      data-aos="slide-up"
                    >
                      <div className="card testimonial-card-container">
                        <div
                          className="inner-container _460px _100---tablet"
                          data-aos="slide-up"
                        >
                          <div className="inner-container _440px _100---tablet">
                            <h3 className="heading-h2-size mg-bottom-16px">
                              <strong> {slides1[currentSlide1].title}</strong>
                            </h3>
                          </div>
                          <p className="mg-bottom-32px leftmobile text-left">
                            {" "}
                            {slides1[currentSlide1].description}
                          </p>
                        </div>
                        <div className="inner-container _296px">
                          <img
                            loading="eager"
                            sizes="(max-width: 479px) 67vw, 296px"
                            src={slides1[currentSlide1].imgUrl}
                            alt={slides1[currentSlide1].alt}
                            className="image fit-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="btn-circle-secondary slider-arrow left-arrow---center w-slider-arrow-left"
                  onClick={handleLeftArrowClick1}
                  data-aos="slide-up"
                >
                  <div className="line-rounded-icon"></div>
                </div>
                <div
                  className="btn-circle-secondary slider-arrow right-arrow---center w-slider-arrow-right"
                  onClick={handleRightArrowClick1}
                  slide-up="slide-up"
                >
                  <div className="line-rounded-icon"></div>
                </div>
                <div className="hidden-on-desktop w-slider-nav w-round" />
              </div>
              <div
                className="text-center"
                data-w-id="6e73a80e-9b4e-3c3d-5de8-2fa6f8cf1dbc"
                data-aos="flip-left"
              >
                <a href={APP_ROUTES.ECLINICS} className="btn-primary ">
                  Find your nearest eClinic
                </a>
              </div>
            </div>
          </div>
        </section>

        <div className="section hero testimonial">
          <div className="container-default w-container">
            <div className="sm:mb-[48px]">
              <div
                data-w-id="e0e77e8d-95ea-759e-7e62-4d27ccea774f"
                className="heading-flex align-bottom"
                data-aos="slide-up"
              >
                <div
                  id="w-node-e0e77e8d-95ea-759e-7e62-4d27ccea7750-4d0e8e3c"
                  className="inner-container _690px _100---tablet"
                  data-aos="slide-up"
                >
                  <h2 className="display-2 mg-bottom-0">
                    Best Doctors for you
                  </h2>
                </div>
                <div id="w-node-e0e77e8d-95ea-759e-7e62-4d27ccea7755-4d0e8e3c">
                  <a href={APP_ROUTES.DOCTORNEW} className="btn-secondary ">
                    See All
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className=" gap-4 ">
                {getdoctordata.ForthComponent?.length > 0 && (
                  <Carousel
                    value={getdoctordata?.ForthComponent}
                    numVisible={3}
                    numScroll={1}
                    autoplay
                    className="custom-carousel"
                    circular
                    itemTemplate={productTemplate}
                    autoplayInterval={3000}
                    showIndicators={false}
                    responsiveOptions={responsiveOptions}
                  >
                    {getdoctordata.ForthComponent?.map((value) => (
                      <div role="listitem" className="w-dyn-item">
                        <div
                          className="card testimonial-card"
                          data-aos="slide-up"
                        >
                          {/* <img srcSet="images/john-carter-doctr-x-webflow-template-p-500.png 500w, images/john-carter-doctr-x-webflow-template.png 760w"
                 alt="John Carter Avatar Circle - Doctr X Webflow Template" 
                 sizes="(max-width: 479px) 81vw, (max-width: 767px) 86vw, (max-width: 991px) 40vw, (max-width: 1439px) 24vw, 348.6640625px"
                  src="images/john-carter-doctr-x-webflow-template.png" className="avatar-image" /> */}
                          <img
                            src={value.image_url}
                            alt={value.alt}
                            className="avatar-image sm:h-[200px];
                    sm:w-[200px]"
                          />
                          <div className="mg-bottom-8px" data-aos="slide-up">
                            <div className="text-200 bold color-neutral-800">
                              {value.name}{" "}
                            </div>
                          </div>
                          <div className="color-neutral-600">
                            {value.specialities}{" "}
                          </div>

                          <div
                            className="text-200 medium color-accent-1"
                            data-aos="slide-up"
                          >
                            <strong className="bold-text-6">
                              <div className="sm:h-24  overflow-hidden">
                                {value.description}
                              </div>
                              <div className="sm:block hidden">
                                <span
                                  onClick={() =>
                                    individualDescription(value?.description)
                                  }
                                  className="text-blue font-bold cursor-pointer text-center"
                                >
                                  Read more...
                                </span>
                              </div>
                            </strong>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
        </div>
        <section className="section">
          <div className="w-layout-blockcontainer container-default w-container">
            <div
              data-w-id="06baaee7-3cac-e682-19b5-d99e81fc3b13"
              style={{
                WebkitTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              }}
              className="heading-flex align-bottom"
            >
              <div
                id="w-node-_06baaee7-3cac-e682-19b5-d99e81fc3b14-4d0e8e3c"
                className="inner-container _690px _100---tablet"
                data-aos="slide-up"
              >
                <h2 className="display-2 mg-bottom-0">Healthcare Plans</h2>
              </div>
              <div
                id="w-node-_06baaee7-3cac-e682-19b5-d99e81fc3b19-4d0e8e3c"
                data-aos="slide-up"
              >
                <a
                  href={APP_ROUTES.CAREPLANNEW}
                  className="btn-secondary sm:mb-[30px]"
                >
                  Explore More
                </a>
              </div>
            </div>
            <div className="div-block">
              {homePageContent?.data[0]?.attributes?.seccomp?.care_content
                ?.length > 0 &&
                homePageContent?.data[0]?.attributes?.seccomp?.care_content.map(
                  (rec, index) => (
                    <div
                      className="card blog-v1-card w-inline-block"
                      key={index}
                      data-aos="slide-up"
                    >
                      <div className="card-picture-wrapper">
                        <img
                          src={rec?.image_url}
                          loading="lazy"
                          sizes="100vw"
                          // srcSet="images/Frame-9-p-500.png 500w, images/Frame-9.png 550w"
                          alt={rec?.alt}
                          className="image-3"
                        />
                      </div>
                      <div className="card-description blog-v1">
                        <h3 className="card-title-dark">{rec?.title}</h3>
                        <p className="color-neutral-600" data-aos="slide-up">
                          {rec?.description}
                        </p>
                        <h3
                          className="card-title-dark textcta cursor-pointer"
                          data-aos="slide-up"
                          onClick={() => {
                            onReadMoreClick(rec?.plan_id);
                          }}
                        >
                          Explore More{" "}
                          <img
                            src={arrowicon}
                            loading="lazy"
                            alt=""
                            className="image-10"
                          />
                        </h3>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </section>

        {/* <div className="section bg-neutral-200">
          <div className="container-default w-container" data-aos="slide-up">

            <div data-delay={4000} data-animation="cross" className="slider-wrapper arrows-bottom-center-mbl mg-bottom-40px w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-w-id="6e73a80e-9b4e-3c3d-5de8-2fa6f8cf1d84" data-autoplay-limit={0} data-nav-spacing={3} data-duration={500} data-infinite="true">
              <div className="slider-mask overflow-visible w-slider-mask">
                <div className="w-slide">
                  <div className="inner-container _986px center"><img src={slides2[currentSlide2].imgUrl} loading="lazy" alt="" /></div>
                </div>
                <div className="w-slide">
                  <div className="inner-container _986px center"><img src={slides2[currentSlide2].imgUrl} loading="lazy" alt="" /></div>
                </div>

              </div>
              <div className="btn-circle-secondary slider-arrow left-arrow---center w-slider-arrow-left" onClick={handleLeftArrowClick2} data-aos="slide-up">
                <div className="line-rounded-icon"></div>
              </div>
              <div className="btn-circle-secondary slider-arrow right-arrow---center w-slider-arrow-right" onClick={handleRightArrowClick2} slide-up="slide-up">
                <div className="line-rounded-icon"></div>
              </div>
              <div className="hidden-on-desktop w-slider-nav w-round" />
            </div>
          </div>
        </div> */}
        <section className="section">
          <div
            className="w-layout-blockcontainer container-default w-container"
            data-aos="slide-up"
          >
            <div
              data-w-id="128de824-8514-de74-a2e6-8c4434fa7eff"
              style={{
                WebkitTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              }}
              className="heading-flex align-bottom"
            >
              <div
                id="w-node-_128de824-8514-de74-a2e6-8c4434fa7f00-4d0e8e3c"
                className="inner-container _690px _100---tablet"
              >
                <h2 className="display-2 mg-bottom-0">
                  Industry Leading Partners
                </h2>
              </div>
              <div id="w-node-_128de824-8514-de74-a2e6-8c4434fa7f05-4d0e8e3c">
                <a
                  href={APP_ROUTES.SERVICEPARTNERNEW}
                  className="btn-secondary "
                >
                  View More
                </a>
              </div>
            </div>
            <div className="div-block-2">
              {datapartner?.[0]?.attributes?.SecondComp?.content1?.map(
                (value) => (
                  <>
                    <div id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df4274839-4d0e8e62">
                      <h3
                        id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df427483a-4d0e8e62"
                        className="card-title-dark"
                      >
                        <strong className="bold-text-2">{value?.name}</strong>
                      </h3>

                      <div className="sm:grid-cols-8 grid-cols-3 logobox items-center">
                        {value?.label?.map((value1) => (
                          <div>
                            <img
                              src={value1?.image_url}
                              loading="lazy"
                              alt={value1?.alt}
                              className="logo sm:w-[7vw] w-[17vw]"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )
              )}

              {/* <div id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df427483d-4d0e8e62" className="card partners"><img src="images/652e9e00ab66a14f1140e56c_hosp5-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e0016f63b469c44082b_download-1-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e011df6961283ce3681_hosp2-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
                <img src="images/652e9e00b7b888907638560c_hosp3-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
                <img src="images/652e9e01fdbcb368db8fdd25_Care-Hospitals-logo-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e62223ed68013685883_Sunflower-nursing-home-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e00befbcffd62c2bb8d_hosp12-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
                <img src="images/652e9e626f3c78eb86c6646b_Maa-shakti-hospital-logo-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e00878288ffe9501793_hosp7-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
                </div> */}
            </div>
          </div>
        </section>
        <section className="">
          <div
            className="w-layout-blockcontainer container-default w-container"
            data-aos="slide-up"
          >
            <div className="text-center mg-bottom-40px">
              {/* <h2 className="display-2 mg-bottom-0 center">Redefining Remote Elderly Care in India</h2> */}
            </div>
            {/* <div className="div-block-4">
              <div style={{ paddingTop: '56.17021276595745%' }} className="w-embed-youtubevideo">
              <iframe src="https://www.youtube.com/embed/DkyZ9O_BjTk?rel=0&controls=1&autoplay=0&mute=0&start=0" frameBorder={0} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', pointerEvents: 'auto' }} allow="autoplay; encrypted-media" allowFullScreen title="Get Affordable Healthcare through Cutting-Edge Health Technology I CureBay Health Card" />
              </div>
            </div> */}

            <div className="slider-wrapper arrows-bottom-center-mbl mg-bottom-40px w-slider">
              {/* <div className="slider-mask overflow-visible w-slider-mask">
                <div className="w-slide" data-aos="slide-up">
                  <div className="inner-container _986px center"> */}
              {/* <div className="inner-container _986px center" style={{ display: 'flex', flexDirection: 'row' }}>
                      {slides4.map((slide, index) => (
                        <div key={index} style={{ display: index === currentSlide ? 'block' : 'none', flex: 1, margin: '0 10px' }}>
                          {slide.iframeSrc && (
                            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                              <iframe
                                src={slide.iframeSrc}
                                frameBorder="0"
                                allowFullScreen
                                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                                title={`Slide ${index + 1}`}
                              />
                            </div>
                          )}
                        </div>
                      ))}

                    </div> */}

              {/* <div
                      style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        height: 0,
                        overflow: "hidden",
                      }}
                    >
                      <iframe
                        src="https://www.youtube.com/embed/IGAq_N_OLoY?si=Zr29uGPKfLYn0-1C"
                        frameBorder="0"
                        allowFullScreen
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div> */}
              {/* </div>
                </div>
              </div> */}
              {/* <div
                className="btn-circle-secondary slider-arrow left-arrow---center w-slider-arrow-left"
                data-aos="slide-up"
                onClick={handleLeftArrowClick4}
              >
                <div className="line-rounded-icon"></div>
              </div>
              <div
                className="btn-circle-secondary slider-arrow right-arrow---center w-slider-arrow-right"
                onClick={handleRightArrowClick4}
              >
                <div className="line-rounded-icon"></div>
              </div> */}
            </div>
          </div>
        </section>

        <section
          className=" mt-[52px] sm:mt-[44px]  pb-[100px] sm:pt-[90px] pt-[0px]"
          style={{ backgroundColor: "#004171" }}
        >
          <div
            className="w-layout-blockcontainer container-default w-container"
            data-aos="slide-up"
          >
            <div
              data-w-id="d29ae847-928e-db3d-c609-6ad3146511de"
              style={{
                WebkitTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              }}
              className="w-layout-grid grid-4-columns stats-grid"
              data-aos="zoom-in"
            >
              <div
                id="w-node-d29ae847-928e-db3d-c609-6ad3146511df-4d0e8e3c"
                data-w-id="d29ae847-928e-db3d-c609-6ad3146511df"
                style={{
                  WebkitTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="text-center"
              >
                <div className="display-1 color-neutral-100">
                  {statisticsCount?.succesObject?.eclinicsCount}
                </div>
                <div className="text-200 medium text-white">
                  eClinics in Odisha
                </div>
              </div>
              <div
                id="w-node-d29ae847-928e-db3d-c609-6ad3146511e6-4d0e8e3c"
                data-w-id="d29ae847-928e-db3d-c609-6ad3146511e6"
                style={{
                  WebkitTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="text-center"
              >
                <div className="display-1 color-neutral-100">
                  {statisticsCount?.succesObject?.townAndVillagesCoveredCount}
                </div>
                <div className="text-200 medium text-white">
                  Villages and towns covered
                </div>
              </div>
              <div
                id="w-node-_388704fc-00cf-9728-3f3a-0fe0f2cf38c9-4d0e8e3c"
                data-w-id="388704fc-00cf-9728-3f3a-0fe0f2cf38c9"
                style={{
                  WebkitTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="text-center"
              >
                <div className="display-1 color-neutral-100">
                  {statisticsCount?.succesObject?.patientCounts}
                </div>
                <div className="text-200 medium text-white">Happy Patients</div>
              </div>
              <div
                id="w-node-d29ae847-928e-db3d-c609-6ad3146511ed-4d0e8e3c"
                data-w-id="d29ae847-928e-db3d-c609-6ad3146511ed"
                style={{
                  WebkitTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="text-center"
              >
                <div className="display-1 color-neutral-100">
                  {statisticsCount?.succesObject?.specialitiesCount}
                </div>
                <div className="text-200 medium text-white">Specialities</div>
              </div>
              <div
                id="w-node-d29ae847-928e-db3d-c609-6ad3146511f4-4d0e8e3c"
                data-w-id="d29ae847-928e-db3d-c609-6ad3146511f4"
                style={{
                  WebkitTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="text-center"
              >
                <div className="display-1 color-neutral-100">
                  {statisticsCount?.succesObject?.serviceProvidersCount}
                </div>
                <div className="text-200 medium text-white">
                  Service Providers
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="section hero testimonial">
          <div className="container-default w-container">
            <div className="mg-bottom-48px" data-aos="slide-up">
              <div
                data-w-id="e9cfdaa9-1687-863e-715d-3168185d92f5"
                style={{
                  WebkitTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="heading-flex align-bottom"
              >
                <div
                  id="w-node-e9cfdaa9-1687-863e-715d-3168185d92f6-4d0e8e3c"
                  className="inner-container _690px _100---tablet"
                >
                  <h2 className="display-2 mg-bottom-0">
                    Happy Patients Speak
                  </h2>
                </div>
                <div id="w-node-e9cfdaa9-1687-863e-715d-3168185d92fb-4d0e8e3c">
                  <a href={APP_ROUTES.REVIEWPAGENEW} className="btn-secondary ">
                    Explore More
                  </a>
                </div>
              </div>
            </div>
            <div
              data-w-id="3fcf44d3-ac14-9906-fedb-314944ce9d42"
              // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

              className="w-layout-grid grid-3-columns gap-row-32px"
            >
              {reviewsData?.attributes?.SecondComp?.[0]?.Content?.slice(
                0,
                3
              ).map((value, index) => (
                <div className="card testimonial-card">
                  <img
                    src={value.image_url}
                    alt={value.alt}
                    className="avatar-image"
                    data-aos="slide-up"
                  />
                  <p className="mg-bottom-24px">{value?.description}</p>
                  <div className="mg-bottom-8px">
                    <div className="text-200 bold color-neutral-800">
                      {" "}
                      {value?.name}
                    </div>
                  </div>
                  <div className="text-200 medium color-accent-1">
                    {value?.location}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className="section hero faqs"
          data-aos="slide-up"
          style={{ marginTop: "0px" }}
        >
          <div className="container-default w-container">
            <div
              data-w-id="a2637b81-b20b-dabb-6c01-4c529397cd68"
              className="inner-container _700px center"
            >
              <div className="text-center mg-bottom-32px">
                <h2 className="display-1">Frequently Asked Questions</h2>

                <div>
                  {homePageContent?.data?.[0]?.attributes?.seventh_comp?.faq.map(
                    (rec, index) => (
                      <>
                        <Accordion title={rec.ques} content={rec.ans} />
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => setVisible(false)}
        >
          <p className="m-0">{individualdocDescription}</p>
        </Dialog>
        {/* <Footer /> */}
        {screen > 500 ? (
          <Footer />
        ) : (
          <>
            <FooterMobileVersion />
            <MobileFooter />
          </>
        )}
      </div>
    </>
  );
};

export default Homepagenew;
