import React, { useEffect, useState, useRef } from "react";
import logoimg from "../../assets/images/Web Logo.png";
// import logoimg from "../../assets/images/footerlogo.png"
import { APP_ROUTES } from "../../constants/approutes";
import { Link } from "react-router-dom";
// import { Menu } from 'primereact/menu';
import { useNavigate } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import loginservice from "../../store/services/loginservice";
import shop from "../homepage/image/shoping.svg";
import { updateUserData } from "../../store/actions/useractions";
import { useDispatch, useSelector } from "react-redux";
import { getCoords } from "../../store/actions/commonactions";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import Dashboard from "../homepage/image/dashboard icon.png";
import { getOurCareplans } from "../../store/actions/howitworks";
import { useCookies } from "react-cookie";
import { SlideMenu } from "primereact/slidemenu";
import {
  getCureBayExpContent,
  getCureBayStatistics,
  getDoctorsPageContent,
  getHomePageContent,
} from "../../store/actions/homepage";
// import logo from "./../homepage/image/Medicare_Logo.png";
import {
  Avatar,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

const Headernew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isHovered, setIshovered] = useState(false);
  const [carePlans, setCarePlans] = useState([]);
  const [open, setOpen] = useState(null);
  const menuLeft = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [visibleTop, setVisibleTop] = useState(false);
  const [menuVisible1, setMenuVisible1] = useState(false);
  const [menuVisible2, setMenuVisible2] = useState(false);
  const [sidebarHeight, setSidebarHeight] = useState("auto");
  const [anchorElplan, setAnchorElplan] = React.useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["cookieConsent"]);

  const menu = useRef(null);

  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  const toggleMenu = () => {
    setMenuVisible1(!menuVisible1);
    setSidebarHeight(menuVisible1 ? "auto" : "auto");
  };

  const toggleopeneelite = () => {
    setMenuVisible2(!menuVisible2);
    setSidebarHeight(menuVisible2 ? "auto" : "auto");
  };
  const closeSidebar = () => {
    setVisibleTop(false);
  };

  useEffect(() => {
    if (localStorage.getItem("loginObj"))
      dispatch(
        updateUserData(JSON.stringify(localStorage.getItem("loginObj")))
      );
    dispatch(getCureBayStatistics());
  }, [dispatch]);

  const handleClick = (e, itemName) => {
    setOpen(itemName);
    setAnchorEl(e.currentTarget);
  };
  const handleHoverClose = () => {
    setIshovered(false);

    console.log(isHovered);
  };
  const handleHoverOpen = () => {
    setIshovered(true);
    console.log(isHovered);
  };
  const handleClose = (e) => {
    setOpen(null);
    setAnchorEl(null);
  };

  const handleMenuClick = (item) => {
    handleClose();
    if (item.href) {
      navigate(item.href);
    } else if (item.route) navigate(item.route);
  };

  const handleAvatarClick = (e) => {
    const ID = e?.target?.id;
    handleClose();

    const actions = {
      myProfile: () => navigate(APP_ROUTES.MYDETAILS),
      changePassword: () => navigate(APP_ROUTES.PASSCHANGE),
      signOut: logout,

      myDetails: () => navigate(APP_ROUTES.MYDETAILS),
      myPlan: () => navigate(APP_ROUTES.MYPLAN),
      memberProfile: () => navigate(APP_ROUTES.MEMBERDETAILS),
      myPrescription: () => navigate(APP_ROUTES.MYPRESCRIPTION),
      myLabreports: () => navigate(APP_ROUTES.MYLABREPORTS),
      myVitals: () => navigate(APP_ROUTES.MYVITALS),
      myReview: () => navigate(APP_ROUTES.MYREVIEW),
      myDashboard: () => navigate(APP_ROUTES.MAINDASHBOARD),
    };
    const action = actions[ID];
    if (action) {
      action();
    }
  };

  useEffect(() => {
    dispatch(getHomePageContent());
    dispatch(getDoctorsPageContent());
    dispatch(getCureBayExpContent());
    dispatch(getCureBayStatistics());
  }, [dispatch]);
  const [userAvatar, setUserAvatar] = useState(null);
  const { cartListLength } = useSelector((state) => state.CommonReducer);

  useEffect(() => {
    if (userAvatar === null) {
      let loginObjStr = localStorage.getItem("loginObj");
      let loginObj = JSON.parse(loginObjStr);
      let userName = loginObj?.[0]?.firstName ? loginObj?.[0]?.firstName : "";
      let userAvatar = Array.from(userName)[0];
      setUserAvatar(userAvatar);
    }
  }, [userAvatar]);

  const coords = useSelector((state) => state.CommonReducer.coords);
  useEffect(() => {
    if (!coords) {
      navigator.geolocation.getCurrentPosition(function (position) {
        dispatch(getCoords(position));
      });
    }
  }, [dispatch, coords]);

  const userData = useSelector((state) => state.AuthReducer.userData);
  useEffect(() => {
    if (!userData.length && !localStorage.getItem("loginObj")) return;

    let data = {};

    if (userData.length) {
      data = JSON.parse(userData);
    } else {
      data = JSON.parse(localStorage.getItem("loginObj"));
    }
    if (data?.[0]?.userCode || data?.[0]?.code) {
      let userName = data?.[0]?.firstName?.[0]
        ? Array.from(data[0].firstName[0])
        : "P";
      setUserAvatar(userName);
    }
    let user = {
      userCode: data?.[0]?.userCode,
    };
    loginservice
      .cartList(user)
      .then((res) => {
        dispatch({
          type: COMMONCONSTANTS.SUCCESS_FETCHCART,
          payload: res?.data?.succesObject,
        });
      })
      .catch((error) => {});
  }, [userData, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const carePlansResponse = await getOurCareplans();
      if (
        carePlansResponse.status === 200 &&
        carePlansResponse?.data?.succesObject
      ) {
        setCarePlans(carePlansResponse.data.succesObject);
      }
    };

    fetchData();
  }, []);

  const logout = () => {
    dispatch(updateUserData({}));
    localStorage.clear();
    navigate(APP_ROUTES.DASHBOARD);
    if (
      typeof window !== "undefined" &&
      typeof window.location !== "undefined"
    ) {
      window.location.reload();
    }
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [screen, setscreen] = useState(window.innerWidth);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    window.fwSettings = {
      widget_id: 1070000000524,
      position: "bottom-left",
      alignment: "left",
    };

    if (typeof window.FreshworksWidget !== "function") {
      class n {
        constructor() {
          n.q.push(arguments);
        }
      }
      n.q = [];
      window.FreshworksWidget = n;
    }

    const script = document.createElement("script");
    script.src = "https://ind-widget.freshworks.com/widgets/1070000000524.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const items = [
    {
      label: "Gold Plan",
      command: () => navigate("/totalcaregold"),
    },
    {
      label: "Platinum Plan",
      command: () => navigate("/totalcareplatinum"),
    },
    {
      label: "Ellite Plan",
      command: () => navigate("/totalcareellite"),
    },
    // {
    //   label: "Ellite Plan",
    //   items: [
    //     {
    //       label: "General Health",
    //       command: () => navigate('/generalHealth'),
    //     },
    //     {
    //       label: "Diabetics Care",
    //       command: () => navigate('/diabeticsCare'),
    //     },
    //     {
    //       label: "Cardiac Care",
    //       command: () => navigate('/CardiacCare'),
    //     },
    //     {
    //       label: "Women's Health Care",
    //       command: () => navigate('/womensHealthCare'),
    //     },
    //     {
    //       label: "Ortho Care Care",
    //       command: () => navigate('/orthopedicsCare'),
    //     },

    //   ],
    // },
  ];

  const handleMenuOpen = (event) => {
    menu.current.toggle(event);
    setAnchorElplan(event?.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElplan(null);
  };

  const handleItemClick = (path) => {
    handleMenuClose();
    setAnchorEl(null);
    if (path) {
      navigate(path);
    }
    window.scrollTo(0, 0);
  };

  const handleButtonMouseEnter = () => {
    setAnchorElplan(true);
  };

  const handleButtonMouseLeave = () => {
    setAnchorElplan(false);
  };

  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);

  const handleSubmenuOpen = (event) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  return (
    <>
      <div
        data-w-id="3308f6cc-4683-2487-0f76-1e48bc67405e"
        data-animation="default"
        data-collapse="medium"
        data-duration={400}
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="header-wrapper bg-transparent w-nav top-0"
      >
        <div className="container-default w-container">
          <div
            data-w-id="3308f6cc-4683-2487-0f76-1e48bc674060"
            className="header-content-wrapper"
          >
            {/* For mobile */}
            <div className="lg:hidden">
              {visibleTop ? (
                <div className="">
                  <div className="text-blue font-bold" onClick={closeSidebar}>
                    &#10007;
                  </div>
                  <Sidebar
                    visible={visibleTop}
                    position="top"
                    onHide={() => setVisibleTop(false)}
                    style={{ height: sidebarHeight }}
                  >
                    <ul role="list" className="header-nav-menu-list">
                      <li className="header-nav-list-item left mt-5">
                        <a
                          href={APP_ROUTES.ECLINICS}
                          className="header-nav-link w-nav-link"
                        >
                          eClinics Near Me
                        </a>
                      </li>
                      <li className="header-nav-list-item left">
                        <a
                          href={APP_ROUTES.CUREBAYEXPERIENCENEW}
                          className="header-nav-link w-nav-link"
                        >
                          CureBay Experience
                        </a>
                      </li>
                      <li className="header-nav-list-item left">
                        <div
                          data-hover="true"
                          data-delay={0}
                          data-w-id="48531fd5-9f0c-f6dc-5f1c-7999bd55d4d6"
                          className="dropdown-wrapper w-dropdown"
                        >
                          <div
                            className="dropdown-toggle w-dropdown-toggle"
                            onClick={toggleMenu}
                          >
                            <div className="header-nav-link">Care Plans</div>

                            <div
                              className="line-rounded-icon dropdown-arrow"
                              onClick={toggleMenu}
                              style={{
                                transform: menuVisible1
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                            >
                              
                            </div>
                          </div>
                          {menuVisible1 && (
                            <div id="dropdown-menu" className="dropdown-menu">
                              <ul>
                                <li>
                                  {" "}
                                  <a
                                    href="/totalcaregold"
                                    className="header-nav-link w-nav-link"
                                  >
                                    Totalcare Gold
                                  </a>
                                </li>
                                <li>
                                  {" "}
                                  <a
                                    href="/totalcareplatinum"
                                    className="header-nav-link w-nav-link"
                                  >
                                    Totalcare Platinum
                                  </a>{" "}
                                </li>
                                <li>
                                  {" "}
                                  <a
                                    href="/totalcareellite"
                                    className="header-nav-link w-nav-link"
                                  >
                                    Totalcare Ellite
                                  </a>{" "}
                                </li>
                                {/* <li>
                                    <div
                                   
                                   className="header-nav-link w-nav-link flex gap-2"
                                  
                                 > Totalcare Ellite
                                     <div
                              className="line-rounded-icon dropdown-arrow"
                              onClick={toggleopeneelite}
                              style={{
                                transform: menuVisible2
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)",
                              }}
                            >
                              
                            </div>
                                   
                                  </div>
                                  {menuVisible2 ? 
                                <div>
                                   
                                <li>
                                  {" "}
                                  <a
                                    href="/generalHealth"
                                    className="header-nav-link w-nav-link"
                                  >
                                    General Care Plan
                                  </a>
                                </li>
                                <li>
                                  {" "}
                                  <a
                                    href="/diabeticsCare"
                                    className="header-nav-link w-nav-link"
                                  >
                                    Diabetic Care Plan{" "}
                                  </a>
                                </li>
                                <li>
                                  {" "}
                                  <a
                                    href="/CardiacCare"
                                    className="header-nav-link w-nav-link"
                                  >
                                    Cardiac Care Plan{" "}
                                  </a>
                                </li>
                                <li>
                                  {" "}
                                  <a
                                    href="/womensHealthCare"
                                    className="header-nav-link w-nav-link"
                                  >
                                    Women's Healthcare Plan
                                  </a>
                                </li>
                                <li>
                                  {" "}
                                  <a
                                    href="/orthopedicsCare"
                                    className="header-nav-link w-nav-link"
                                  >
                                    Ortho Care Plan
                                  </a>
                                </li>
                                </div>  :"" 
                                
                                }
                                </li> */}
                              </ul>
                            </div>
                          )}
                        </div>
                      </li>
                      {/* <li className="header-nav-list-item header-btn-show-on-tablet">
                        <a
                          href={APP_ROUTES.NEWAPPOINMENT}
                          className="btn-primary small w-button"
                        >
                          <span className="doctr-custom-icon mg-right-4px text-medium">
                            
                          </span>
                          Book a Health Assessment
                        </a>
                      </li> */}
                      <li className="header-nav-list-item header-btn-show-on-tablet"></li>
                      <li className="header-nav-list-item header-btn-show-on-tablet"></li>
                    </ul>
                  </Sidebar>
                </div>
              ) : (
                <div
                  className="hamburger-menu-wrapper w-nav-button "
                  onClick={() => setVisibleTop(true)}
                >
                  <div className="hamburger-menu-bar top white-transparent" />
                  <div className="hamburger-menu-bar bottom white-transparent" />
                </div>
              )}
            </div>

            {!cookies.cookieConsent && (
              <div className="cookie-consent fixed bottom-0 left-0 w-full bg-gray-800 text-white pt-4 px-6 flex justify-between items-center">
                <p className="text-sm">
                  We use cookies to enhance your user experience. By using our
                  website, you agree to our use of cookies.{" "}
                  <a href="/privacypolicy" className="underline text-white">
                    Learn more.
                  </a>
                  <button
                    onClick={giveCookieConsent}
                    className="bg-white  hover:bg-blue-600 text-gray-800 font-bold py-2 px-4 rounded ml-2"
                  >
                    Accept
                  </button>
                </p>
              </div>
            )}

            <div className="header-left-side">
              <a
                href={APP_ROUTES.DASHBOARD}
                aria-current="page"
                className="curebaylogoweb header-logo-link w-nav-brand w--current "
              >
                <img src={logoimg} className="" alt="" />
              </a>
              <a
                href={APP_ROUTES.DASHBOARD}
                aria-current="page"
                className="curebaylogomob header-logo-link w-nav-brand w--current "
              >
                <img
                  src={logoimg}
                  className="pl-[9px] h-[24px]"
                  alt=""
                  style={{ paddingLeft: "9px" }}
                />
              </a>
              <nav
                role="navigation"
                className="header-nav-menu-wrapper w-nav-menu"
              >
                <ul role="list" className="header-nav-menu-list">
                  <li className="header-nav-list-item left">
                    <a
                      href={APP_ROUTES.ECLINICS}
                      className="header-nav-link white-link w-nav-link"
                    >
                      eClinics Near Me
                    </a>
                  </li>
                  <li className="header-nav-list-item left">
                    <a
                      href={APP_ROUTES.CUREBAYEXPERIENCENEW}
                      className="header-nav-link white-link w-nav-link"
                    >
                      CureBay Experience
                    </a>
                  </li>
                  <li className="header-nav-list-item left">
                    <div
                      data-hover="true"
                      data-delay={0}
                      data-w-id="3308f6cc-4683-2487-0f76-1e48bc67406d"
                      className="dropdown-wrapper w-dropdown"
                    >
                      <div className="dropdown-toggle white-link tablet w-dropdown-toggle">
                        <button
                          className="header-nav-link relative"
                          onMouseOver={handleMenuOpen}
                          aria-controls="popup_menu_left"
                          aria-haspopup
                        >
                          Care Plans
                        </button>

                        <SlideMenu
                          ref={menu}
                          model={items}
                          popup
                          className="custom-slide-menu"
                        ></SlideMenu>
                        {/* <Menu
                          anchorEl={anchorElplan}
                          open={Boolean(anchorElplan)}
                          onClose={() => setAnchorElplan(null)} // Close the menu when clicking outside
                          // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                          {items.map((item, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => handleItemClick(item.path)}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                        </Menu> */}

                        <div
                          className={`line-rounded-icon dropdown-arrow ${
                            anchorElplan ? "rotate-180" : ""
                          }`}
                          onClick={handleMenuOpen}
                        >
                          
                        </div>
                      </div>

                      <nav className="dropdown-column-wrapper w-dropdown-list">
                        <div className="card dropdown-pd">
                          <div className="menu-main-wrapper">
                            <div className="inner-container _300px">
                              <div className="nav-content">
                                {/* <ul role="list" className="nav-menu-list-wrapper">
                                  <li className="nav-menu-list-item">
                                    <a href="https://curebay-medicare.webflow.io/product/general-care-plan" className="nav-link">General Care Plan</a>
                                  </li>
                                  <li className="nav-menu-list-item">
                                    <a href="https://curebay-medicare.webflow.io/product/diabetes-care-plan" className="nav-link">Diabetes Care Plan</a>
                                  </li>
                                  <li className="nav-menu-list-item">
                                    <a href="https://curebay-medicare.webflow.io/product/cardiac-care-plan" className="nav-link">Cardiac Care Plan</a>
                                  </li>
                                  <li className="nav-menu-list-item">
                                    <a href="https://curebay-medicare.webflow.io/product/womens-health-care-plan" className="nav-link">Women's Healthcare Plan</a>
                                  </li>
                                  <li className="nav-menu-list-item">
                                    <a href="https://curebay-medicare.webflow.io/product/ortho-care-plan" className="nav-link">Ortho Care Plan</a>
                                  </li>
                                </ul> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </li>
                  <li className="header-nav-list-item header-btn-show-on-tablet">
                    {/* <a href={APP_ROUTES.NEWAPPOINMENT} className="btn-primary small w-button"><span className="doctr-custom-icon mg-right-4px text-medium"></span>Book an appointment</a> */}
                  </li>
                </ul>
              </nav>
            </div>
            {/* <div className="lg:hidden"> <img src={logo} className="" alt="" /></div> */}
            <div className="header-right-side ">
              <div className="hidden-on-tablet">
                <div className="mg-right-20px">
                  {!(
                    (userData.length && JSON.parse(userData)?.[0]?.userCode) ||
                    (userData.length && JSON.parse(userData)?.[0]?.code)
                  ) ? (
                    <a
                      href={APP_ROUTES.NEWAPPOINMENT}
                      className="btn-secondary small w-button"
                    >
                      <span className="doctr-custom-icon btn-icon-left small">
                        
                      </span>
                      Book a Health Assessment
                    </a>
                  ) : (
                    <button
                      onClick={() => {
                        navigate(APP_ROUTES.MAINDASHBOARD);
                      }}
                      className="sm:flex bg-[#e6f4fd] hidden sm:block h-10 w-40 items-center  justify-center bg-white capitalize border-2 border z-50 rounded-[1.37rem]"
                      style={{
                        border: " 1px solid #000",
                        margin: "5px 14px 4px 12px",
                      }}
                    >
                      <img className="p-1" src={Dashboard} alt="img" />
                      <span className="text-[14px] font-poppins  font-semibold text-black">
                        My Dashboard
                      </span>
                    </button>
                  )}
                  {userData.length && JSON.parse(userData)?.[0]?.userCode ? (
                    <div className="relative flex gap-3">
                      {/* <img className='w-6 h-7 mt-2' src={notif}></img> */}
                      <button
                        onClick={(e) => navigate(APP_ROUTES.CART)}
                        className="p-2 lg:flex  md:block "
                      >
                        <span className="sr-only">Cart</span>
                        {cartListLength?.length > 0 ||
                        cartListLength?.length > 0 ? (
                          <div>
                            <p className="text-size absolute text-center text-xs -mt-1 text-white font-medium bg-brand-lightgreen rounded-xl h-4 w-4">
                              {cartListLength.length <= 9
                                ? cartListLength.length
                                : "9+"}
                            </p>
                            <img
                              className="w-6 h-6 md:w-8 md:h-7"
                              src={shop}
                              alt={shop}
                              id="shoping"
                            />
                          </div>
                        ) : (
                          //<h1>{cartList?.patientDrugPrescriptionOrder?.length}</h1>
                          <img
                            className="w-6 h-6 md:w-8 md:h-7"
                            src={shop}
                            alt={shop}
                            id="shoping"
                          />
                        )}
                      </button>
                    </div>
                  ) : (
                    <div className="relative" />
                  )}

                  {!(
                    (userData.length && JSON.parse(userData)?.[0]?.userCode) ||
                    (userData.length && JSON.parse(userData)?.[0]?.code)
                  ) ? (
                    <a
                      href={APP_ROUTES.LOGIN}
                      className="btn-primary small w-button"
                    >
                      <span style={{ fontSize: "12px" }}>Login</span>
                    </a>
                  ) : (
                    ""
                  )}

                  {!(
                    (userData.length && JSON.parse(userData)?.[0]?.userCode) ||
                    (userData.length && JSON.parse(userData)?.[0]?.code)
                  ) ? (
                    <a
                      href={APP_ROUTES.SIGNUP}
                      className="btn-primary small w-button "
                    >
                      {" "}
                      <span style={{ fontSize: "12px" }}>Sign up</span>
                    </a>
                  ) : userAvatar?.length > 0 ? (
                    <div className="ml-2 sm:ml-5 headercart">
                      <Avatar
                        sx={{
                          bgcolor: "#0272DA",
                          height: "41px",
                          width: "41px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleClick(e, userAvatar)}
                      >
                        {userAvatar}{" "}
                      </Avatar>

                      {screen < 500 ? (
                        <>
                          <Menu
                            anchorEl={anchorEl}
                            open={open === userAvatar}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            // sx={{ top: "70px" }}
                          >
                            <MenuItem
                              id="myDashboard"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              Dashboard
                            </MenuItem>
                            <MenuItem
                              id="myProfile"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              My Profile
                            </MenuItem>

                            {/* <MenuItem
                              id="myDetails"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              {" "}
                              My Details
                            </MenuItem> */}
                            <MenuItem
                              id="myPlan"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              {" "}
                              My Plans
                            </MenuItem>
                            <MenuItem
                              id="memberProfile"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              Care Recipient's Profile
                            </MenuItem>
                            {/* <MenuItem
                              id="myPrescription"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              {" "}
                              Prescription
                            </MenuItem>
                            <MenuItem
                              id="myLabreports"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              {" "}
                              Lab Reports
                            </MenuItem>
                            <MenuItem
                              id="myVitals"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              {" "}
                              Vitals
                            </MenuItem> */}
                            <MenuItem
                              id="myReview"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              {" "}
                              Review
                            </MenuItem>

                            <MenuItem
                              id="changePassword"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              Change Password
                            </MenuItem>
                            <MenuItem
                              id="signOut"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              Sign Out
                            </MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <>
                          <Menu
                            anchorEl={anchorEl}
                            open={open === userAvatar}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            sx={{ top: "20px" }}
                          >
                            <MenuItem
                              id="myProfile"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              My Details
                            </MenuItem>

                            <MenuItem
                              id="changePassword"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              Change Password
                            </MenuItem>
                            <MenuItem
                              id="signOut"
                              onClick={(e) => handleAvatarClick(e)}
                            >
                              Sign Out
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="header-right-side">
                <div className="hidden-on-tablet ">
                  {/* <div className="mg-right-20px">
                    <a href={APP_ROUTES.NEWAPPOINMENT} className="btn-secondary small w-button"><span className="doctr-custom-icon btn-icon-left small"></span>Book an appointment</a>
                    <a href="user-pages/sign-up.html" className="btn-primary small w-button">Sign up</a>
                  </div> */}
                </div>

                <div className="sm:hidden flex gap-1 hidelogin -mr-[7px] sm:mr-0">
                  <div>
                    {!(
                      (userData.length &&
                        JSON.parse(userData)?.[0]?.userCode) ||
                      (userData.length && JSON.parse(userData)?.[0]?.code)
                    ) ? (
                      <a
                        href={APP_ROUTES.LOGIN}
                        className="hidelogin bg-[#004171] text-white"
                        style={{ borderRadius: "22px", padding: "2px 10px" }}
                      >
                        <span style={{ fontSize: "12px" }}>Login</span>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="sm:hidden block">
                    {userData.length && JSON.parse(userData)?.[0]?.userCode ? (
                      <div className="relative flex gap-3">
                        {/* <img className='w-6 h-7 mt-2' src={notif}></img> */}
                        <button
                          onClick={(e) => navigate(APP_ROUTES.CART)}
                          className="p-2 lg:flex  md:block "
                        >
                          <span className="sr-only">Cart</span>
                          {cartListLength?.length > 0 ||
                          cartListLength?.length > 0 ? (
                            <div>
                              <p className="text-size absolute text-center text-xs -mt-1 text-white font-medium bg-brand-lightgreen rounded-xl h-4 w-4">
                                {cartListLength.length <= 9
                                  ? cartListLength.length
                                  : "9+"}
                              </p>
                              <img
                                className="w-6 h-6 md:w-8 md:h-7"
                                src={shop}
                                alt={shop}
                                id="shoping"
                              />
                            </div>
                          ) : (
                            //<h1>{cartList?.patientDrugPrescriptionOrder?.length}</h1>
                            <img
                              className="w-6 h-6 md:w-8 md:h-7"
                              src={shop}
                              alt={shop}
                              id="shoping"
                            />
                          )}
                        </button>
                      </div>
                    ) : (
                      <div className="relative" />
                    )}
                  </div>
                  <div>
                    {!(
                      (userData.length &&
                        JSON.parse(userData)?.[0]?.userCode) ||
                      (userData.length && JSON.parse(userData)?.[0]?.code)
                    ) ? (
                      <a
                        href={APP_ROUTES.SIGNUP}
                        className="  hidelogin bg-[#004171] text-white"
                        style={{
                          borderRadius: "22px",
                          padding: "2px 10px",
                          fontSize: "12px",
                        }}
                      >
                        Sign up
                      </a>
                    ) : userAvatar?.length > 0 ? (
                      <div className="ml-2 sm:ml-5">
                        <Avatar
                          sx={{
                            bgcolor: "#0272DA",
                            height: "41px",
                            width: "41px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleClick(e, userAvatar)}
                        >
                          {userAvatar}{" "}
                        </Avatar>
                        {/* {screen < 500 ? (
                          <>
                            <Menu
                              anchorEl={anchorEl}
                              open={open === userAvatar}
                              onClose={handleClose}
                              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                              transformOrigin={{ vertical: "top", horizontal: "right" }}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{ top: "85px" }}
                            >
                              <MenuItem
                                id="myDashboard"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                Dashboard
                              </MenuItem>
                              <MenuItem
                                id="myProfile"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                My Profile
                              </MenuItem>

                              <MenuItem
                                id="myDetails"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                {" "}
                                My Details
                              </MenuItem>
                              <MenuItem
                                id="myPlan"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                {" "}
                                My Plan
                              </MenuItem>
                              <MenuItem
                                id="memberProfile"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                Member Profile
                              </MenuItem>
                              <MenuItem
                                id="myPrescription"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                {" "}
                                Prescription
                              </MenuItem>
                              <MenuItem
                                id="myLabreports"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                {" "}
                                Lab Reports
                              </MenuItem>
                              <MenuItem
                                id="myVitals"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                {" "}
                                Vitals
                              </MenuItem>
                              <MenuItem
                                id="myReview"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                {" "}
                                Review
                              </MenuItem>

                              <MenuItem
                                id="changePassword"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                Change Password
                              </MenuItem>
                              <MenuItem
                                id="signOut"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                Sign Out
                              </MenuItem>
                            </Menu>
                          </>
                        ) : (
                          <>
                            <Menu
                              anchorEl={anchorEl}
                              open={open === userAvatar}
                              onClose={handleClose}
                              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                              transformOrigin={{ vertical: "top", horizontal: "right" }}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{ top: "20px" }}
                            >
                              <MenuItem
                                id="myProfile"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                My Profile
                              </MenuItem>

                              <MenuItem
                                id="changePassword"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                Change Password
                              </MenuItem>
                              <MenuItem
                                id="signOut"
                                onClick={(e) => handleAvatarClick(e)}
                              >
                                Sign Out
                              </MenuItem>
                            </Menu>
                          </>
                        )} */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Headernew;
