import "./App.css";
import { Provider } from "react-redux";
import store from "./store/store";
import { OurRoutes } from "./routes";
import HEADER from "./components/homepage/header";
import HEADERNEW from "./components/webflow/headernew";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import FooterMobileVersion from "./components/homepage/FooterMobileVersion";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import loaderGif from "./speed-up-gif.gif";
import { useLocation } from "react-router-dom";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const theme = createTheme({
  palette: {
    primary: {
      main: "#396DDD",
    },
  },
});
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1300); // Simulate a loading period of 3 seconds
  }, []);
  const isHeaderVisible = location.pathname === "/careplan";
  const isHeaderVisiblethankyou = location.pathname === "/thankyoupage";
  const isHeaderVisibleLandingPage2 = location.pathname === "/careplantwo";
  return (
    <AgoraRTCProvider client={client}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          {isLoading ? (
            <div className="loader-container bg-[#fbfbfb]">
              <div className="loader-content">
                <img src={loaderGif} alt="Loading..." className="loaderimg" />
              </div>
            </div>
          ) : (
            <div>
              {/* <HEADER /> */}
              {/* < HEADERNEW /> */}
              {!isHeaderVisible &&
                !isHeaderVisiblethankyou &&
                !isHeaderVisibleLandingPage2 && <HEADERNEW />}
              <OurRoutes />
              <ToastContainer />
            </div>
          )}

          {/* <FooterMobileVersion/> */}
        </Provider>
      </ThemeProvider>
    </AgoraRTCProvider>
  );
}

export default App;
