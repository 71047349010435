import React, { useState, useEffect } from "react";
import logoimg from "../../assets/images/Web Logo.png";
import landingimg from "../../assets/images/landingpageimg.png";
import landingmobimg from "../../assets/images/landingmobileimg.svg";
import Baseline from "../../assets/images/Baseline Visit.png";
import Baseline1 from "../../assets/images/Baseline Visit (1).png";
import Periodic from "../../assets/images/Periodic health checkup.png";
import healthcare from "../../assets/images/healthcare package.png";
import Timely from "../../assets/images/Timely diagnosis.png";
import { APP_ROUTES } from "../../constants/approutes";
import { Card, Grid } from "@mui/material";
import { Dialog } from "primereact/dialog";
import PhoneInput from "react-phone-input-2";
import { fetchCitiesByStates } from "../../store/actions/myprofile";
import loginservice from "../../store/services/loginservice";
import Landingpagefooter from "../../components/webflow/landingpagefooter";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCureBayStatistics } from "../../store/actions/homepage";
import emergency from "../../assets/images/emergency.png";
import doc from "../../assets/images/doc.png";
import hospital from "../../assets/images/hospital.png";

export const Landingpage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameRegex = /^[A-Za-z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex =
    /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{6,}(?=\D*\d\D*$)/;
  const wspRegex = /^\+?\d{1,3}\s?\d{1,14}$/;
  const [isChecked, setIsChecked] = useState(false);
  const [err1, setErr1] = useState("");
  const [cities, setCities] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(getCureBayStatistics());
  }, [dispatch]);

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };
  const { statisticsCount } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    async function fetchData() {
      let payload = {
        countrycode: "IND",
        statecode: "OD",
      };
      const citiesDetailsResponse = await fetchCitiesByStates(payload);
      if (
        citiesDetailsResponse?.status === 200 &&
        citiesDetailsResponse?.data?.succesObject
      ) {
        setCities(citiesDetailsResponse.data.succesObject);
      }
    }

    fetchData();
  }, ["IND", "OD"]);
  console.log(cities, "cities");
  const [userDetails, setUserDetails] = useState({
    Name: "",
    Email: "",
    City: "",
    ConfirmPassword: "",
  });

  // const handleInputChange = (e, countryData) => {
  //   if (countryData) {
  //     const name = "ContactNumber";
  //     const { dialCode, format } = countryData;

  //     setUserDetails((prevUserDetails) => ({
  //       ...prevUserDetails,
  //       [name]: {
  //         countryCode: format.charAt(0) + dialCode,
  //         mobileNumber: e.slice(dialCode.length).trim(),
  //       },
  //     }));
  //   } else {
  //     const { name, value } = e?.target;

  //     const onlyLettersRegex = /^[a-zA-Z]+$/;
  //     if (onlyLettersRegex.test(value) || value === "") {
  //     setUserDetails((prevUserDetails) => ({
  //       ...prevUserDetails,
  //       [name]: value,
  //     }));
  //     }
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;

    if (name === "Name") {
      //const onlyLettersRegex = /^[a-zA-Z]+$/;
      const onlyLettersRegex = /^[a-zA-Z\s]+$/;
      if (onlyLettersRegex.test(value) || value === "") {
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          [name]: value,
        }));
      }
    } else {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: value,
      }));
    }
  };

  const handleInputChangenumber = (e, countryData) => {
    if (countryData) {
      const name = "ContactNumber";
      const { dialCode, format } = countryData;

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: {
          countryCode: format.charAt(0) + dialCode,
          mobileNumber: e.slice(dialCode.length).trim(),
        },
      }));
    }
  };

  const handleInputChangewpnumber = (e, countryData) => {
    if (countryData) {
      const name = "wpcontactnumber";
      const { dialCode, format } = countryData;

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: {
          whatsAppNoCountryCode: format.charAt(0) + dialCode,
          wspNo: e.slice(dialCode.length).trim(),
        },
      }));
    }
  };
  const onSubmitClick = async () => {
    if (userDetails?.Name && !nameRegex.test(userDetails?.Name)) {
      setErr1("Enter a valid name");
    }
    // else if (userDetails?.City === "") {
    //   setErr1("Please select district");
    // }
    else if (
      userDetails?.ContactNumber?.mobileNumber &&
      !phoneRegex.test(userDetails?.ContactNumber?.mobileNumber)
    ) {
      setErr1("Enter valid Phone no");
    } else if (
      !isChecked &&
      userDetails?.wpcontactnumber?.wspNo &&
      !wspRegex.test(userDetails?.wpcontactnumber?.wspNo)
    ) {
      setErr1("Enter valid WhatsApp Number");
    } else if (userDetails?.Email && !emailRegex.test(userDetails?.Email)) {
      setErr1("Enter valid Email id");
    }
    //  if (
    //   (
    //     userDetails?.Name !== "" &&
    //     userDetails?.City !== "" &&
    //     // emailRegex.test(userDetails?.Email) &&
    //     phoneRegex.test(userDetails?.ContactNumber?.mobileNumber) &&
    //     !isChecked &&
    //     wspRegex.test(userDetails?.wpcontactnumber?.wspNo)) ||
    //   (userDetails?.Name !== "" &&
    //     userDetails?.City !== "" &&
    //     // emailRegex.test(userDetails?.Email) &&
    //     phoneRegex.test(userDetails?.ContactNumber?.mobileNumber) &&
    //     isChecked)
    // )
    else {
      setErr1("");
      const payload = {
        firstName: userDetails?.Name,
        countryCode: userDetails?.ContactNumber?.countryCode,
        mobile: userDetails?.ContactNumber?.mobileNumber,
        email: userDetails?.Email?.toLowerCase(),
        whatsAppNumber: !isChecked
          ? userDetails?.wpcontactnumber?.wspNo
          : userDetails?.ContactNumber?.mobileNumber,
        whatsAppNoCountryCode: !isChecked
          ? userDetails?.wpcontactnumber?.whatsAppNoCountryCode
          : userDetails?.ContactNumber?.countryCode,
        country: "India",
        state: "Odisha",
        city: userDetails?.City,
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "landingpage_lead_generate",
        ecommerce: {
          value: 100,
          items: [
            {
              firstName: userDetails?.Name,
              countryCode: userDetails?.ContactNumber?.countryCode,
              mobile: userDetails?.ContactNumber?.mobileNumber,
              email: userDetails?.Email?.toLowerCase(),
              whatsAppNumber: !isChecked
                ? userDetails?.wpcontactnumber?.wspNo
                : userDetails?.ContactNumber?.mobileNumber,
              whatsAppNoCountryCode: !isChecked
                ? userDetails?.wpcontactnumber?.whatsAppNoCountryCode
                : userDetails?.ContactNumber?.countryCode,
              country: "India",
              state: "Odisha",
              city: userDetails?.City,
            },
          ],
        },
      });
      try {
        const res = await loginservice.landingpage(payload);
        // setLoading(false);
        // handleClose();
        if (res?.status && res?.data?.responseCode === "200") {
          // setSnackbarMessage(res.data?.responseMessage, "success");
          navigate("/thankyoupage");
        }
        //else if (res?.data?.responseCode === "500") {
        //   setShowresponsedata(res?.data?.responseMessage);
        //   setShowModal(true);
        // } else {
        //   setSnackbarMessage(res?.data?.responseMessage, "error");
        // }
      } catch (error) {
        console.error("Failed to save", error);
      }
    }
  };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     const cardElement = document.querySelector('.fadeInAnimation');
  //     if (cardElement) {
  //       cardElement.classList.remove('hidden');
  //     }
  //   }, 5000);

  //   return () => clearTimeout(timer);
  // }, []);

  // const individualDescription = () => {
  //   setVisible(true);
  // };
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 2000);

    // Clear the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="sm:block hidden">
        <div className="container-default w-container">
          <div className="mt-7 sm:mb-[100px] mb-[80px]">
            <a
              href={APP_ROUTES.DASHBOARD}
              aria-current="page"
              className="sm:ml-[115px] ml-5  header-logo-link w-nav-brand w--current "
            >
              <img src={logoimg} className="" alt="" />
            </a>
          </div>

          <div
            className=""
            style={{
              // display: 'flex',
              // justifyContent: 'flex-start',
              backgroundImage: `url("${landingimg}")`,
              // backgroundSize: "contain",
              height: "785px",
              backgroundRepeat: "no - repeat",
              backgroundSize: "cover",
              backgroundSize: "100% 100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <div className="container">
                {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
                <div className="bg-transparent  px-[77px] w-full flex gap-6">
                  <div className="w-[429px]">
                    <p className="text-white md:text-40px text-2xl font-semibold sm:leading-54px md:leading-36px  font-poppins">
                      Worried about your elderly parents' health back home in
                      Odisha?
                    </p>
                    <p className="text-white">
                      CureBay Totalcare provides advanced healthcare solutions
                      through its network of{" "}
                      {statisticsCount?.succesObject?.eclinicsCount} eClinics in
                      Odisha, in partnership with the best doctors, diagnostic
                      laboratories and hospitals.
                    </p>
                  </div>
                  <div className="ml-[315px] hidden sm:block">
                    <p className="text-white mb-6 font-semibold">
                      {" "}
                      No more Worries.
                      <br />
                      <span className="font-normal">
                        {" "}
                        Take Care From Anywhere
                      </span>
                    </p>
                    <Card
                      sx={{
                        borderColor: "#396DDD52",
                        borderWidth: "0.5px",
                        borderRadius: "20px",

                        width: "354px",
                        backgroundColor: "#F4F8FE",
                      }}
                      className="max-w-1400"
                    >
                      <div className="grid justify-start px-6 pt-2 pb-2">
                        <div>
                          <div className="grid">
                            <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                              Name
                              {/* <span className="text-red-500"> *</span> */}
                            </label>
                            <input
                              name="Name"
                              className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                              type="text"
                              required
                              value={userDetails?.Name}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="grid">
                            <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                              District
                              {/* <span className="text-red-500"> *</span> */}
                            </label>
                            <select
                              onChange={handleInputChange}
                              value={userDetails?.City || ""}
                              fullWidth
                              isSearchable
                              size="small"
                              name="City"
                              required
                              className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                            >
                              <option value="">Select District</option>
                              {cities &&
                                cities?.map((value, index) => (
                                  <option value={value.description} key={index}>
                                    {value.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="grid py-2">
                            <label className="text-brand-lightBlack font-poppins pt-1 capitalize text-[14px]">
                              Mobile Number
                              {/* <span className="text-red-500"> *</span> */}
                            </label>
                            <div className="flex items-center">
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                onChange={handleInputChangenumber}
                                value={`${userDetails?.ContactNumber?.countryCode} ${userDetails?.ContactNumber?.mobileNumber}`}
                              />
                            </div>
                          </div>

                          <div className="grid py-2">
                            <div className="flex justify-start items-start font-poppins ">
                              <input
                                className="mr-3"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheck}
                              ></input>
                              <label className="font-poppins text-[12px] ">
                                Whatsapp number is the same as the Mobile number
                                {/* <span className="text-red-500"> *</span> */}
                              </label>
                            </div>

                            {!isChecked ? (
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                onChange={handleInputChangewpnumber}
                                value={`${userDetails?.wpcontactnumber?.whatsAppNoCountryCode} ${userDetails?.wpcontactnumber?.wspNo}`}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="grid pt-2 pb-6">
                            <label className="text-brand-lightBlack capitalize font-poppins text-[14px] ">
                              Email
                              {/* <span className="text-red-500"> *</span> */}
                            </label>
                            <input
                              name="Email"
                              className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px]  w-full"
                              type="text"
                              onChange={(e) => handleInputChange(e)}
                              value={userDetails?.Email}
                            />
                          </div>
                        </div>
                        {err1 && (
                          <span className="text-red-600 font-normal text-xs pb-4 text-center tracking-widest">
                            {err1}
                          </span>
                        )}

                        <button
                          type="submit"
                          className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"
                          variant="contained"
                          onClick={() => onSubmitClick()}
                        >
                          Submit
                        </button>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-16">
            <h2 class="text-center pb-5">Why CureBay Totalcare?</h2>

            <div className="grid sm:grid-cols-4 grid-cols-2 gap-4">
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Timely}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center w-full sm:text-lg text-sm font-bold mt-[23px]">
                    Timely diagnosis and treatment
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Periodic}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center w-full sm:text-lg text-sm font-bold mt-[23px]">
                    Periodic health check-ups with instant online Reports
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Baseline}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Instant emergency intervention
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={healthcare}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    5 Healthcare packages to choose.
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Baseline1}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Daily health updates
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={hospital}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Best Hospital Partners such as, Apollo Hospital, Utkal
                    Hospital, Sparsh Hospital
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={doc}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Experienced Doctors visiting home
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={emergency}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Emergency Ambulance Service
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-container _986px center my-20">
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/IGAq_N_OLoY?si=Zr29uGPKfLYn0-1C"
                frameBorder="0"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                // title={`Slide ${index + 1}`}
              />
            </div>

            <div className="text-center mt-[62px]">
              <a
                href={APP_ROUTES.DASHBOARD}
                className="btn-primary py-[14px] px-[48px] "
              >
                Know More
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile screen */}
      <div className="block sm:hidden">
        <div className="container-default w-container">
          <div className="mt-7 sm:mb-[100px] mb-[80px]">
            <a
              href={APP_ROUTES.DASHBOARD}
              aria-current="page"
              className="sm:ml-[115px] ml-5  header-logo-link w-nav-brand w--current "
            >
              <img src={logoimg} className="" alt="" />
            </a>
          </div>

          <div
            className=" block px-5"
            style={{
              // display: 'flex',
              // justifyContent: 'flex-start',
              backgroundImage: `url("${landingmobimg}")`,
              // backgroundSize: "contain",
              height: "auto",
              backgroundRepeat: "no - repeat",
              backgroundSize: "cover",
              backgroundSize: "100% 100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              <div className="container">
                {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
                <div className="bg-transparent  pr-[24px] w-full flex ">
                  <div className="">
                    <p className="text-white text-2xl font-semibold  font-poppins">
                      Worried about your elderly parents' health back home in
                      Odisha?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full  block">
            <p className="text-[#004171] mb-6 font-normal mt-6">
              No more Worries.
              <br />
              <span className="font-semibold"> Take Care From Anywhere </span>
            </p>
            <p className="text-[#004171] mb-6">
              CureBay Totalcare provides advanced healthcare solutions through
              its network of 75+ eClinics in Odisha, in partnership with the
              best doctors, diagnostic laboratories and hospitals.
            </p>
            <Card
              sx={{
                borderColor: "#396DDD52",
                borderWidth: "0.5px",
                borderRadius: "20px",
                width: "auto",
                backgroundColor: "#F4F8FE",
              }}
              className="max-w-1400"
            >
              <div className="grid justify-start px-6 pt-2 pb-2">
                <div>
                  <div className="grid">
                    <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                      Name
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                    <input
                      name="Name"
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                      type="text"
                      required
                      value={userDetails?.Name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="grid">
                    <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                      District
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                    <select
                      onChange={handleInputChange}
                      value={userDetails?.City || ""}
                      fullWidth
                      isSearchable
                      size="small"
                      name="City"
                      required
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                    >
                      <option value="">Select District</option>
                      {cities &&
                        cities?.map((value, index) => (
                          <option value={value.description} key={index}>
                            {value.description}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="grid py-2">
                    <label className="text-brand-lightBlack font-poppins pt-1 capitalize text-[14px]">
                      Mobile Number
                      {/* <span className="text-red-500"> *</span>  */}
                    </label>
                    <div className="flex items-center">
                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        onChange={handleInputChangenumber}
                        value={`${userDetails?.ContactNumber?.countryCode} ${userDetails?.ContactNumber?.mobileNumber}`}
                      />
                    </div>
                  </div>

                  <div className="grid py-2">
                    <div className="flex justify-start items-start font-poppins ">
                      <input
                        className="mr-3"
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheck}
                      ></input>
                      <label className="font-poppins text-[12px] ">
                        Whatsapp number is the same as the Mobile number
                        {/* <span className="text-red-500"> *</span> */}
                      </label>
                    </div>

                    {!isChecked ? (
                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        onChange={handleInputChangewpnumber}
                        value={`${userDetails?.wpcontactnumber?.whatsAppNoCountryCode} ${userDetails?.wpcontactnumber?.wspNo}`}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="grid pt-2 pb-6">
                    <label className="text-brand-lightBlack capitalize font-poppins text-[14px] ">
                      Email
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                    <input
                      name="Email"
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px]  w-full"
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      value={userDetails?.Email}
                    />
                  </div>
                </div>
                {err1 && (
                  <span className="text-red-600 font-normal text-xs pb-4 text-center tracking-widest">
                    {err1}
                  </span>
                )}

                <button
                  type="submit"
                  className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"
                  variant="contained"
                  onClick={() => onSubmitClick()}
                >
                  Submit
                </button>
              </div>
            </Card>
          </div>

          <div className="w-full mt-16">
            <h2 class="text-center pb-5">Why CureBay Totalcare?</h2>

            <div className="grid sm:grid-cols-5 grid-cols-2 gap-4">
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Timely}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center w-full sm:text-lg text-sm font-bold mt-[23px]">
                    Timely diagnosis and treatment
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Periodic}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center w-full sm:text-lg text-sm font-bold mt-[23px]">
                    Periodic health check-ups with instant online Reports
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={healthcare}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Instant emergency intervention
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Baseline}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    5 Healthcare packages to choose.
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={Baseline1}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Daily health updates
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={hospital}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Best Hospital Partners such as, Apollo Hospital, Utkal
                    Hospital, Sparsh Hospital
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={doc}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Experienced Doctors visiting home
                  </div>
                </div>
              </div>
              <div
                className="card features"
                style={{ display: "block", textAlign: "center" }}
              >
                <img
                  src={emergency}
                  loading="lazy"
                  alt=""
                  className="icon sm:w-[84px] w-[70px]"
                />
                <div>
                  <div className="text-center sm:text-lg text-sm font-bold mt-[23px]">
                    Emergency Ambulance Service
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inner-container _986px center my-20">
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://www.youtube.com/embed/IGAq_N_OLoY?si=Zr29uGPKfLYn0-1C"
                frameBorder="0"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                // title={`Slide ${index + 1}`}
              />
            </div>

            <div className="text-center mt-[62px]">
              <a
                href={APP_ROUTES.DASHBOARD}
                className="btn-primary py-[14px] px-[48px] "
              >
                Know More
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <Dialog
        visible={visible}
        header={<span style={{ color: "#004171" }}>Request a Callback</span>}
        onHide={() => setVisible(false)}
      >
        <Card
          sx={{
            borderColor: "#396DDD52",
            borderWidth: "0.5px",
            borderRadius: "20px",
            width: "auto",
            backgroundColor: "#F4F8FE",
          }}
          className="max-w-1400"
        >
          <div className="grid justify-start px-6 pt-2 pb-2">
            <div>
              <div className="grid">
                <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                  Name

                </label>
                <input
                  name="Name"
                  className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                  type="text"
                  required
                  value={userDetails?.Name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="grid">
                <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                  District

                </label>
                <select
                  onChange={handleInputChange}
                  value={userDetails?.City || ""}
                  fullWidth
                  isSearchable
                  size="small"
                  name="City"
                  required
                  className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                >
                  <option value="">Select District</option>
                  {cities &&
                    cities?.map((value, index) => (
                      <option value={value.description} key={index}>
                        {value.description}
                      </option>
                    ))}
                </select>
              </div>
              <div className="grid py-2">
                <label className="text-brand-lightBlack font-poppins pt-1 capitalize text-[14px]">
                  Mobile Number

                </label>
                <div className="flex items-center">
                  <PhoneInput
                    country={"us"}
                    enableSearch={true}
                    onChange={handleInputChangenumber}
                    value={`${userDetails?.ContactNumber?.countryCode} ${userDetails?.ContactNumber?.mobileNumber}`}
                  />
                </div>
              </div>

              <div className="grid py-2">
                <div className="flex justify-start items-start font-poppins ">
                  <input
                    className="mr-3"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheck}
                  ></input>
                  <label className="font-poppins text-[12px] ">
                    Whatsapp number is the same as the Mobile number

                  </label>
                </div>

                {!isChecked ? (
                  <PhoneInput
                    country={"us"}
                    enableSearch={true}
                    onChange={handleInputChangewpnumber}
                    value={`${userDetails?.wpcontactnumber?.whatsAppNoCountryCode} ${userDetails?.wpcontactnumber?.wspNo}`}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="grid pt-2 pb-6">
                <label className="text-brand-lightBlack capitalize font-poppins text-[14px] ">
                  Email

                </label>
                <input
                  name="Email"
                  className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px]  w-full"
                  type="text"
                  onChange={(e) => handleInputChange(e)}
                  value={userDetails?.Email}
                  required
                />
              </div>
            </div>
            {err1 && (
              <span className="text-red-600 font-normal text-xs pb-4 text-center tracking-widest">
                {err1}
              </span>
            )}

            <button
              type="submit"
              className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"
              variant="contained"
              onClick={() => onSubmitClick()}
            >
              Submit
            </button>
          </div>
        </Card>
        <p
          style={{
            marginTop: "13px",
            color: "rgb(0, 65, 113)",
            fontSize: " 16px",
            fontWeight: "700",
          }}
        >
          Visit Our Site:{" "}
          <a
            className=" underline underline-offset-2"
            href="https://curebaytotalcare.com/"
          >
            curebaytotalcare.com
          </a>
        </p>
      </Dialog> */}

      <Landingpagefooter />
    </>
  );
};
export default Landingpage;
