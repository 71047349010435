import { APP_ROUTES } from "../../constants/approutes";
import Mydetailsicon from "../../images/icons/mydetails-black.svg";
import Myplanicon from "../../images/icons/myplan-black.svg";
import Healthcardicon from "../../images/icons/Health Records (Black Icon).svg";
import revieIcon from "../../images/icons/myreview-black.svg";



import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState,useEffect } from "react";

function MyprofileSidemenu(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const loginObjStr = localStorage.getItem('loginObj');
    const loginObj = JSON.parse(loginObjStr);
    const userObj = loginObj?.[0];
    const userCode = userObj?.userCode ? userObj.userCode : null;
    const [expand, setExpand] = useState(location.pathname === APP_ROUTES.MYPRESCRIPTION || location.pathname === APP_ROUTES.MYLABREPORTS || location.pathname === APP_ROUTES.MYVITALS ? true : false)

    const handleExpand = () => {

        setExpand(!expand)
    }
    console.log(userCode, "coming")
    return (
        <>
            <div>
                <ul className="grid pl-0">
                    <li onClick={() => { navigate(APP_ROUTES.MYDETAILS) }} className={`!inline-block relative before:absolute before:w-full before:top-16  before:border-b my-5 flex py-2 items-center text-base font-normal leading-7 text-brand-lightBlack cursor-pointer 
                        ${location.pathname === APP_ROUTES.MYDETAILS ? "text-white rounded-[200px] bg-seegreen" : "text-shuttle-gray-800"} `}>
                        <div className={`flex items-center px-6  ${location.pathname === APP_ROUTES.MYDETAILS ? "brightness-200" : ""}`}>
                            <img className="mr-5" src={Mydetailsicon} /> My Profile
                        </div>
                    </li>
                    <li onClick={() => { navigate(APP_ROUTES.MYPLAN) }} className={`!inline-block relative before:absolute before:w-full before:top-16  before:border-b my-5 flex py-2 items-center text-base font-normal leading-7 text-brand-lightBlack cursor-pointer  
                    ${location.pathname === APP_ROUTES.MYPLAN ? "text-white rounded-[200px] bg-seegreen" : "text-shuttle-gray-800"} `}>
                        <div className={`flex items-center px-6 ${location.pathname === APP_ROUTES.MYPLAN ? "brightness-200" : ""}`}>
                            <img className="mr-5" src={Myplanicon} /> My Plans
                        </div>
                    </li>
                    {userCode &&
                        <li onClick={() => { navigate(APP_ROUTES.MEMBERDETAILS) }} className={`!inline-block relative before:absolute before:w-full before:top-16  before:border-b my-5 flex py-2 items-center text-base font-normal leading-7 text-brand-lightBlack cursor-pointer 
                        ${location.pathname === APP_ROUTES.MEMBERDETAILS ? "text-white rounded-[200px] bg-seegreen" : "text-shuttle-gray-800"} `}>
                            <div className={`flex items-center px-6  ${location.pathname === APP_ROUTES.MEMBERDETAILS ? "brightness-200" : ""}`}>
                                <img className="mr-5" src={Mydetailsicon} />
                                Care Recipient's Profile
                            </div>
                        </li>}
                    <li onClick={() => { navigate(APP_ROUTES.MYPRESCRIPTION) }} className={`!inline-block relative before:absolute before:w-full before:top-16  my-5 flex py-2 items-center text-base font-normal leading-7 text-brand-lightBlack cursor-pointer 
                    ${location.pathname === APP_ROUTES.HEALTHRECORDS || location.pathname === APP_ROUTES.MYPRESCRIPTION || location.pathname === APP_ROUTES.MYLABREPORTS || location.pathname === APP_ROUTES.MYVITALS ? "text-white rounded-[200px] bg-seegreen" : "text-shuttle-gray-800"} `}>
                        <div className="flex justify-between items-center px-6">
                            <div className={`flex items-center ${location.pathname === APP_ROUTES.HEALTHRECORDS || location.pathname === APP_ROUTES.MYPRESCRIPTION || location.pathname === APP_ROUTES.MYLABREPORTS || location.pathname === APP_ROUTES.MYVITALS ? "brightness-200" : ""}`}>
                                <img className="mr-5" src={Healthcardicon} />
                                Health Records
                            </div>
                            <div className="">
                                {expand ? <RemoveCircleIcon onClick={handleExpand} /> : <AddCircleIcon onClick={handleExpand} />}
                            </div>
                        </div>
                    </li>
                    <li className={`${!expand ? "hidden" : "pl-16"}`}>
                        <p className={`${location.pathname === APP_ROUTES.MYPRESCRIPTION ? "text-lg font-bold text-start cursor-pointer" : "text-base text-start cursor-pointer"}`} onClick={() => navigate(APP_ROUTES.MYPRESCRIPTION)} >Prescription</p>
                        <p className={`${location.pathname === APP_ROUTES.MYLABREPORTS ? "text-lg font-bold text-start cursor-pointer" : "text-base text-start cursor-pointer"}`} onClick={() => navigate(APP_ROUTES.MYLABREPORTS)}> Lab Reports</p>
                        <p className={`${location.pathname === APP_ROUTES.MYVITALS ? "text-lg font-bold text-start cursor-pointer" : "text-base text-start cursor-pointer"}`} onClick={() => navigate(APP_ROUTES.MYVITALS)}>Vitals</p>
                        <p className={`${location.pathname === APP_ROUTES.ECGREPORT ? "text-lg font-bold text-start cursor-pointer" : "text-base text-start cursor-pointer"}`} onClick={() => navigate(APP_ROUTES.ECGREPORT)}>Ecg Report</p>
                    </li>
                    <li onClick={() => { navigate(APP_ROUTES.MYREVIEW) }} className={`!inline-block relative before:absolute before:w-full before:top-16  before:border-b border-t my-5 flex py-2 items-center text-base font-normal leading-7 text-brand-lightBlack cursor-pointer 
                        ${location.pathname === APP_ROUTES.MYREVIEW ? "text-white rounded-[200px] bg-seegreen" : "text-shuttle-gray-800"} `}>
                        <div className={`flex items-center px-6  ${location.pathname === APP_ROUTES.MYREVIEW ? "brightness-200" : ""}`}>
                            <img className="mr-5" src={revieIcon} /> My Review
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default MyprofileSidemenu;