import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  FormControl,
  Input,
  Chip,
} from "@mui/material";
import loginservice from "../../store/services/loginservice";
import Footer from "../../components/webflow/postloginfooter";
import { useDispatch, useSelector } from "react-redux";
import { getOurCareplans } from "../../store/actions/howitworks";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import hearthealth from "../../assets/images/Heart Health.svg";
import diabatic from "../../assets/images/Diabetic Care.svg";
import general from "../../assets/images/General Health.svg";
import Womens from "../../assets/images/Womens' Health.svg";
import ortho from "../../assets/images/Ortho Care.svg";
import { useSnackbar } from "../common/snackbar";
import { updateCart } from "../../store/actions/login";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import Img from "../../assets/images/cart.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { TabContext, TabPanel } from "@mui/lab";
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";
import jsPDF from "jspdf";
import axios from "axios";

const Cart = () => {
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [memberDetails, setMemberDetails] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [careplanDetails, setCarePlanDetails] = useState([]);
  const userData = useSelector((state) => state.AuthReducer.userData);
  const [paymentDuration, setPaymentDuration] = useState({});
  const [packageDuration, setPackageDuration] = useState({});
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedMemberaddress, setSelectedMemberaddress] = useState("");
  const [showAnchor, setShowAnchor] = useState(false);
  const [initializedItems, setInitializedItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const cbInstanceRef = useRef(null);
  const cartRef = useRef(null);
  const navigate = useNavigate();
  const [bytecode, setBytecode] = useState();
  const [usefirstName, setfirstName] = useState();
  const [responseData, setResponseData] = useState({});
  const [subscriptionConsent, setSubscriptionConsent] = useState(<></>);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "0.875rem",
      lineHeight: "2",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  const isMobile = () => window.innerWidth <= 768;
  useEffect(() => {
    const initializeChargebee = () => {
      const isProd = window.location.hostname === "curebaytotalcare.com";
      const site = isProd ? "curebay" : "curebay-test";

      cbInstanceRef.current = window.Chargebee.init({
        site: site,
        isItemsModel: true,
      });
      cartRef.current = cbInstanceRef.current.getCart();
    };

    initializeChargebee();
  }, []);

  useEffect(() => {
    async function fetchSubscriptionConsent() {
      // let response = await axios.get()
      // console.log(response , "sdvsdhiuvgsdivsdvs")
      // if(response.data && response.data.data.length){
      //   // const updatedHtml = data.replace(/\${patientName}/g, userName);
      //  let gcpResponse = await fetch(`${process.env.REACT_APP_IMAGE_URL}${response.data.data[0].consentFile}`)
      //  let htmlResult = await gcpResponse.text()
      //  console.log(htmlResult, "svsdvbsdbvisdvsdvsd")
      //   setSubscriptionConsent(response.data.data[0])
      // }

      // Fetch the consent file information
      let apiEndpoint = `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=TC_W_SC`;
      fetch(apiEndpoint)
        .then((response) => response.json())
        .then((data) => {
          console.log(data, "sdvksdvhsdivdssdvsdvs");
          if (data.data.length === 0) {
            return;
          }
          const htmlPath = data.data[0].consentFile;
          // Construct the full URL to the HTML file if needed
          const fullHtmlUrl = `${process.env.REACT_APP_IMAGE_URL}${htmlPath}`;
          return fetch(fullHtmlUrl);
        })
        .then((response) => response.text())
        .then((html) => {
          setSubscriptionConsent(html);
          // setHtmlContent(updatedHtml);
        })
        .catch((error) => console.error("Error fetching HTML content:", error));
    }

    fetchSubscriptionConsent();
  }, [userData]);

  useEffect(() => {
    const isProd = window.location.hostname === "curebaytotalcare.com";
    const site = isProd ? "curebay" : "curebay-test";

    cbInstanceRef.current = window.Chargebee.init({
      site: site,
      isItemsModel: true,
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      const carePlansResponse = await getOurCareplans();
      if (
        carePlansResponse.status === 200 &&
        carePlansResponse?.data?.succesObject
      ) {
        setCarePlanDetails(carePlansResponse.data.succesObject);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const loginObjStr = localStorage.getItem("loginObj");
    const loginObj = JSON.parse(loginObjStr);
    const userObj = loginObj?.[0];

    let data = userData.length ? JSON.parse(userData) : userObj ? userObj : "";
    setfirstName(userObj.firstName);

    let payload = {
      code: data?.[0]?.userCode
        ? data[0].userCode
        : data.userCode
        ? data.userCode
        : null,
      email: data?.[0]?.email,
      mobile: data?.[0]?.mobile,
    };
    loginservice
      .fetchUserList(payload)
      .then((res) => {
        setMemberDetails(res?.data?.succesObject);
      })
      .catch((error) => {
        console.error("Failed to fetch user list:", error);
      });
  }, []);

  useEffect(() => {
    let data = userData.length ? JSON.parse(userData) : "";
    if (cartList?.length === 0) {
      setIsLoading(true);
      let payload = {
        userCode: data?.[0]?.userCode
          ? data[0].userCode
          : data?.[1]?.patientCode
          ? data[1].patientCode
          : null,
      };
      loginservice
        .cartList(payload)
        .then((res) => {
          setCartList(res?.data?.succesObject);
          dispatch({
            type: COMMONCONSTANTS.SUCCESS_FETCHCART,
            payload: res?.data?.succesObject,
          });
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userData, cartList.length, dispatch]);

  useEffect(() => {
    cartList.forEach((rec, index) => {
      if (rec?.period === "monthly" && !initializedItems[index]) {
        setPaymentDuration((prevState) => ({
          ...prevState,
          [index]: "MONTHLY",
        }));
        setInitializedItems((prevState) => ({ ...prevState, [index]: true }));
      } else if (rec?.period === "yearly" && !initializedItems[index]) {
        setPaymentDuration((prevState) => ({
          ...prevState,
          [index]: "ONETIMEPAYMENT",
        }));
        setInitializedItems((prevState) => ({ ...prevState, [index]: true }));
      }
    });
  }, [cartList, initializedItems, setPaymentDuration]);

  const handlePaymentDurationChange = (event, index) => {
    const { value } = event.target;
    setPaymentDuration((prevState) => ({ ...prevState, [index]: value }));
    // Update the initialized status to true for this index
    setInitializedItems((prevState) => ({ ...prevState, [index]: true }));
  };

  const handlePackageDurationChange = (event, index) => {
    const { name, value } = event.target;
    setPackageDuration((prevState) => ({ ...prevState, [index]: value }));
  };

  // const handleMemberChange = (event, index) => {
  //   const { name, value } = event.target;
  //   setSelectedMember((prevState) => ({ ...prevState, [index]: value }));

  //   // setSelectmemberaddress
  // };

  const handleMemberChange = (event, index, planId) => {
    const { value } = event.target;
    // Check if the plan allows multiple selections
    const allowsMultipleSelection = planId === 10 || planId === 11;

    // Handle multiple selections
    if (allowsMultipleSelection) {
      setSelectedMember((prevState) => ({
        ...prevState,
        [index]:
          typeof value === "string"
            ? value.split(",").slice(0, 2)
            : value.slice(0, 2), // Restrict to 2 selections
      }));
    } else {
      // Handle single selection
      setSelectedMember((prevState) => ({
        ...prevState,
        [index]: value,
      }));
    }

    // Optionally handle address or other state updates here
    // setSelectmemberaddress(...);
  };

  const handleDateChange = (date, index) => {
    let data = [...cartList];
    data[index] = {
      ...data[index],
      effectiveStartDate: date,
    };
    setCartList(data);
  };

  // const effectivedate = cartList.effectiveStartDate;
  // console.log(cartList[0].effectiveStartDate, cartList, 'cartList')

  // const deleteFromCart = async (e, value) => {
  //     let data = JSON.parse(userData);
  //     let payload;
  //     if (value === 'single') {
  //         payload = {
  //             "userCode": data?.[0]?.userCode,
  //             "cartType": 2,
  //             "cartVOList": [
  //                 {
  //                     "userId": data?.[0]?.userSeqId,
  //                     "userCode": data?.[0]?.userCode,
  //                     "planId": e?.planId,
  //                     "planName": e?.planName,
  //                     "cartId": e?.cartId
  //                 },
  //             ]
  //         }
  //     }
  //     else {
  //         payload = {
  //             "userCode": data?.[0]?.userCode,
  //             "cartType": 3
  //         }
  //     }
  //     const res = await updateCart(payload);
  //     if (res?.status && res?.data?.responseCode === '200') {
  //         setCartList([]);
  //     }
  // };

  // const [isLoading, setShowLoader] = useState(false);
  // useEffect(() => {
  //     if (isLoading) {

  //         setShowLoader(true);

  //         const timer = setTimeout(() => {
  //             setIsLoading(false);
  //             setShowLoader(false);
  //         }, 1000);
  //         return () => clearTimeout(timer);
  //     }
  // }, [isLoading]);

  const deleteFromCart = async (e, value) => {
    try {
      let data = JSON.parse(userData);

      let payload = [];

      if (value === "single") {
        console.log(
          data?.[0]?.userCode,
          data?.[0]?.userSeqId,
          e?.planId,
          e?.planName,
          e?.cartId,
          value,
          "data are coming2"
        );
        payload = {
          userCode: data?.[0]?.userCode,
          cartType: 2,
          cartVOList: [
            {
              userId: data?.[0]?.userSeqId,
              userCode: data?.[0]?.userCode,
              planId: e?.planId,
              planName: e?.planName,
              cartId: e?.cartId,
            },
          ],
        };
      } else {
        payload = {
          userCode: data?.[0]?.userCode,
          cartType: 3,
        };
      }

      const res = await updateCart(payload);
      setIsLoading(false);

      if (res?.status && res?.data?.responseCode === "200") {
        setCartList([]);

        localStorage.removeItem("responseData");
        // setTimeout(() => {
        //     setCartList([]);
        // }, 2000);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while removing the items", "error");
    }
  };

  const convertToPDFAndSendToAPI = async () => {
    const content = document.getElementById("contentContainer").innerText;
    const pdf = new jsPDF();

    let yPosition = 10;
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width - 2 * margin;

    pdf.setFontSize(12);

    const addPage = () => {
      pdf.addPage();
      yPosition = margin;
    };

    pdf.setTextColor(0, 0, 0);

    const lines = pdf.splitTextToSize(content, pageWidth);

    lines.forEach((line) => {
      if (
        yPosition + pdf.getTextDimensions(line).h >
        pdf.internal.pageSize.height - margin
      ) {
        addPage();
      }

      pdf.setTextColor(0, 0, 0);

      pdf.text(line, margin, yPosition);
      yPosition += pdf.getTextDimensions(line).h + 2;
    });

    // pdf.save('consent.pdf');
    // const byteArray = pdf.output();

    const base64String = pdf.output("dataurlstring");
    const base64WithoutPrefix = base64String.split(",")[1];

    console.log(base64WithoutPrefix, "base64");
    setBytecode(base64WithoutPrefix);
  };
  useEffect(
    (responseData) =>
      cbInstanceRef.current.setCheckoutCallbacks(function (cart) {
        const storedResponseDataString = localStorage.getItem("responseData");
        const storedResponseData = JSON.parse(storedResponseDataString);
        return {
          success: function (hpid) {
            console.log("successssss", hpid, responseData);

            deleteFromCart(storedResponseData, "single");
            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                value: 100,
                items: storedResponseData,
              },
            });

            // deleteFromCart(responseData, "single");
          },
        };
      }),
    []
  );
  const openCheckout = (
    e,
    planDuration,
    patientDetails,
    consentFormUrl,
    planDuration1 = {},
    consentFormUrl1 = ""
  ) => {
    console.log(planDuration, "Sdvisdouvsgdouvdsvsds", patientDetails);
    let customerEmail;
    let data = userData.length ? JSON.parse(userData) : "";
    customerEmail = data[0]?.email;
    const planMappings = {
      MONTHLY: {
        1: "General-Health-Care-INR-Monthly",
        3: "Diabetes-Care-INR-Monthly",
        4: "Cardiac-Care-INR-Monthly",
        5: "Ortho-Care-INR-Monthly",
        6: "Womens-Health-Care-INR-Monthly",
        8: "Gold-Plan-INR-Monthly",
        9: "Platinum-Plan-INR-Monthly",
        10: "Gold-Plan-Combo-INR-Monthly",
        11: "Platinum-Plan-Combo-INR-Monthly",
      },
      ONETIMEPAYMENT: {
        1: "General-Health-Care-INR-Yearly",
        3: "Diabetes-Care-INR-Yearly",
        4: "Cardiac-Care-INR-Yearly",
        5: "Ortho-Care-INR-Yearly",
        6: "Womens-Health-Care-INR-Yearly",
        8: "Gold-Plan-INR-Yearly",
        9: "Platinum-Plan-INR-Yearly",
        10: "Gold-Plan-Combo-INR-Yearly",
        11: "Platinum-Plan-Combo-INR-Yearly",
      },
    };
    const planId =
      planMappings[planDuration?.list?.[0]?.paymentType]?.[e.planId];
    const planPriceQuantity = 1;
    const product = cbInstanceRef.current?.initializeProduct?.(
      planId,
      planPriceQuantity
    );
    product.data["cf_patient"] = planDuration1?.list?.length
      ? patientDetails[0].name + "," + patientDetails[1].name
      : patientDetails[0].name;
    product.data["cf_relation"] = planDuration1?.list?.length
      ? patientDetails[0].relation + "," + patientDetails[1].relation
      : patientDetails[0].relation;
    product.data["cf_usercode"] = planDuration?.list?.[0]?.userCode;
    product.data["cf_userid"] = planDuration?.list?.[0]?.userId;
    product.data["cf_cpseqid"] = planDuration?.list?.[0]?.cpSeqId;
    product.data["cf_patientid"] = planDuration1?.list?.length
      ? planDuration?.list?.[0]?.patientId +
        "," +
        planDuration1?.list?.[0]?.patientId
      : planDuration?.list?.[0]?.patientId;
    product.data["cf_patientcode"] = planDuration1?.list?.length
      ? planDuration?.list?.[0]?.patientCode +
        "," +
        planDuration1?.list?.[0]?.patientCode
      : planDuration?.list?.[0]?.patientCode;
    product.data["cf_subsperiod"] = planDuration?.list?.[0]?.subsPeriod;
    product.data["cf_totalamount"] = planDuration?.list?.[0]?.totalAmount;
    product.data["cf_paymenttype"] = planDuration?.list?.[0]?.paymentType;
    product.data["cf_startdate"] = planDuration?.list?.[0]?.date;
    product.data["cf_consentformflag"] = "1";
    product.data["cf_consentform"] = consentFormUrl1
      ? consentFormUrl + consentFormUrl1
      : consentFormUrl;

    cartRef.current.replaceProduct(product);

    const customer = {
      first_name: "",
      last_name: "",
      company: "",
      email: customerEmail,
      phone: "",
    };
    cartRef.current.setCustomer(customer);
    cartRef.current.proceedToCheckout();
  };

  // const OnSaveClick = () => {
  //     const addSubscription = (e) => {
  //         const loginObjStr = localStorage.getItem('loginObj');
  //         const loginObj = JSON.parse(loginObjStr);
  //         const userObj = loginObj?.[0];
  //         const request = {
  //             list: cartList.map((rec, index) => {
  //                 return {
  //                     cpSeqId: rec?.planId,
  //                     userId: userObj?.userSeqId,
  //                     userCode: userObj?.userCode,
  //                     patientCode: selectedMember[index]?.code,
  //                     patientId: selectedMember[index]?.id,
  //                     subsPeriod: packageDuration[index],
  //                     totalAmount: totalAmount,
  //                     paymentType: paymentDuration[index],
  //                     date: selectedDates[index]?.date
  //                 };
  //             })
  //         };
  //         const errors = [];

  //         if (cartList?.length !== (Object.keys(selectedMember)?.length)) {
  //             errors.push('Please select member for all the plans');
  //         }

  //         if (cartList?.length !== (Object.keys(paymentDuration)?.length)) {
  //             errors.push('Please select payment duration for all the plans');
  //         }

  //         if (cartList?.length !== (Object.keys(packageDuration)?.length)) {
  //             errors.push('Please select package duration for all the plans');
  //         }

  //         if (cartList?.length !== (Object.keys(selectedDates)?.length)) {
  //             errors.push('Please select Date for all the plans');
  //         }

  //         if (!errors.length) {
  //         loginservice.subscriptionssave(request).then((res) => {
  //             if (res?.status && res?.data?.responseCode === '200') {
  //                 setSnackbarMessage(res?.data?.responseMessage, 'success');
  //                 deleteFromCart(null, 'all');
  //                 setShowAnchor(true);
  //             }
  //             else
  //                 setSnackbarMessage(res?.data?.responseMessage, 'error');
  //         });
  //         } else {
  //         setSnackbarMessage(errors.join(' and '), 'error');
  //         }

  //     };
  //     addSubscription();
  // };

  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [value, setValue] = useState("1");
  const [consent, setConsent] = useState();
  const [open, setOpen] = useState(false);
  const [screen, setscreen] = useState(window.innerWidth);
  const [effectivedate, setEffectivedate] = useState();

  const handleDailog = (data) => {
    const addSubscriptionnew = (e) => {
      // setEffectivedate(date);
      cartList.map((rec, index) => {
        if (rec.cartId === data.cartId) {
          const selectedPatientname = selectedMember[index];
          const selectedDate = formatDate(rec?.effectiveStartDate);
          console.log(selectedMember, "selectedDate");
          if (!selectedPatientname) {
            setSnackbarMessage("Please selected the member", "error");
          } else if (
            (rec?.planName === "Totalcare Gold Combo" ||
              rec?.planName === "Totalcare Platinum Combo") &&
            selectedPatientname.length < 2
          ) {
            setSnackbarMessage("Please selected two members", "error");
          } else if (selectedDate === "Invalid Date") {
            setSnackbarMessage(
              "Please select the effective start date",
              "error"
            );
          } else {
            setEffectivedate(selectedDate);
            const { address1, address2, city, pincode, state } =
              selectedPatientname;
            let fullAddress = `${address1}`;

            if (address2) {
              fullAddress += `, ${address2}`;
            }

            fullAddress += `, ${city}, ${state} , ${pincode}`;
            setSelectedMemberaddress(fullAddress);
            // setSelectedMemberaddress(selectedPatientname.address1,selectedPatientname.city,selectedPatientname.pincode,selectedPatientname.state);
            setOpen(true);
            let html = "";
            const loginObjStr = localStorage.getItem("loginObj");
            const loginObj = JSON.parse(loginObjStr);
            const userObj = loginObj?.[0];

            let data = userData.length
              ? JSON.parse(userData)
              : userObj
              ? userObj
              : "";
            console.log(data, "sdvsdhivusduivsduvdsvsd");
            const parser = new DOMParser();
            const doc = parser.parseFromString(
              subscriptionConsent,
              "text/html"
            );
            doc.querySelectorAll("i").forEach((el) => {
              if (
                el.innerHTML.includes("Subscriber") &&
                el.innerHTML.includes("Name")
              ) {
                el.innerHTML = el.innerHTML.replace(
                  /Subscriber[\s\S]*?Name/,
                  data[0].firstName
                );
              }
              if (el.innerHTML == "Auto Insert Date") {
                el.innerHTML = moment(new Date()).format("DD/MM/YYYY");
              }
              if (el.innerHTML == "insert address of the Subscriber") {
                el.innerHTML = "insert address of the Subscriber";
              }
            });
            const serializer = new XMLSerializer();
            html = serializer.serializeToString(doc);
            setSubscriptionConsent(html);
          }
        }
      });
    };
    addSubscriptionnew();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setIsConsentChecked(true);
    setOpen(false);
    convertToPDFAndSendToAPI();
  };
  const handleDisagree = () => {
    setIsConsentChecked(false);
    setOpen(false);
  };
  const handleConsentCheck = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  // useEffect(() => {
  //   if (selectedMember[0] && selectedMember[0].address1) {
  //     setSelectedMemberaddress(selectedMember[0].address1)
  //   }
  // }, [selectedMember]);

  // useEffect(() => {
  //   if (selectedMember[0]) {
  //     const { address1, address2, city, pincode, state } = selectedMember[0];
  //     let fullAddress = `${address1}`;

  //     if (address2) {
  //       fullAddress += `, ${address2}`;
  //     }

  //     fullAddress += `, ${city}, ${state} , ${pincode}`;
  //     setSelectedMemberaddress(fullAddress);
  //   }
  // }, [selectedMember]);

  const OnSaveClick = (data) => {
    const loginObjStr = localStorage.getItem("loginObj");
    const loginObj = JSON.parse(loginObjStr);
    const userObj = loginObj?.[0];
    const errors = [];
    let selectedPatient = [];
    const request = cartList
      .map((rec, index) => {
        if (rec.cartId === data.cartId) {
          const paymentType = paymentDuration[index];
          let price = careplanDetails.find(
            (res) => res.planId == data.planId
          ).price;
          let totalAmount =
            paymentType === "ONETIMEPAYMENT" ? 12 * price : price;
          console.log(paymentType, "selectedPatient", careplanDetails);
          const subsPeriod = "12"; // packageDuration[index] since this is the only option available
          if (data.planId == 10 || data.planId == 11) {
            selectedPatient = selectedMember[index];
          } else {
            selectedPatient = [selectedMember[index]];
          }

          const selectedDate = formatDate(rec?.effectiveStartDate);
          if (
            paymentType &&
            subsPeriod &&
            selectedPatient?.length &&
            !(selectedDate === "Invalid Date")
          ) {
            const isInvalidPlan =
              selectedPatient.every((res) => res.gender == "M") &&
              rec?.planName === "Womens Health Care";
            const isEclinicPresent = selectedPatient.every(
              (res) => res.eclinics == "Y"
            );
            if (isInvalidPlan) {
              errors.push(
                "Please ensure selected member qualifies for Women's Health Care for item "
              );
              return null;
            }
            if (!isEclinicPresent) {
              errors.push(
                "The member you've selected doesn't have eclinics assigned. Please try again after eclinics are assigned to them."
              );
              return null;
            }
            if (selectedPatient.length > 1) {
              let result = selectedPatient.map((res) => ({
                cpSeqId: rec?.planId,
                userId: userObj?.userSeqId,
                userCode: userObj?.userCode,
                patientCode: res.code,
                patientId: res.id,
                subsPeriod: subsPeriod,
                totalAmount: totalAmount,
                paymentType: paymentType,
                date: selectedDate,
              }));
              return { ...result };
            } else {
              return {
                cpSeqId: rec?.planId,
                userId: userObj?.userSeqId,
                userCode: userObj?.userCode,
                patientCode: selectedPatient[0].code,
                patientId: selectedPatient[0].id,
                subsPeriod: subsPeriod,
                totalAmount: totalAmount,
                paymentType: paymentType,
                date: selectedDate,
              };
            }
          } else {
            if (!selectedPatient.length) {
              console.log(selectedPatient, "selectedPatient1", selectedMember);
              errors.push("Please ensure you have selected the member ");
              // return null;
            }

            if (selectedDate === "Invalid Date") {
              errors.push("Please select the effective start date");
              // return null;
            }
          }
        } else {
          return null;
        }
        return null;
      })
      .filter((item) => item !== null);
    // const requestObj = { list: request };
    let requestObj = {};
    let requestObj1 = {};
    if (errors.length === 0) {
      if (data.planId == 10 || data.planId == 11) {
        requestObj = {
          list: [{ ...request[0]["0"], cf_consentform: bytecode }],
        };
        requestObj1 = {
          list: [{ ...request[0]["1"], cf_consentform: bytecode }],
        };
      } else {
        requestObj = {
          list: [{ ...request[0], cf_consentform: bytecode }],
        };
      }
    }

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    // window.dataLayer.push({
    //   currency: "USD",
    //   event: "begin_checkout",
    //   ecommerce: {
    //     value: totalAmount,
    //     items: [
    //       {
    //         cpSeqId: requestdata[0]?.cpSeqId,
    //         userId: requestdata[0]?.userId,
    //         userCode: requestdata[0]?.userCode,
    //         patientCode: requestdata[0]?.patientCode,
    //         patientId: requestdata[0]?.patientId,
    //         subsPeriod: requestdata[0]?.subsPeriod,
    //         // totalAmount: totalAmount,
    //         paymentType: requestdata[0]?.paymentType,
    //         date: requestdata[0]?.date,
    //       },
    //     ],
    //   },
    // });
    if (isConsentChecked === false) {
      errors.push("Please Read and Sign Consent Form");
    }
    if (errors.length === 0) {
      if (data.planId == 10 || data.planId == 11) {
        Promise.all([
          loginservice.subscriptionssave(requestObj),
          loginservice.subscriptionssave(requestObj1),
        ])
          .then((res) => {
            const consentFormUrl = res[0].data?.succesObject?.consentFormUrl;
            const consentFormUrl1 = res[1].data?.succesObject?.consentFormUrl;
            // deleteFromCart(data, "single");
            const responseDataString = JSON.stringify(data);
            localStorage.setItem("responseData", responseDataString);

            setResponseData(data);
            setShowAnchor(true);
            console.log(selectedPatient, "sdivhsdouvhgsdvdsvds");
            openCheckout(
              data,
              requestObj,
              selectedPatient,
              consentFormUrl,
              requestObj1,
              consentFormUrl1
            );
          })
          .catch((err) => {
            setSnackbarMessage(err, "error");
          });
      } else {
        loginservice.subscriptionssave(requestObj).then((res) => {
          if (res?.status && res?.data?.responseCode === "200") {
            const consentFormUrl = res?.data?.succesObject?.consentFormUrl;
            // deleteFromCart(data, "single");
            const responseDataString = JSON.stringify(data);
            localStorage.setItem("responseData", responseDataString);

            setResponseData(data);
            setShowAnchor(true);
            console.log(selectedPatient, "sdivhsdouvhgsdvdsvds");
            openCheckout(data, requestObj, selectedPatient, consentFormUrl);
          } else {
            setSnackbarMessage(res?.data?.responseMessage, "error");
          }
        });
      }
    } else {
      setSnackbarMessage(errors.join(" and "), "error");
      // }
    }
  };

  const onProceedClick = () => {
    setShowAnchor(false);
  };
  console.log(cartList, "cartList");
  return (
    <>
      {snackbarMessage}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
          <div className="relative">
            <div className="w-20 h-20 border-4 border-t-4 border-gray-200 rounded-full animate-spin" />
          </div>
        </div>
      )}

      {cartList?.length !== 0 ? (
        <div className="rounded-full bg-chathams-blue-200 hover:bg-seegreen absolute top-28 cursor-pointer left-0 ml-16 p-2 sm:block hidden">
          <ChevronLeftIcon
            sx={{ color: "white" }}
            onClick={() => {
              navigate(APP_ROUTES.BUYCAREPLAN);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="w-full ">
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          style={{ backgroundColor: "" }}
        >
          <Grid item xs={12} sm={6} md={8}></Grid>
        </Grid>
        {cartList?.length === 0 ? (
          <div>
            {isLoading === true ? (
              ""
            ) : (
              <div className=" rounded-md  bg-white  mx-auto flex flex-col items-center justify-center">
                <img className="w-[28rem] mt-[90px]" src={Img} alt="" />
                <div className=" text-gray-800 font-poppins text-[20px] font-semibold text-center">
                  Your cart is empty. Please add items to the cart.
                </div>
                {/* <div className="textgray-3 text-base  text-center">{message.message2}</div> */}
                <button
                  onClick={() => {
                    navigate(APP_ROUTES.BUYCAREPLAN);
                  }}
                  className={`mt-3 mb-6 py-2 px-4 text-white disabled:cursor-default rounded transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none font-poppins`}
                  style={{ background: "#66B889" }}
                >
                  {" "}
                  Continue Shopping
                </button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Grid
              container
              rowSpacing={2}
              sx={{
                display: "block",
                backgroundColor: "",
                marginTop: "2px",
                minHeight: "50vh",
                width: "100%",
              }}
            >
              {/* <Grid item xs={12} sm={8} md={8} sx={{ width: '100%', backgroundColor: "", display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <Grid container={'true'} item xs={12} spacing={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: "" }}> */}
              <Grid item xs={12} sm={12} md={12} sx={{ width: "100rem" }}>
                <div className="  flex flex-col justify-center items-start pl-4 sm:pl-0">
                  <span className="text-[20px] pb-4 font-bold text-black text-left sm:mt-[175px] mt-[98px] sm:ml-[64px] ml-[6px] ">
                    Your Cart
                  </span>
                  {/* <p className="text-lg mt-4 font-normal text-black text-center ">Continue Shopping</p> */}
                </div>

                {cartList && cartList.length > 0 && memberDetails?.length && (
                  <div className="flex flex-col justify-center items-start pl-4 sm:pl-0">
                    <span className="text-md text-black mb-2 pb-4 font-bold sm:ml-[66px] ml-[7px]">
                      Please note that the price will be converted from USD to
                      INR during checkout in compliance with RBI guidelines.
                    </span>
                  </div>
                )}

                <div>
                  {" "}
                  {memberDetails?.length > 0 ? (
                    <></>
                  ) : (
                    <div className="sm:flex sm:float-right text-center sm:text-left">
                      <h4>
                        You have not added any Care Receiver. Please add a
                        member.
                      </h4>
                      <button
                        onClick={() => {
                          navigate(APP_ROUTES.ADDMEMBER);
                        }}
                        className={`  sm:-mt-1 ml-2 py-2 px-4 text-white disabled:cursor-default rounded transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none font-poppins`}
                        style={{ background: "#66B889" }}
                      >
                        {" "}
                        Add Member
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col justify-center items-center w-full">
                  {cartList?.map((rec, index) => {
                    const planDetails = careplanDetails.find(
                      (plan) => plan.planId === rec.planId
                    );
                    const allowsMultipleSelection =
                      rec?.planName === "Totalcare Gold Combo" ||
                      rec?.planName === "Totalcare Platinum Combo";

                    return (
                      <card
                        sx={{
                          width: "87rem",
                          height: "auto",
                          marginBottom: "2rem",
                          borderRadius: "10px",
                          overflow: "visible",
                        }}
                        elevation={5}
                        variant="outlined"
                      >
                        <div
                          className="flex flex-col w-full justify-between items-center sm:p-4 sm:pt-[32px] sm:pr-[66px] pr-[10px] sm:pl-[50px] pl-[5px]"
                          style={{ zIndex: "1" }}
                          key={index}
                        >
                          <div className="flex items-center justify-between w-full mx-10 border-b-2 pb-3">
                            <div className=" sm:mx-3 flex sm:pl-1">
                              {rec?.planName === "General Health Care" ? (
                                <img src={general} alt="General Health" />
                              ) : rec?.planName === "Diabetes Care" ? (
                                <img src={diabatic} alt="Diabetics Care" />
                              ) : rec?.planName === "Cardiac Care" ? (
                                <img src={hearthealth} alt="Cardiac Care" />
                              ) : rec?.planName === "Ortho Care" ? (
                                <img src={ortho} alt="Ortho Care" />
                              ) : rec?.planName === "Womens Health Care" ? (
                                <img src={Womens} alt="Womens Health Care" />
                              ) : (
                                <></>
                              )}

                              <p className="mb-0 sm:text-[22px] text-[16px] font-bold text-left text-chathams-blue-500 sm:ml-4 ml-2">
                                {rec?.planName}
                              </p>
                            </div>
                            <p className="sm:text-[20px] text-[16px] text-left my-2  text-seegreen font-semibold ">{`Price: $${
                              paymentDuration[index] === "ONETIMEPAYMENT"
                                ? 12 * planDetails?.price
                                : paymentDuration[index] === "MONTHLY"
                                ? planDetails?.price
                                : ""
                            }`}</p>
                          </div>
                          <div className="flex flex-col sm:flex-row justify-start w-full sm:mx-6 py-4 border-b-2 sm:px-3 ">
                            <div className="flex items-center mb-2 ">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Payment Duration
                                <spam style={{ color: "#F34040" }}> * </spam>
                              </InputLabel>
                              <div className="w-full sm:w-auto ">
                                <TextField
                                  select
                                  required
                                  name="paymentDuration"
                                  onChange={(event) =>
                                    handlePaymentDurationChange(event, index)
                                  }
                                  sx={{ minWidth: "150px", maxWidth: "150px" }}
                                  size="small"
                                  value={paymentDuration[index] || ""}
                                >
                                  <MenuItem key="MONTHLY" value="MONTHLY">
                                    Monthly
                                  </MenuItem>
                                  <MenuItem
                                    key="ONETIMEPAYMENT"
                                    value="ONETIMEPAYMENT"
                                  >
                                    One-time payment
                                  </MenuItem>
                                </TextField>
                              </div>
                            </div>
                            <div className="flex items-center mb-2 sm:mx-4">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Package Duration
                                <spam style={{ color: "#F34040" }}> * </spam>
                              </InputLabel>
                              <div className="w-full sm:w-auto ">
                                <TextField
                                  select
                                  required
                                  name="packageDuration"
                                  onChange={(event) =>
                                    handlePackageDurationChange(event, index)
                                  }
                                  sx={{ width: "10rem" }}
                                  size="small"
                                  defaultValue="12"
                                >
                                  {/* <MenuItem key={1} value="6">
                                                                    6 Months
                                                                </MenuItem> */}
                                  <MenuItem key={2} value="12">
                                    12 Months
                                  </MenuItem>
                                </TextField>
                              </div>
                            </div>
                            <div className="flex items-center mb-2 sm:ml-3 sm:mr-6">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Select Member
                                <spam style={{ color: "#F34040" }}> * </spam>
                              </InputLabel>
                              {rec?.planId == 10 || rec?.planId == 11 ? (
                                <div className="w-full sm:w-auto ">
                                  <Select
                                    labelId="members-label"
                                    multiple={allowsMultipleSelection} // Use the same condition for multiple
                                    value={
                                      selectedMember[index] ||
                                      (allowsMultipleSelection ? [] : "")
                                    } // Support both array and string values
                                    onChange={(event) =>
                                      handleMemberChange(
                                        event,
                                        index,
                                        rec?.planId
                                      )
                                    }
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={(selected) =>
                                      allowsMultipleSelection ? (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                          }}
                                        >
                                          {selected.map((value) => (
                                            <Chip
                                              key={value.id}
                                              label={value?.name}
                                            />
                                          ))}
                                        </Box>
                                      ) : (
                                        selected?.name || ""
                                      )
                                    }
                                  >
                                    {memberDetails?.map((member) => (
                                      <MenuItem key={member.id} value={member}>
                                        {member?.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              ) : (
                                <div className="w-full sm:w-auto ">
                                  <TextField
                                    select
                                    required
                                    name="members"
                                    onChange={(event) =>
                                      handleMemberChange(
                                        event,
                                        index,
                                        rec?.planId
                                      )
                                    }
                                    sx={{ width: "10rem" }}
                                    size="small"
                                  >
                                    {memberDetails?.map((member) => (
                                      <MenuItem key={member.id} value={member}>
                                        {member?.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </div>
                              )}
                            </div>

                            <div className="flex items-center mb-2 sm:ml-3 sm:mr-6">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                  minWidth: "11rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Effective start date
                                <spam style={{ color: "#F34040" }}> *</spam>
                              </InputLabel>
                              <div className="w-full sm:w-auto ">
                                <DatePicker
                                  name="effectiveStartDate"
                                  selected={
                                    cartList[index]?.effectiveStartDate || null
                                  }
                                  onChange={(date) =>
                                    handleDateChange(date, index)
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={
                                    <input style={datePickerStyles.input} />
                                  }
                                  minDate={new Date()}
                                  maxDate={new Date().setDate(
                                    new Date().getDate() + 30
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-start items-start sm:pb-6 sm:pt-5 pt-4 sm:mr-[1000px] text-[#0B4881] font-semibold">
                            {isConsentChecked == true ? (
                              <input
                                className=" mr-3 mt-[3px]"
                                checked
                                type="checkbox"
                                // checked={isConsentChecked}
                                // onChange={handleConsentCheck}
                              />
                            ) : (
                              ""
                            )}
                            Read and Sign{" "}
                            <span
                              // onClick={() =>
                              //    handleDailog(cartList[index])
                              //   }
                              onClick={() => {
                                handleDailog(rec);
                              }}
                              className="text-seegreen font-bold underline cursor-pointer pl-1"
                            >
                              {" "}
                              Consent Form{" "}
                            </span>{" "}
                            {/* {consent?.attributes?.SecondComp?.name} */}
                          </div>

                          <div className=" flex sm:justify-start justify-between w-full sm:ml-6 mt-5 ">
                            <div className="mb-[50px] sm:mb-0">
                              <button
                                className="sm:w-40 w-auto px-8 sm:px-0"
                                style={{
                                  textTransform: "capitalize",
                                  backgroundColor: "#004171",
                                  color: "white",
                                  borderRadius: "20px",
                                  height: "2.5rem",
                                }}
                                onClick={() => {
                                  OnSaveClick(rec);
                                }}
                              >
                                Checkout
                              </button>
                            </div>
                            {/* <div>
                                                    <button className='sm:ml-7 sm:w-40 w-auto px-8 sm:px-0' style={{ textTransform: "capitalize", borderColor: "red", color: "red", borderWidth: 1, borderRadius: "8px", height: "2.5rem" }} onClick={() => deleteFromCart(rec, 'single')}>
                                                        Remove
                                                    </button>
                                                </div> */}

                            <div>
                              <button
                                className="sm:ml-7 sm:w-40 w-auto px-8 sm:px-0"
                                style={{
                                  textTransform: "capitalize",
                                  borderColor: "red",
                                  color: "red",
                                  borderWidth: 1,

                                  height: "2.5rem",
                                  border: "1px solid red",
                                  borderRadius: "20px",
                                }}
                                onClick={() => deleteFromCart(rec, "single")}
                                disabled={isLoading}
                              >
                                {isLoading ? "Removing..." : "Remove"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </card>
                    );
                  })}
                </div>

                {/* </Grid>
                        </Grid> */}
              </Grid>
              {/* <div className='flex gap-5 mt-3 sm:mt-0 p-7'>
<button className='text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 mb-20 rounded-md hover:text-white hover:border-seegreen hover:bg-seegreen font-semibold' onClick={() => navigate(APP_ROUTES.BUYCAREPLAN)}>Go Back</button>
                    </div> */}
              {/* <Grid item xs={12} sm={4} md={4} >
                        <Card sx={{ width: '100%', height: '100%', borderRadius: '7px', marginLeft: "rem" }} elevation={5} variant='outlined'>
                            <div className='p-4 flex flex-col '>
                                <p className='text-[20px] text-chathams-blue font-bold border-b-2 pb-4'>Payment Details</p>
                                <div className='flex flex-col'>
                                    {cartList?.map((e, index) => {
                                        const planDetails = careplanDetails.find(plan => plan.planId === e.planId);
                                        return (
                                            <div className='flex flex-row w-full items-center my-[4px] justify-between' key={index}>
                                                <p className='text-sm text-brand-lightBlack'>{e?.planName}</p>
                                                <p className='text-sm text-brand-lightBlack font-semibold'>
                                                    {paymentDuration[index] === 'ONETIMEPAYMENT' ?
                                                        `$${planDetails?.price} * ${packageDuration[index]} = $${planDetails?.price * packageDuration[index]}`
                                                        : `$${planDetails?.price}`}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='pt-6'>
                                    <div className='flex flex-row w-full items-center my-[4px] justify-between ' >
                                        <p className='text-sm text-brand-lightBlack'>Total</p>
                                        <p className='text-sm text-brand-lightBlack font-semibold'>{`$${totalAmount}`}</p>
                                    </div>
                                  
                                    <button style={{ textTransform: "capitalize", width: "100%", marginTop: "5rem", backgroundColor: "#3CBA84", color: "white", borderRadius: "8px", height: "2.5rem" }} onClick={() => { OnSaveClick() }}>
                                        Checkout
                                    </button>
                                   
                                   
                                </div>
                            </div>
                        </Card>
                    </Grid> */}
            </Grid>

            <Dialog
              visible={open}
              // position="top"
              position={isMobile() ? "top" : "center"}
              header={<div className="font-bold text-lg">Consent Form</div>}
              style={{ height: "500px" }}
              className="sm:w-4/5 w-full"
              onHide={handleClose}
              footer={
                <div className="sm:mx-10 sm:my-10 my-3 flex">
                  {/* <button
                  className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28" style={{ border: "1px solid #396DDD" }}
                  onClick={handleAgree}
                >
                  Agree
                </button>
                <button
                  className="text-chathams-blue-200 mr-4 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28 " style={{ border: "1px solid #396DDD" }}
                  onClick={handleDisagree}
                >
                  Disagree
                </button> */}

                  <button
                    className="btn-primary width-100 w-button w-[119px] h-[49px] "
                    style={{ paddingTop: "11px" }}
                    onClick={handleAgree}
                  >
                    Accept
                  </button>
                  <button
                    className="btn-primary width-100 w-button w-[119px] h-[49px]"
                    style={{ paddingTop: "11px" }}
                    onClick={handleDisagree}
                  >
                    Decline
                  </button>
                </div>
              }
              draggable={false}
              resizable={false}
            >
              <div>
                <div
                  className="sm:text-justify sm:w-auto w-fit"
                  ref={divRef}
                  id="contentContainer"
                  dangerouslySetInnerHTML={{ __html: subscriptionConsent }}
                />
              </div>
            </Dialog>
          </div>
        )}
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
};

export default Cart;
