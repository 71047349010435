import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, DrawerHeader, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';
import { APP_ROUTES } from '../../constants/approutes';
import { useNavigate } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import logo from "../homepage/image/logo.svg";
import curebayExperience from '../../../src/images/Curebay Experience.svg';
import Careplans from '../../../src/images/Care Plans.svg';
import services from '../../../src/images/Service Partners.svg';
import About from '../../../src/images/About CureBay.svg';


const Userprofilesidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutExpanded, setIsAboutExpanded] = useState(false);
  const[isServiceExpanded, setIsServiceExpanded]=useState(false);
  const [isPoliciesExpanded, setIsPoliciesExpanded] = useState(false);
  const drawerWidth = 240;
  const pluslogo = <div className='text-seegreen'><AddBoxIcon /></div> ;
  const MinusIconProfile =<div className='text-seegreen'><IndeterminateCheckBoxIcon /></div> ;

  const sideBardata = [
    {
      img:curebayExperience,
      text: 'Home',
      route: APP_ROUTES.DASHBOARD
    },
    {
      img:curebayExperience,
      text: 'CureBay Experience',
      route: APP_ROUTES.HOWITWORKS
    },
    {
      img:Careplans,
      text: 'Care Plans',
      expand: isAboutExpanded,
      routes: [
        { route: APP_ROUTES.GENERAL_HEALTH, text: 'General Health' },
       
        { route: APP_ROUTES.DIABETICS_CARE, text: 'Diabetes Care' },
        { route: APP_ROUTES.HEARTHEALTH_CARE, text: 'Cardiac Care' },
      
        { route: APP_ROUTES.WOMENSHEALTH_CARE, text: "Women's Health Care" },
        { route: APP_ROUTES.ORTHOPEDICS_CARE, text: "Ortho Care" },
      
      ]
    },
    {
      // img:services,
      // text: 'Services',
      // expand: isServiceExpanded,
      // routes: [
        route: APP_ROUTES.LOCATIONSPAGE, text: 'Location' },
       
        { route: APP_ROUTES.DOCTORS, text: 'Doctors' },
        { route: APP_ROUTES.ALLPARTNERS, text: 'Service Partners' },
      
        { route: APP_ROUTES.REVIEWS, text: 'Review' },
      
      // ]
    // },
   
    {
      img:About,
      text: 'About',
      expand: isAboutExpanded,
      routes: [
        { route: APP_ROUTES.ABOUTUS, text: 'About Us' },
       
        { route: APP_ROUTES.OURTEAM, text: 'Our Team' },
        { route: APP_ROUTES.WHYCUREBAY, text: 'Why CureBay' },
       
        { route: APP_ROUTES.MEDIA, text: 'Article, Blogs & Press' },
       
      ]
    },
    // {
    //   text: 'Consumer App',
    //   expand: isPoliciesExpanded,
    //   routes: [
    //     { route: APP_ROUTES.PRIVACY_POLICY, text: 'Privacy Policy' },
    //     { route: APP_ROUTES.TERMS_AND_CONDITION, text: 'Term & condition' },
    //     { route: APP_ROUTES.REFUND_POLICY, text: 'Return & Refund Policy' },
    //   ]
    // },
  ];

  const toggleAboutExpansion = () => {
    setIsAboutExpanded(!isAboutExpanded);
  };

  const togglePoliciesExpansion = () => {
    setIsPoliciesExpanded(!isPoliciesExpanded);
  };
  const toggleServiceExpansion = () => {
    setIsServiceExpanded(!isServiceExpanded);
  };
  


  const redirectTo = (e, location) => {
    e.preventDefault();
    setIsOpen(false);
    navigate(location);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  // const toggleSidebar = () => {
  //   setIsOpen((prevIsOpen) => {
  //     const newIsOpen = !prevIsOpen;
  
  //     if (newIsOpen === false) {
  //       // Reset the submenu state when the sidebar is closed
  //       setIsAboutExpanded(false);
  //     }
  
  //     return newIsOpen;
  //   });
  // };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  return (
    <div>
      <IconButton onClick={toggleSidebar} edge="start" color="inherit" aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
        <DrawerHeader sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <img className="h-10 ml-5 " src={logo} alt="curebay"></img>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton> */}
        </DrawerHeader>
        <Divider />


        <List>
  {sideBardata.map((item, idx) => (
    <React.Fragment key={idx}>
      {item.text !== 'Care Plans' && item.text !== 'About' && item.text !== 'Services' && (
        <ListItem button onClick={(e) => redirectTo(e, item.route)}>
          {/* <ListItemIcon>
            <img src={item.img} alt="Icon" />
          </ListItemIcon> */}
          <ListItemText
            primary={
              <div>
               
                <span className='font-bold text-chathams-blue'>{item.text}</span>
                {/* {isAboutExpanded ? MinusIconProfile : pluslogo} */}
              </div>
            }
            // primary={item.text}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={item.subtext}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </ListItem>
      )}
      {item.text === 'Care Plans' && (
        <>
          <ListItem button onClick={toggleAboutExpansion}>
          {/* <ListItemIcon>
                    <img src={item.img} alt="Icon" />
                  </ListItemIcon> */}
            <ListItemText
              primary={
                <div className='flex gap-16 '>
                 
                  <span className='font-bold text-chathams-blue'>{item.text}</span>
                  {isAboutExpanded ? MinusIconProfile : pluslogo}
                </div>
              }
              primaryTypographyProps={{ variant: 'body1' }}
              secondary=
              {
                <div >
                 
                  <span className='font-bold '>{item.subtext}</span>
                  
                </div>
              }
              secondaryTypographyProps={{ variant: 'caption' }}
            />
          </ListItem>
          {isAboutExpanded && (
            <List style={{ padding: '0px', margin: '0px',  }}>
              {item.routes.map((subItem, subIdx) => (
                <ListItem button key={subIdx} onClick={(e) => redirectTo(e, subItem.route)}>
                  <ListItemText
                  primary={
                    <div>
                        <span className='font-semibold'>{subItem.text}</span>
                  
                    </div>
                  }
                    // primary={subItem.text}
                    primaryTypographyProps={{ variant: 'body1' }}
                    style={{ paddingTop: '0', paddingBottom: '0' }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
      {/* {item.text === 'Services' && (
        <>
          <ListItem button onClick={toggleServiceExpansion}>
          <ListItemIcon>
                    <img src={item.img} alt="Icon" />
                  </ListItemIcon>
            <ListItemText
              primary={
                <div className='flex gap-20'>
                 
                  <span className='font-bold text-chathams-blue'>{item.text}</span>
                  {isServiceExpanded ? MinusIconProfile : pluslogo}
                </div>
              }
              primaryTypographyProps={{ variant: 'body1' }}
              secondary={item.subtext}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
          </ListItem>
          {isServiceExpanded && (
            <List style={{ padding: '0px', margin: '0px' }}>
              {item.routes.map((subItem, subIdx) => (
                <ListItem button key={subIdx} onClick={(e) => redirectTo(e, subItem.route)}>
                  <ListItemText
                    primary={
                      <div>
                          <span className='font-semibold'>{subItem.text}</span>
                    
                      </div>
                    }
                    // primary={subItem.text}
                    primaryTypographyProps={{ variant: 'body1' }}
                    style={{ paddingTop: '0', paddingBottom: '0' }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </>
      )} */}
      {item.text === 'About' && (
        <>
          <ListItem button onClick={togglePoliciesExpansion}>
          {/* <ListItemIcon>
                    <img src={item.img} alt="Icon" />
                  </ListItemIcon> */}
            <ListItemText
              primary={
                <div className='flex gap-24'>
                 
                  <span className='font-bold text-chathams-blue'>{item.text}</span>
                  {isPoliciesExpanded ? MinusIconProfile : pluslogo}
                </div>
              }
              primaryTypographyProps={{ variant: 'body1' }}
              secondary={item.subtext}
              secondaryTypographyProps={{ variant: 'caption' }}
            />
          </ListItem>
          {isPoliciesExpanded && (
            <List style={{ padding: '0px', margin: '0px' }}>
              {item.routes.map((subItem, subIdx) => (
                <ListItem button key={subIdx} onClick={(e) => redirectTo(e, subItem.route)}>
                  <ListItemText
                    primary={
                      <div>
                          <span className='font-semibold'>{subItem.text}</span>
                    
                      </div>
                    }
                    // primary={subItem.text}
                    primaryTypographyProps={{ variant: 'body1' }}
                    style={{ paddingTop: '0', paddingBottom: '0' }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </React.Fragment>
  ))}
</List>


      </Drawer>
    </div>
  );
};

export default Userprofilesidebar;
