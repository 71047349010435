import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import { APP_ROUTES } from "../../constants/approutes";
// import { Spin } from "antd";
// import { LoadingOutlined } from "@ant-design/icons";
import micIcon from "../../assets/images/mic_icon.svg";
import videoCallIcon from "../../assets/images/video_call.svg";
import endCallIcon from "../../assets/images/end_call.svg";
import otherUser from "../../assets/images/other_user.svg";
import micOffIcon from "../../assets/images/mic_off_icon.svg";
import cameraIcon from "../../assets/images/camera_icon.svg";
// import "./styles.css";
// import logo from "./agora-logo.svg";

export const VideoCall = () => {
  const [calling, setCalling] = useState(false);
  const isConnected = useIsConnected();
  // const [appId, setAppId] = useState("");
  const [channel, setChannel] = useState("");
  const [token, setToken] = useState("");
  const [uId, setUId] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const joinCode =
    queryParams.get("joinCode") || sessionStorage.getItem("joinCode");
  const navigate = useNavigate();
  const { state } = location;
  const appId = "8a19d64688084774b82663baf77b3e2f";
  useJoin(
    { appid: appId, channel: channel, token: token ? token : null, uid: uId },
    calling
  );
  //local user
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);
  //remote users
  const remoteUsers = useRemoteUsers();

  const setupAgora = async () => {
    try {
      const channelId = joinCode
        ? String(joinCode)
        : state?.id
        ? String(state.id)
        : "defaultChannel";
      const sanitizedChannelId = channelId.replace(/[^a-zA-Z0-9_-]/g, "");

      const response = await axios.get(
        `${process.env.REACT_APP_NEWBASEURL}token/generate?channelName=${sanitizedChannelId}&role=subscriber`
      );
      const token = response.data?.data?.token;
      const uuid = response.data?.data?.uid;
      if (!token || !appId || !sanitizedChannelId) {
        throw new Error("App ID, Token, or Channel ID is undefined");
      }
      sessionStorage.removeItem("joinCode");
      sessionStorage.removeItem("consultationType");
      setToken(token);
      setChannel(channelId);
      setUId(uuid);
    } catch (error) {
      console.error("Error setting up Agora:", error);
    }
  };

  useEffect(() => {
    if (token && channel && uId) {
      setCalling(true);
    }
  }, [token, channel, uId]);

  useEffect(() => {
    setupAgora();
  }, []);

  const getGridClass = () => {
    const totalUsers = remoteUsers.length + 1; // Local user + remote users
    if (totalUsers === 1) return "grid-cols-1";
    if (totalUsers === 2) return "grid-cols-1 md:grid-cols-2";
    if (totalUsers >= 3) return "grid-cols-1 md:grid-cols-2";
    return "grid-cols-3 grid-rows-2";
  };

  console.log(remoteUsers, "remoteUsers", isConnected, channel, calling);

  const endconsultation = () => {
    setCalling((a) => !a);
    navigate(APP_ROUTES.DASHBOARD);
  };

  return (
    <>
      <div className="room">
        {isConnected ? (
          <div
            className={`grid ${getGridClass()} gap-1 flex-1`}
            style={{ height: "85vh" }}
          >
            <div className="user">
              <LocalUser
                audioTrack={localMicrophoneTrack}
                cameraOn={cameraOn}
                micOn={micOn}
                videoTrack={localCameraTrack}
                cover={otherUser}
              >
                <samp className="user-name">You</samp>
              </LocalUser>
            </div>
            {remoteUsers.map((user) => (
              <div className="user" key={user.uid}>
                <RemoteUser cover={cameraIcon} user={user}>
                  <samp className="user-name">{user.uid}</samp>
                </RemoteUser>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-screen flex justify-center items-center">
            {/* <Spin indicator={<LoadingOutlined spin />} size="large" /> */}
          </div>
        )}
      </div>
      {isConnected && (
        <div className="flex justify-center bg-black bg-opacity-75 p-4">
          <button
            onClick={() => setMic((a) => !a)}
            className="text-white bg-white rounded-full p-3"
          >
            <img
              src={micOn ? micIcon : micOffIcon}
              alt="Mic Icon"
              className="w-8 h-8"
            />
          </button>
          <button
            onClick={() => setCamera((a) => !a)}
            className="text-white bg-white rounded-full p-3 mx-4"
          >
            <img
              src={!cameraOn ? cameraIcon : videoCallIcon}
              alt="Camera Icon"
              className="w-8 h-8"
            />
          </button>
          <button
            onClick={() => endconsultation()}
            className="text-white bg-red-500 rounded-full p-3"
          >
            <img src={endCallIcon} alt="End Call Icon" className="w-8 h-8" />
          </button>
        </div>
      )}
    </>
  );
};

export default VideoCall;
