import React from "react";

import { APP_ROUTES } from "../constants/approutes";
// import HomePage from "../components/homepage";

import { Route, Routes, Navigate } from "react-router-dom";
import AboutUs from "../components/aboutus/index";
import LocationsPage from "../components/locations";
import HowItWorks from "../components/howitworks";
import Reviews from "../components/reviews";
// import GeneralHealthCare from "../components/careplans/generalhealthcare.jsx";
import Ourgallery from "../components/aboutus/ourgallery";
import Doctors from "../components/doctors";
// import DiabeticsCare from "../components/careplans/diabeticscare";
// import WomensHealthCare from "../components/careplans/womenshealthcare";
import HeartHealthCare from "../components/careplans/hearthealthcare";
// import CancerScreeningCare from "../components/careplans/cancerscreening";
// import OrthopedicsCare from "../components/careplans/orthopedicscare";
import CarePlans from "../components/careplans";
import AllDoc from "../components/homepage/seeAllDoc";
import Doc from "../components/homepage/doctorProfile";
import Login from "../components/login";
import Signup from "../components/signup";
import ForgotPswd from "../components/forgotpassword";
import AddMember from "../components/signup/addmember";
import Subscriptionservices from "../components/dashboard/subscriptionservices";
import Appointments from "../components/dashboard/appointments";
import Buycareplan from "../components/signup/buycareplan";
import Dashboard from "../components/dashboard";
import Mydetails from "../components/myprofile/mydetails";
import MyHealthCards from "../components/myprofile/healthCards";
import Myplan from "../components/myprofile/myplan";
import Myreview from "../components/myprofile/myreview";
import Myhealth from "../components/myprofile/myhealthdetails";
import Myprescription from "../components/myprofile/myprescription";
import Mylabreports from "../components/myprofile/mylabreports";
import Myvitals from "../components/myprofile/myvitals";
import Ecgreport from "../components/myprofile/ecgreport";
import Memberdetails from "../components/myprofile/memberdetails";
import { checkIsLoggedIn } from "../store/actions/useractions";
import Cart from "../components/signup/cart";
import Faq from "../components/common/faq";
import PassChange from "../components/myprofile/changepswd";
import Seeallpartners from "../components/homepage/seeallpartners";
import EPRECRIPTION from "../components/myprofile/my-e-prescription";
import Healthrecords from "../components/dashboard/healthrecords";
import Ourteam from "../components/footerlinks/ourteam";
import Whycurebay from "../components/footerlinks/Whycurebay";
import Media from "../components/footerlinks/Media";
import Consent from "../components/login/consent";
import RFC from "../components/dashboard/requestChange";
import Schedule from "../components/dashboard/schedule";
// import HomePage from "../components/homepage/homepage";
import Userprofilesidebar from "../components/homepage/userProfilesidebar";
import AllPlans from "../components/homepage/allPlans";

// new page
import HomePage from "../components/webflow/homepagenew";
import CurebayExperienceNew from "../components/webflow/curebayexperiencenew";
import ServicePartnerNew from "../components/webflow/servicepartnernew";
import ReviewPageNew from "../components/webflow/reviewpagenew";
import GeneralHealthCare from "../components/webflow/Careplan";
import TotalcareGold from "../components/webflow/totalcaregold";
import TotalcarePlatinum from "../components/webflow/TotalcarePlatinum";
import DiabeticsCare from "../components/webflow/DiabeticCare";
import WomensHealthCare from "../components/webflow/WomensHealth";
import OrthopedicsCare from "../components/webflow/Orthopedic";
import CancerScreeningCare from "../components/webflow/Cardiac";
// import OrthopedicsCare from "../components/careplans/orthopedicscare";
import Totalcareellite from "../components/webflow/totalcareellite";
import Ourteamnew from "../components/webflow/ourteamnew";
import Ourblogsnew from "../components/webflow/ourblogsnew";
import Appoinmnentnew from "../components/webflow/appoinmnetnew";
import Eclinics from "../components/webflow/eclinics";

import CarePlannew from "../components/webflow/careplannew";
import DoctorNew from "../components/webflow/doctornew";
import DoctorSpeciality from "../components/webflow/doctorspeciality";
import Privacypolicy from "../components/webflow/privacypolicy";
import TnC from "../components/webflow/tearmsandcondition";
import Cancel from "../components/webflow/cancellation";
import Landingpage from "../components/webflow/landingpage";
import Landingpage2 from "../components/webflow/Landingpage2";
import Landingpagefooter from "../components/webflow/landingpagefooter";
import Notfound from "../components/webflow/notfound";
import Thankyou from "../components/webflow/Thankyou";
import Chart from "../components/webflow/chartlinetitan";
import VideoCall from "../components/dashboard/Videocall";

const OurRoutes = (props) => {
  return (
    <Routes>
      <Route exact path={APP_ROUTES.DASHBOARD} element={<HomePage />} />

      <Route
        exact
        path={APP_ROUTES.SIDEBAR}
        element={<Userprofilesidebar />}
      ></Route>
      <Route exact path={APP_ROUTES.ABOUTUS} element={<AboutUs />} />
      <Route exact path={APP_ROUTES.DOCTORS} element={<Doctors />} />
      <Route exact path={APP_ROUTES.HOWITWORKS} element={<HowItWorks />} />
      <Route
        exact
        path={APP_ROUTES.LOCATIONSPAGE}
        element={<LocationsPage />}
      />
      <Route
        exact
        path={APP_ROUTES.GENERAL_HEALTH}
        element={<GeneralHealthCare />}
      />
      <Route
        exact
        path={APP_ROUTES.DIABETICS_CARE}
        element={<DiabeticsCare />}
      />
      <Route
        exact
        path={APP_ROUTES.WOMENSHEALTH_CARE}
        element={<WomensHealthCare />}
      />
      <Route
        exact
        path={APP_ROUTES.HEARTHEALTH_CARE}
        element={<HeartHealthCare />}
      />
      <Route
        exact
        path={APP_ROUTES.ORTHOPEDICS_CARE}
        element={<OrthopedicsCare />}
      />
      <Route exact path={APP_ROUTES.CARE_PLANS} element={<CarePlans />} />
      <Route
        exact
        path={APP_ROUTES.CANCERSCREENING_CARE}
        element={<CancerScreeningCare />}
      />
      <Route exact path={APP_ROUTES.ALL_DOCTORS} element={<AllDoc />} />
      <Route exact path={APP_ROUTES.DOC} element={<Doc />} />
      <Route exact path={APP_ROUTES.LOGIN} element={<Login />} />
      <Route exact path={APP_ROUTES.SIGNUP} element={<Signup />} />
      <Route exact path={APP_ROUTES.FORGOTPSWD} element={<ForgotPswd />} />
      <Route exact path={APP_ROUTES.REVIEWS} element={<Reviews />} />
      <Route exact path={APP_ROUTES.OURGALLERY} element={<Ourgallery />} />
      <Route exact path={APP_ROUTES.FAQ} element={<Faq />} />
      <Route exact path={APP_ROUTES.ALLPARTNERS} element={<Seeallpartners />} />
      <Route exact path={APP_ROUTES.OURTEAM} element={<Ourteam />} />
      <Route exact path={APP_ROUTES.WHYCUREBAY} element={<Whycurebay />} />
      <Route exact path={APP_ROUTES.MEDIA} element={<Media />} />
      <Route exact path={APP_ROUTES.AllPlans} element={<AllPlans />} />

      <Route
        exact
        path={APP_ROUTES.MAINDASHBOARD}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MAINDASHBOARD}
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.BUYCAREPLAN}
        element={
          <PrivateRoute>
            <Buycareplan />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.ADDMEMBER}
        element={
          <PrivateRoute>
            <AddMember />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYDETAILS}
        element={
          <PrivateRoute>
            <Mydetails />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYPLAN}
        element={
          <PrivateRoute>
            <Myplan />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.PASSCHANGE}
        element={
          <PrivateRoute>
            <PassChange />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYREVIEW}
        element={
          <PrivateRoute>
            <Myreview />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.HEALTHRECORDS}
        element={
          <PrivateRoute>
            <Myhealth />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYPRESCRIPTION}
        element={
          <PrivateRoute>
            <Myprescription />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYLABREPORTS}
        element={
          <PrivateRoute>
            <Mylabreports />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYVITALS}
        element={
          <PrivateRoute>
            <Myvitals />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MEMBERDETAILS}
        element={
          <PrivateRoute>
            <Memberdetails />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.SUBSCRIPTIONSERVICES}
        element={
          <PrivateRoute>
            <Subscriptionservices />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.APPOINTMENTS}
        element={
          <PrivateRoute>
            <Appointments />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.CART}
        element={
          <PrivateRoute>
            <Cart />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.EPRECRIPTION}
        element={
          <PrivateRoute>
            <EPRECRIPTION />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYHEALTHRECORDS}
        element={
          <PrivateRoute>
            <Healthrecords />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.CONSENT}
        element={
          <PrivateRoute>
            <Consent />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.RFC}
        element={
          <PrivateRoute>
            <RFC />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYSCHEDULE}
        element={
          <PrivateRoute>
            <Schedule />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={APP_ROUTES.MYHCARDS}
        element={
          <PrivateRoute>
            <MyHealthCards />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={APP_ROUTES.VIDEOCALL}
        element={
          <PrivateRoute>
            <VideoCall />
          </PrivateRoute>
        }
      />

      {/* newpage */}
      <Route
        exact
        path={APP_ROUTES.CUREBAYEXPERIENCENEW}
        element={<CurebayExperienceNew />}
      />
      <Route
        exact
        path={APP_ROUTES.SERVICEPARTNERNEW}
        element={<ServicePartnerNew />}
      />
      <Route
        exact
        path={APP_ROUTES.REVIEWPAGENEW}
        element={<ReviewPageNew />}
      />
      <Route exact path={APP_ROUTES.CAREPLANNEW} element={<CarePlannew />} />
      <Route exact path={APP_ROUTES.OURTEANNEW} element={<Ourteamnew />} />
      <Route exact path={APP_ROUTES.NEWMEDIA} element={<Ourblogsnew />} />
      <Route
        exact
        path={APP_ROUTES.NEWAPPOINMENT}
        element={<Appoinmnentnew />}
      />
      <Route exact path={APP_ROUTES.ECLINICS} element={<Eclinics />} />
      <Route exact path={APP_ROUTES.DOCTORNEW} element={<DoctorNew />} />
      <Route
        exact
        path={APP_ROUTES.DOCTORSPECIALITY}
        element={<DoctorSpeciality />}
      />

      <Route
        exact
        path={APP_ROUTES.PRIVACYPOLICY}
        element={<Privacypolicy />}
      />
      <Route exact path={APP_ROUTES.TNC} element={<TnC />} />
      <Route exact path={APP_ROUTES.CANCEL} element={<Cancel />} />
      <Route exact path={APP_ROUTES.LANDINGPAGE} element={<Landingpage />} />
      <Route exact path={APP_ROUTES.LANDINGPAGE2} element={<Landingpage2 />} />
      <Route
        exact
        path={APP_ROUTES.LANDINGPAGEFOOTER}
        element={<Landingpagefooter />}
      />
      <Route exact path="*" element={<Notfound />} />
      <Route exact path={APP_ROUTES.THANKYOU} element={<Thankyou />} />
      <Route exact path={APP_ROUTES.CHART} element={<Chart />} />
    </Routes>
  );
};

const PrivateRoute = ({ children }) => {
  const auth = checkIsLoggedIn();
  return auth ? children : <Navigate to="/login" />;
};

export { OurRoutes };
