import services from "../../store/services/index";
import httpcommon from "../../store/services/httpcommon"
import axios from "axios";
class mydetailservice {
    getmyListapi(data){
    return httpcommon.post(
      "patient/view",data
    );
    };
    getmyLischildapi(data){
      return httpcommon.post(
        "childProfile/view",data
      );
      };
    getmyListupdateapi(data){
      return httpcommon.post(
        "childProfile/update",data
      );
      };
  

    updatePatientapi(data){
      // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
  return httpcommon.post(
    "patient/update",data
  );
  };

  getmypPrescriptionlist(data){
    return httpcommon.post(
      "prescription/getAll",data
    );
    };

    getmylablist(data){
      return httpcommon.post(
        "labReport/getAll",data
      );
      };
      downloadLabDoc(data){
        return httpcommon.post(
          "prescription/FileData/fileWithByteArray",data
        );
        };
        downloadLabDoc2(data){
          return httpcommon.post(
            "file/download",data
          );
          };
      getVitals(data){
        return httpcommon.post("dashboard/getVitals",data)
      }
      vitalsGet(data){
        return httpcommon.post("dashboard/vitals",data)
      }
      getVitalsRange(){
        return httpcommon.post("dashboard/getVitalsRange",{})
      }
      getpresDocName(data){
        return httpcommon.post("prescription/viewDetails",data)
      }
      getecgReport(data){
        return httpcommon.post("/ecg/list",data)
      }
}
export default new mydetailservice();
