import React from "react";
import "../../css/webflow.css";
import "../../css/curebay-medicare.webflow.css";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  getHowItWorksAction,
  healtchCareService,
  exportComparePlan,
  getBenifits,
  fetchGeneralHealth,
  healthCareService,
} from "../../store/actions/careplans";
import { getOurCareplans } from "../../store/actions/howitworks/index.jsx";
import { useSnackbar } from "../common/snackbar";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/webflow/footer";
import arrowicon from "../../webflowimages/arrow-right-1.svg";
import { APP_ROUTES } from "../../constants/approutes";
import { updateCart } from "../../store/actions/login";
import loginservice from "../../store/services/loginservice";
import { useNavigate } from "react-router-dom";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import star from "../../assets/images/star.png";

import totalcaregold from "../../assets/images/totalcareelite.png";
import tickcheck from "../../assets/images/tickcheck.png";
import packegebgimg from "../../assets/images/packegebgimg.png";
import { Carousel } from "primereact/carousel";

const responsiveOptions = [
  {
    breakpoint: "1199px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "991px",
    numVisible: 1,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 1,
    numScroll: 1,
  },
];

function Cardiac() {
  const userData = useSelector((state) => state.AuthReducer.userData);
  const [cardiacHealthContent, setCardiacHealthContent] = useState([]);
  const [generalHealthContent, setGeneralHealthContent] = useState([]);
  const [carePlans, setCarePlans] = useState([]);
  const [compareHealth, setCompareHealthCare] = useState([]);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [healthCareDetails, setHealthCareDetails] = useState([]);
  const navigate = useNavigate();
  const [compareHealth1, setCompareHealthCare1] = useState([]);
  const [compareHealth2, setCompareHealthCare2] = useState([]);
  const [compareHealth3, setCompareHealthCare3] = useState([]);
  const [compareHealth4, setCompareHealthCare4] = useState([]);
  const [generalHealth, setGeneralHealth] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);

  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj.parentCode
    : null;
  console.log(userCode);
  const [loggedin, setLoggedIn] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userCode) setLoggedIn(true);
  }, [userCode]);

  useEffect(() => {
    async function fetchHelthPlan() {
      const response = await healthCareService();
      if (response?.data?.data.length > 0) {
        setCardiacHealthContent(
          response?.data?.data?.filter((e) => e?.id === 6)?.[0]
        );
      }
    }
    fetchHelthPlan();
  }, []);

  const [activeTab, setActiveTab] = useState("yearly");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const monthlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.monthlys === "Y"
  );
  const quarterlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.quarterlys === "Y"
  );
  const hYearlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.halfYearlys === "Y"
  );
  const yearlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.yearlys === "Y"
  );
  const onDemandCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.onDemands === "Y"
  );

  const addToCart = async (e) => {
    if (!loggedin) {
      const data = {
        prop1: "Cardiac Care",
        prop2: 4,
        prop3: e === "monthly" || e === "yearly" ? e : undefined,
      };
      // navigate(APP_ROUTES.LOGIN)
      navigate(APP_ROUTES.LOGIN, { state: data });
    } else {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          value: 100,
          items: [
            {
              // userId: "",
              userCode: userCode,
              planId: 4,
              planName: "Cardiac Care",
              period: e === "monthly" || e === "yearly" ? e : undefined,
            },
          ],
        },
      });

      let data = JSON.parse(userData);
      let payload = {
        userCode: userCode,
        cartType: 1,
        cartVOList: [
          {
            userId: "",
            userCode: userCode,
            planId: 4,
            planName: "Cardiac Care",
            period: e === "monthly" || e === "yearly" ? e : undefined,
          },
        ],
      };

      const res = await updateCart(payload);
      if (res?.status === 200 && res?.data?.responseCode) {
        let userData = {
          userCode: data?.[0]?.userCode,
        };
        loginservice
          .cartList(userData)
          .then((res) => {
            dispatch({
              type: COMMONCONSTANTS.SUCCESS_FETCHCART,
              payload: res?.data?.succesObject,
            });
          })
          .catch((error) => {});
        setSnackbarMessage("Added to the cart", "success");
      }
    }
  };

  const navigateToPlans = async () => {
    if (!loggedin) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      navigate(APP_ROUTES.BUYCAREPLAN);
    }
  };

  const homePageContent = useSelector(
    (state) => state.HomePageReducer.homePageContent
  );

  useEffect(() => {
    async function fetchHelthCareData() {
      const resposne = await healtchCareService();
      if (resposne?.data?.data.length > 0) {
        setHealthCareDetails(resposne?.data?.data);
      }
    }

    async function fetchData() {
      const comparePlanResponse = await exportComparePlan(1);
      if (
        comparePlanResponse?.data?.responseCode === "200" &&
        comparePlanResponse?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare(comparePlanResponse?.data?.succesObject);
      }
      const comparePlanResponse1 = await exportComparePlan(3);
      if (
        comparePlanResponse1?.data?.responseCode === "200" &&
        comparePlanResponse1?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare1(comparePlanResponse1?.data?.succesObject);
      }
      const comparePlanResponse2 = await exportComparePlan(4);
      if (
        comparePlanResponse2?.data?.responseCode === "200" &&
        comparePlanResponse2?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare2(comparePlanResponse2?.data?.succesObject);
      }
      const comparePlanResponse3 = await exportComparePlan(6);
      if (
        comparePlanResponse3?.data?.responseCode === "200" &&
        comparePlanResponse3?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare3(comparePlanResponse3?.data?.succesObject);
      }
      const comparePlanResponse4 = await exportComparePlan(5);
      if (
        comparePlanResponse4?.data?.responseCode === "200" &&
        comparePlanResponse4?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare4(comparePlanResponse4?.data?.succesObject);
      }

      // const benefitsResponse = await getBenifits();
      // if (benefitsResponse?.status === 200 && benefitsResponse?.data?.data) {
      //   setBenefits(benefitsResponse.data.data[0]);
      // }
    }
    async function fetchGeneralHealthDetails() {
      const response = await fetchGeneralHealth();
      if (response?.data?.data) {
        setGeneralHealth(response?.data?.data);
      }
    }
    fetchData();
    fetchHelthCareData();
    fetchGeneralHealthDetails();
    dispatch(getHowItWorksAction());
  }, [dispatch]);

  const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(title === "Yearly" ? true : false);
    const handleOnClick = () => {
      setIsActive(!isActive);
      // setShow(!show)
    };

    return (
      <div
        className="accordion-item-wrapper v2 "
        style={{ display: "block", borderTop: "0" }}
      >
        {snackbarMessage}
        <div
          className="flex cursor-pointer justify-between items-center md:py-2 w-full"
          onClick={handleOnClick}
        >
          <div class="accordion-content-wrapper v2">
            {" "}
            <div class="accordion-header">
              {" "}
              <h2 class="accordion-title display-4">{title}</h2>
            </div>
          </div>
          <div className="md:flex justify-end md:text-3xl text-sm font-semibold  text-brand-primary sm:w-[44px]">
            {/* <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}> */}
            {isActive ? (
              <div className="accordion-side right-side">
                <div className="accordion-icon-wrapper">
                  <div className="accordion-btn-line v2 horizontal"></div>
                </div>
              </div>
            ) : (
              <div className="accordion-side right-side">
                <div className="accordion-icon-wrapper">
                  <div className="accordion-btn-line v2 vertical"></div>
                  <div className="accordion-btn-line v2 horizontal"></div>
                </div>
              </div>
            )}
            {/* </IconButton> */}
          </div>
        </div>
        {isActive ? <div className="w-full">{content}</div> : null}
      </div>
    );
  };

  const onReadMoreClick = (plan_id) => {
    switch (plan_id) {
      case 1:
        navigate(APP_ROUTES.GENERAL_HEALTH);
        break;
      case 4:
        navigate(APP_ROUTES.CANCERSCREENING_CARE);
        break;
      case 3:
        navigate(APP_ROUTES.DIABETICS_CARE);
        break;
      case 5:
        navigate(APP_ROUTES.ORTHOPEDICS_CARE);
        break;
      case 6:
        navigate(APP_ROUTES.WOMENSHEALTH_CARE);
        break;
      default:
        break;
    }

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [packagearrow, setpackagearrow] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };
  const [selectedPlan, setSelectedPlan] = useState("monthly");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const PlanCard = ({
    title,
    price,
    frequency,
    description,
    planType,
    isSelected,
    onSelect,
  }) => (
    // <div className="border-card-wrapper m-2 shadow-md">
    <div
      className={`border-card-wrapper3 m-2 shadow-md cursor-pointer ${
        isSelected ? "border-selected" : ""
      }`}
      onClick={onSelect}
    >
      <div className="border-card text-center flex justify-between">
        <div className="border-card-content text-left">
          <h3 className="font-medium sm:text-[26px] text-[17px] sm:mb-2 mb-0 text-[#373737]">
            <span className="mr-2">
              <img src={totalcaregold} className="sm:h-auto h-[18px]" />
            </span>
            {title}
          </h3>
          <h2 className="sm:text-2xl text-lg font-semibold  text-[#373737] mb-1">
            {price}
          </h2>
          <p className="sm:text-[16px] text-[12px] font-normal sm:mt-2 my-0 text-[#666666]">
            {description}
          </p>
        </div>
        <div className="border-card-right text-[#373737] sm:text-base text-[14px] font-medium ">
          {frequency}
        </div>
        {isSelected && (
          <div className="border-card-selected">
            <i
              className="fa fa-check selecteliteicon text-white text-2xl"
              aria-hidden="true"
            ></i>
          </div>
        )}
      </div>
    </div>
  );
  const BenefitItem = ({ title, frequency, type, children, star }) => (
    <div className="benefit-item">
      <div className="checkicon w-[7%]">
        {/* <FaCheck color="#00d084" /> */}
        {/* <i class="fa fa-check" aria-hidden="true"></i> */}
        <img src={tickcheck} className="w-6" />
      </div>
      <div className="w-[92%]">
        <h4 className="text-[#373737] font-medium sm:text-lg text-[15px]">
          {title}
          {star}
        </h4>
        <p className="text-[#666666] font-normal text-sm mb-1">
          Annual frequency: {frequency}
        </p>
        <p className="text-[#666666] font-normal text-sm mb-1">
          Service Type: {type}
          {children}
        </p>
      </div>
    </div>
  );

  const Accordion1 = ({ title, children, defaultOpen }) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
      if (defaultOpen) {
        setIsOpen(true);
      }
    }, [defaultOpen]);
    console.log(generalHealthContent?.attributes?.ourFeatures, "jjjj");
    return (
      <div>
        <div className="accordion-section" onClick={() => setIsOpen(!isOpen)}>
          <span>{title}</span>
          <span className="accordion-icon">
            {isOpen ? (
              <i class="fa fa-minus" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-plus" aria-hidden="true"></i>
            )}
          </span>
        </div>
        {isOpen && <div className="accordion-content">{children}</div>}
      </div>
    );
  };
  return (
    <>
      <div className="page-wrapper">
        {snackbarMessage}
        <div className="relative oldplandesign">
          <div className="position-relative ">
            <div className="half-bg-top package-single-bg-top h-[68vw]"></div>

            <div className="section hero about">
              <div className="container-default w-container">
                <div className="position-relative">
                  <div className="shape-image-container-hero">
                    <div className="inner-container _1128px center">
                      <div className="mg-bottom-64px">
                        <div
                          data-w-id="3bd12f2c-d7e9-9289-87b4-07cb88779680"
                          className="w-layout-grid grid-2-columns _1-col-tablet title-and-buttons"
                        >
                          <div
                            id="w-node-_3bd12f2c-d7e9-9289-87b4-07cb88779681-4d0e8e03"
                            className="inner-container _690px"
                          >
                            <h1
                              className="display-1 mg-bottom-0 white mt-0 "
                              data-aos="fade-up"
                            >
                              Totalcare Elite
                            </h1>
                          </div>
                          <br />
                          <div
                            id="w-node-_3bd12f2c-d7e9-9289-87b4-07cb88779681-4d0e8e03"
                            className="inner-container _690px"
                          >
                            <h1
                              className="text-4xl text-white font-medium mg-bottom-0 white mt-0 "
                              data-aos="fade-up"
                            >
                              {cardiacHealthContent?.attributes?.name}
                            </h1>
                          </div>

                          <div id="w-node-_3bd12f2c-d7e9-9289-87b4-07cb88779686-4d0e8e03"></div>
                        </div>
                      </div>
                      <div
                        className="w-layout-grid grid-2-columns hero-grid-image"
                        style={{ marginBottom: "0px" }}
                      >
                        <div className="inner-container _460px _100---tablet">
                          <p
                            id="w-node-_3bd12f2c-d7e9-9289-87b4-07cb8877968d-4d0e8e03"
                            data-w-id="3bd12f2c-d7e9-9289-87b4-07cb8877968d"
                            className=" text-white white text-left mb-4"
                            data-aos="fade-up"
                          >
                            {
                              cardiacHealthContent?.attributes?.firstcomp
                                ?.description
                            }
                          </p>
                          <div className="rich-text-block white w-richtext">
                            {cardiacHealthContent?.attributes?.SecondComp?.points?.map(
                              (rec, index) => (
                                <ul role="list">
                                  <li style={{ listStyle: "disc" }}>
                                    {rec?.points}
                                  </li>
                                </ul>
                              )
                            )}
                          </div>
                        </div>
                        <div
                          id="w-node-_62f914ff-393e-5110-fef7-e21aa35d4788-4d0e8e03"
                          className="sm:w-[668px]"
                        >
                          <div>
                            <img
                              src={
                                cardiacHealthContent?.attributes?.firstcomp
                                  ?.image_url
                              }
                              alt={"generalHealthImage"}
                              className=" w-full"
                              data-aos="fade-up"
                            />
                          </div>
                          <div className=" flex gap-2">
                            <div>
                              <img
                                src={
                                  cardiacHealthContent?.attributes?.firstcomp
                                    ?.image_url2
                                }
                                alt={"generalHealthImage"}
                                className="rounded-3xl mt-4"
                                data-aos="fade-up"
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div>
                              <img
                                src={
                                  cardiacHealthContent?.attributes?.firstcomp
                                    ?.image_url3
                                }
                                alt={"generalHealthImage"}
                                className="rounded-3xl mt-4"
                                data-aos="fade-up"
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div>
                              <img
                                src={
                                  cardiacHealthContent?.attributes?.firstcomp
                                    ?.image_url4
                                }
                                alt={"generalHealthImage"}
                                className="rounded-3xl mt-4"
                                data-aos="fade-up"
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="section pay ">
            <div className="container-default w-container">
              <div className="image-rounded-corners-wrapper _02">
                <div
                  data-w-id="ca1df06d-438f-e5e9-c5b8-716b4d8377f2"
                  className="card add-to-cart-card"
                  data-aos="fade-up"
                >
                  <p className="paybutton">Pay in Full</p>
                  {/* <div class=" cartprice">828.00 USD</div> */}
                  <div
                    data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"
                    class="display-3 mg-bottom-24px text-center"
                  >
                    1200.00&nbsp;USD
                  </div>
                  {/* <p className=" mg-bottom-16px">Just pay for 11 months. 1 month is on us. </p> */}
                  <div className="div-block-16">
                    <a
                      href="#"
                      className="btn-primary"
                      onClick={() => navigateToPlans()}
                    >
                      Buy Now
                    </a>
                    <a
                      href="#"
                      className="btn-secondary addcartbtn"
                      onClick={() => addToCart("yearly")}
                    >
                      Add to Cart
                    </a>
                  </div>
                </div>
                <div
                  data-w-id="dbf466ca-8f17-e78b-fa11-038c0e2b26a8"
                  className="card add-to-cart-card text-center"
                  data-aos="fade-up"
                >
                  <p className="paybutton">Pay Monthly</p>
                  {/* <div className="text-3xl mb-24"></div> */}
                  <div
                    data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"
                    class="display-3 mg-bottom-24px text-center"
                  >
                    100.00&nbsp;USD
                  </div>
                  <p className=" mg-bottom-16px">
                    Pay $100 every month for a year{" "}
                  </p>
                  <div className="div-block-16">
                    <a
                      href="#"
                      className="btn-primary"
                      onClick={() => navigateToPlans()}
                    >
                      Buy Now
                    </a>
                    <a
                      href="#"
                      className="btn-secondary addcartbtn"
                      onClick={() => addToCart("monthly")}
                    >
                      Add to Cart
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="">
          <img src={packegebgimg} className="packegebgimg" />
          <div className="max-w-4xl mx-auto my-8 p-4  bg-white">
            <h1 className="sm:text-5xl text-2xl font-bold text-center mb-2 text-[#373737]">
              Cardiac care plan
            </h1>
            <p className="text-center text-[#666666] sm:font-normal font-medium sm:text-3xl text-base sm:mb-8 mb-4 sm:mt-4">
              Don't wait for heart trouble. Get a personalized plan to prevent
              stroke and improve heart health.
            </p>
            <div className="grid sm:grid-cols-2 grid-cols-1 justify-between sm:gap-4 gap-0">
              <PlanCard
                title="Pay in Full"
                price="1200.00 USD"
                frequency="Yearly plan"
                // description="Effective ₹ 3300 monthly"
                planType="Yearly plan"
                isSelected={selectedPlan === "yearly"}
                onSelect={() => handleSelectPlan("yearly")}
              />
              <PlanCard
                title="Pay Monthly"
                price="100.00 USD"
                frequency="Monthly plan"
                description="Pay $100 every month for a year"
                planType="Monthly plan"
                isSelected={selectedPlan === "monthly"}
                onSelect={() => handleSelectPlan("monthly")}
              />
            </div>
          </div>
          <div className="bgclrdiv -mt-[120px]">
            <h5 className="text-[#00426F] font-bold sm:text-[40px] sm:text-2xl text-[22px] text-center sm:pt-[116px] pt-[100px]">
              Take a look at our services & benefits
            </h5>

            <div className="inner-container _1259px mx-auto sm:my-8 mb-8 p-4">
              <Accordion1
                title={
                  <span className="text-lg sm:text-[22px]">
                    Annual benefits
                  </span>
                }
                defaultOpen={true}
              >
                <div className="accodiandiv1">
                  {cardiacHealthContent?.attributes?.serviceType?.map(
                    (rec, index) =>
                      // Every third item should start a new row
                      index % 3 === 0 ? (
                        <div key={index} className="borderstyle">
                          <div className="grid sm:grid-cols-3 grid-cols-1 justify-between sm:gap-4 gap-1">
                            {cardiacHealthContent?.attributes?.serviceType

                              .slice(index, index + 3)
                              .map((rec, i) => (
                                <BenefitItem
                                  key={i}
                                  title={rec?.title}
                                  frequency={rec?.frequencyAnually}
                                  type={rec?.subTitle}
                                  star={
                                    rec?.star === true ? (
                                      <img
                                        src={star}
                                        alt="star"
                                        className="ml-1 sm:mb-2 sm:h-auto h-[13px]"
                                      />
                                    ) : (
                                      ""
                                    )
                                  }
                                />
                              ))}
                          </div>
                        </div>
                      ) : null
                  )}
                </div>
                <div className="accodiandiv2">
                  <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-4 gap-1">
                    {cardiacHealthContent?.attributes?.serviceType?.map(
                      (rec, index) => (
                        <div key={index} className="borderstyle">
                          <BenefitItem
                            title={rec?.title}
                            frequency={rec?.subTitle}
                            type={rec?.frequencyAnually}
                            star={
                              rec?.star === true ? (
                                <img
                                  src={star}
                                  alt="star"
                                  className="ml-1 sm:mb-2 sm:h-auto h-[13px]"
                                />
                              ) : (
                                ""
                              )
                            }
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Accordion1>
              <Accordion1
                title={
                  <span className="text-lg sm:text-[22px]">
                    Additional benefits
                  </span>
                }
              >
                <div>
                  {cardiacHealthContent?.attributes?.additionalBenefits?.map(
                    (rec, index) =>
                      // Every third item should start a new row
                      index % 3 === 0 ? (
                        <div key={index} className="borderstyle">
                          <div className="grid grid-cols-3 justify-between sm:gap-4 gap-1">
                            {cardiacHealthContent?.attributes?.additionalBenefits
                              .slice(index, index + 3)
                              .map((rec, i) => (
                                <BenefitItem
                                  key={i}
                                  title={rec?.title}
                                  frequency={rec?.subTitle}
                                  type={rec?.value}
                                  children={
                                    rec?.star === true ? (
                                      <i
                                        class="fa fa-star text-yellow-500 text-base ml-3"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      ""
                                    )
                                  }
                                />
                              ))}
                          </div>
                        </div>
                      ) : null
                  )}
                </div>
              </Accordion1>
              <Accordion1
                title={
                  <span className="text-lg sm:text-[22px]">
                    On demand services
                  </span>
                }
              >
                <div>
                  {cardiacHealthContent?.attributes?.onDemandServices?.map(
                    (rec, index) =>
                      // Every third item should start a new row
                      index % 3 === 0 ? (
                        <div key={index} className="borderstyle">
                          <div className="grid grid-cols-3 justify-between sm:gap-4 gap-1">
                            {cardiacHealthContent?.attributes?.onDemandServices
                              .slice(index, index + 3)
                              .map((rec, i) => (
                                <BenefitItem
                                  key={i}
                                  title={rec?.title}
                                  frequency={rec?.subTitle}
                                  type={rec?.value}
                                />
                              ))}
                          </div>
                        </div>
                      ) : null
                  )}
                </div>
              </Accordion1>

              <div className=" flex flex-col gap-2 p-2 text-[#373737] sm:mb-[40px]">
                <div>
                  <span className="text-red-500">*</span>{" "}
                  <span>GST as applicable 18%</span>
                </div>
                {/* <div>
                  <span className="text-red-500">*</span>{" "}
                  <span>
                    Combo packages are for 2 persons residing in the same house
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <h5
            className="
           text-[#00426F] font-bold sm:text-[34px] text-[22px] text-center"
          >
            Features that we offer
          </h5>

          <div className="steps-container grid sm:grid-cols-3 grid-cols-1 mt-[50px] sm:px-[100px] px-[15px] sm:gap-4 gap-1">
            {cardiacHealthContent?.attributes?.FourthComp?.content?.map(
              (step, index) => (
                <div key={index} className="flex px-[5px]">
                  <div className="step-number w-[14%]">
                    {" "}
                    <img
                      src={step?.image_url}
                      loading="lazy"
                      // width={50}
                      alt=""
                      className="icon sm:w-12 w-8"
                    />
                  </div>
                  <div className="step-content w-[86%]">
                    <h3 className="text-[#373737] sm:text-[24px] text-[18px] font-semibold">
                      {step.name}
                    </h3>
                    <p className="text-[#666666] sm:text-[17px] text-[16px] font-normal">
                      {step.description}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="container-default w-container oldplandesign">
            <div className="div-block-8" data-aos="fade-up">
              {cardiacHealthContent?.attributes?.FourthComp?.content?.map(
                (rec, index) => (
                  <div className="card features" style={{ display: "block" }}>
                    <img
                      src={rec?.image_url}
                      loading="lazy"
                      width="119"
                      alt=""
                      className="icon"
                    />
                    <div>
                      <h5 className=" my-5">{rec?.name}</h5>
                      <div className="tab-body">{rec?.description}</div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </section>
        <section className="">
          {/* <div className="w-full  block bg-[#F3F8FF] sm:pt-[232px] pt-[63px] sm:pb-[200px] pb-[20px]  rounded-[54px]"> */}
          <div className="w-full bg-[#F3F8FF] sm:pb-[70px] pb-[27px]">
            {/* <div
              id="w-node-d146676f-9cdc-23ae-2609-132ecbd5961e-4d0e8e42"
              data-w-id="d146676f-9cdc-23ae-2609-132ecbd5961e"
              className="specialities-slider-col-left sm:w-3/12 w-full"
            > */}
            {/* <div className="specialities-left-content sm:pl-[70px] pl-[15px] pr-[15px] sm:pr-[0px]">
                <div className="subtitle">Specialities</div> */}
            {/* <div className="inner-container _470px _100---tablet"> */}
            <h2 className=" text-[#025B91] text-center pt-[55px] pb-[15px]">
              Our features
            </h2>

            {/* </div> */}
            {/* </div> */}
            {/* <div className="half-bg-left specialities-left-col-bg" />
            </div> */}
            <div className="container-default z-index-1 w-container">
              <div className="carouseldiv2">
                <Carousel
                  value={
                    homePageContent?.data[0]?.attributes?.seccomp?.care_content
                  }
                  numVisible={1}
                  numScroll={1}
                  circular
                  responsiveOptions={responsiveOptions}
                  // autoplayInterval={3000}
                  showIndicators={false}
                  // orientation="horizontal"
                  // verticalViewPortHeight="360px"
                  itemTemplate={(rec, index) => (
                    <div
                      className="card blog-v1-card w-inline-block"
                      key={index}
                    >
                      <div className="card-picture-wrapper">
                        <img
                          src={rec?.image_url}
                          loading="lazy"
                          sizes="100vw"
                          // srcSet="images/Frame-9-p-500.png 500w, images/Frame-9.png 550w"
                          alt=""
                          className="image-3"
                        />
                      </div>
                      <div className="card-description blog-v1">
                        <h3 className="card-title-dark">{rec?.title}</h3>
                        <p className="color-neutral-600">{rec?.description}</p>
                        <h3
                          className="card-title-dark textcta cursor-pointer"
                          onClick={() => {
                            onReadMoreClick(rec?.plan_id);
                          }}
                        >
                          Explore More{" "}
                          <img
                            src={arrowicon}
                            loading="lazy"
                            alt=""
                            className="image-10"
                          />
                        </h3>
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="carouseldiv1 gap-4 pr-[10px] sm:pr-[0px]">
                {!homePageContent?.data[0]?.attributes ? (
                  ""
                ) : (
                  <Carousel
                    value={
                      homePageContent?.data[0]?.attributes?.seccomp
                        ?.care_content
                    }
                    numVisible={3}
                    numScroll={1}
                    // autoplay={false}
                    className="custom-carousel"
                    circular
                    responsiveOptions={responsiveOptions}
                    // autoplayInterval={3000}
                    showIndicators={false}
                    itemTemplate={(rec, index) => (
                      <div
                        className="card blog-v1-card w-inline-block"
                        key={index}
                      >
                        <div className="card-picture-wrapper">
                          <img
                            src={rec?.image_url}
                            loading="lazy"
                            sizes="100vw"
                            // srcSet="images/Frame-9-p-500.png 500w, images/Frame-9.png 550w"
                            alt=""
                            className="image-3"
                          />
                        </div>
                        <div className="card-description blog-v1">
                          <h3 className="card-title-dark">{rec?.title}</h3>
                          <p className="color-neutral-600">
                            {rec?.description}
                          </p>
                          <h3
                            className="card-title-dark textcta cursor-pointer"
                            onClick={() => {
                              onReadMoreClick(rec?.plan_id);
                            }}
                          >
                            Explore More{" "}
                            <img
                              src={arrowicon}
                              loading="lazy"
                              alt=""
                              className="image-10"
                            />
                          </h3>
                        </div>
                      </div>
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="relative oldplandesign">
            <div className="position-relative">
              <div className="half-bg-top package-single-bg-top h-[150vw]"></div>

              <div className="section hero about">
                <div className="container-default w-container hidden md:block">
                  <div>
                    {cardiacHealthContent ? (
                      <h1 className="text-white text-center text-5xl">
                        Cardiac Care Frequency
                      </h1>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="position-relative">
                    <div className="shape-image-container-hero">
                      <div className="inner-container _1128px center ">
                        <div className="bg-white rounded-3xl ">
                          {/* Header */}
                          <div className="grid grid-cols-2 gap-4 font-bold p-4 bg-[#F9F9F9] text-xl  rounded-l-3xl rounded-r-3xl shadow-sm ">
                            <div className="text-center p-2">Service Type</div>
                            <div className="text-center p-2">
                              Frequency Annualy
                            </div>
                          </div>

                          {/* Row 2 */}
                          {cardiacHealthContent?.attributes?.serviceType?.map(
                            (rec, index) => (
                              <div
                                className="grid grid-cols-2 gap-4 p-4 border-t justify-center"
                                key={index}
                              >
                                <div className="border-r p-2">
                                  <span className="text-[#004171] text-lg font-semibold">
                                    {rec?.title}
                                  </span>
                                  {rec?.star === true ? (
                                    <img
                                      src={star}
                                      alt="star"
                                      className="ml-1 mb-2"
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  <span className="text-sm">
                                    {rec?.subTitle}
                                  </span>
                                </div>
                                <div className=" border-r text-[#666E82] p-2 text-center text-sm">
                                  {rec?.frequencyAnually}
                                </div>
                              </div>
                            )
                          )}

                          {/* {HEADER2} */}
                          <div
                            className=" flex justify-between font-bold p-2 bg-[#F9F9F9] text-[#666E82] text-2xl  "
                            onClick={toggleAccordion}
                          >
                            <div className=" p-2">Additional Services</div>
                            <div className="p-2 cursor-pointer">
                              {" "}
                              <i
                                className={`fa ${
                                  isOpen
                                    ? "fa-chevron-circle-up  "
                                    : "fa-chevron-circle-down"
                                }`}
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>

                          {isOpen && (
                            <div className="mt-4">
                              {cardiacHealthContent?.attributes?.additionalBenefits?.map(
                                (rec, index) => (
                                  <div
                                    className="grid grid-cols-2 gap-4 p-2 border-b"
                                    key={index}
                                  >
                                    <div className="border-r p-2">
                                      {" "}
                                      <span className="text-[#004171] text-lg font-semibold">
                                        {rec?.title}
                                      </span>{" "}
                                      <br />
                                      <span className="text-sm">
                                        {rec?.subTitle}
                                      </span>
                                    </div>
                                    <div className=" text-[#666E82] p-2  text-sm flex justify-center items-center">
                                      {rec?.value}
                                      {rec?.star === true ? (
                                        <i
                                          class="fa fa-star text-yellow-500 text-base ml-3"
                                          aria-hidden="true"
                                        ></i>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}

                          {/* {HEADER3} */}

                          <div
                            className="mt-6 mb-10 flex justify-between  font-bold p-2 bg-[#F9F9F9] text-[#666E82] text-2xl rounded-b shadow-sm  "
                            onClick={toggleAccordion1}
                          >
                            <div className=" p-2">On Demand Services</div>
                            <div className="p-2 cursor-pointer">
                              {" "}
                              <i
                                className={`fa ${
                                  isOpen1
                                    ? "fa-chevron-circle-up"
                                    : "fa-chevron-circle-down"
                                }`}
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>

                          {isOpen1 && (
                            <div className="mt-4">
                              {cardiacHealthContent?.attributes?.onDemandServices?.map(
                                (rec, index) => (
                                  <div
                                    className="grid grid-cols-2 gap-4 p-2 border-b "
                                    key={index}
                                  >
                                    <div className="border-r p-4">
                                      {" "}
                                      <span className="text-[#004171] text-lg font-semibold">
                                        {rec?.title}
                                      </span>{" "}
                                      <br />
                                      <span className="text-sm">
                                        {rec?.subTitle}
                                      </span>
                                    </div>
                                    <div className=" text-[#666E82] p-2 text-center text-sm">
                                      {rec?.value}{" "}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className=" flex flex-col gap-2 p-2 ml-10 text-white mb-[40px] mt-4">
                        <div>
                          <span className="text-red-500">*</span>
                          <span>GST as applicable</span>
                        </div>
                        <div>
                          <span className="text-red-500">*</span>
                          <span>
                            Combo packages are for 2 persons residing in the
                            same house
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* For mobile */}

                <div className=" container-default w-container block md:hidden">
                  <div>
                    {/* {generalHealthContent ? ( */}
                    <h1 className="text-white text-center  font-bold text-3xl">
                      Cardiac Care Frequency
                    </h1>
                    {/* ) : (
                    ""
                  )} */}
                  </div>
                  <div>
                    <div className="position-relative">
                      <div className="shape-image-container-hero">
                        <div className="inner-container _1128px center ">
                          <div>
                            <div className=" flex flex-col gap-4 p-1  text-center items-center text-white mt-2 ">
                              <div className="bg-white rounded-lg text-start">
                                {cardiacHealthContent?.attributes?.serviceType?.map(
                                  (rec, index) => (
                                    <div className=" border-b p-2 " key={index}>
                                      <div className=" p-2">
                                        <span className="text-[#004171] text-lg font-semibold">
                                          {rec?.title}
                                        </span>
                                        {rec?.star === true ? (
                                          <img
                                            src={star}
                                            alt="star"
                                            className="ml-1 mb-2"
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <br />
                                        <div className="mt-2">
                                          <span className="text-sm text-[#666E82] mt-5">
                                            <span className="font-semibold">
                                              Service Type
                                            </span>
                                            -{rec?.subTitle}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="  text-[#666E82] p-2  text-sm">
                                        <span className="font-semibold">
                                          Frequency Annualy
                                        </span>
                                        - {rec?.frequencyAnually}
                                      </div>
                                      {/* <div className=" text-[#666E82] p-2 text-center text-sm">
                                {rec?.combo}
                              </div> */}
                                    </div>
                                  )
                                )}
                              </div>

                              <div
                                className=" flex flex-col justify-evenly  font-bold p-2 mt-2 bg-white text-[#666E82] text-2xl rounded-lg widthwebkit"
                                onClick={toggleAccordion}
                              >
                                <div className="flex justify-between">
                                  <div className=" p-1  text-[#004171]  text-[18px] mt-1">
                                    Additional Services
                                  </div>
                                  <div className=" ml-1 mr-1 mt-2 cursor-pointer ">
                                    {" "}
                                    <i
                                      className={`fa ${
                                        isOpen
                                          ? "fa-chevron-circle-up"
                                          : "fa-chevron-circle-down"
                                      }`}
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>

                                {isOpen && (
                                  <div className="mt-2 bg-[#F9F9F9] rounded-lg text-start">
                                    {cardiacHealthContent?.attributes?.additionalBenefits?.map(
                                      (rec, index) => (
                                        <div
                                          className="flex flex-col border-b p-2"
                                          key={index}
                                        >
                                          <div className="">
                                            {" "}
                                            <span className="text-[#004171] text-lg font-semibold">
                                              {rec?.title}
                                            </span>{" "}
                                            <br />
                                            <span className="text-[#004171] text-sm">
                                              {rec?.subTitle}
                                            </span>
                                          </div>
                                          <div className=" text-[#666E82] p-2 text-sm">
                                            {rec?.value}
                                            {rec?.star === true ? (
                                              <i
                                                class="fa fa-star text-yellow-500 text-base ml-3"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>

                              <div
                                className="mt-2 mb-4 flex flex-col justify-evenly  font-bold p-2 bg-white text-[#666E82] text-2xl rounded-lg shadow-sm widthwebkit "
                                onClick={toggleAccordion1}
                              >
                                <div className="flex justify-between">
                                  <div className=" p-1  text-[#004171] text-[18px] mt-1">
                                    On Demand Services
                                  </div>
                                  <div className=" ml-1 mr-1 mt-2 cursor-pointer ">
                                    {" "}
                                    <i
                                      className={`fa ${
                                        isOpen1
                                          ? "fa-chevron-circle-up"
                                          : "fa-chevron-circle-down"
                                      }`}
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                {isOpen1 && (
                                  <div className="mt-2 bg-[#F9F9F9] rounded-lg text-start">
                                    {cardiacHealthContent?.attributes?.onDemandServices?.map(
                                      (rec, index) => (
                                        <div
                                          className="flex flex-col border-b p-2"
                                          key={index}
                                        >
                                          <div className="">
                                            {" "}
                                            <span className="text-[#004171] text-lg font-semibold">
                                              {rec?.title}
                                            </span>{" "}
                                            <br />
                                            <span className="text-[#004171] text-sm mt-1">
                                              {rec?.subTitle}
                                            </span>
                                          </div>
                                          <div className=" text-[#666E82] p-2 text-sm">
                                            {rec?.value}{" "}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>

                              <div className=" flex flex-col text-start gap-2 p-2 text-white mb-[35px]">
                                <div>
                                  <span className="text-red-500">*</span>
                                  <span>GST as applicable</span>
                                </div>
                                <div>
                                  <span className="text-red-500">*</span>
                                  <span>
                                    Combo packages are for 2 persons residing in
                                    the same house
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="overflow-hidden oldplandesign">
          <div className="section pd-top-and-bottom-150px">
            <div className="w-layout-blockcontainer container-default w-container">
              <div className="text-center mg-bottom-40px">
                <h2 className="display-2 mg-bottom-0 center" data-aos="fade-up">
                  Additional Healthcare services
                </h2>
              </div>
              <div className="div-new" data-aos="fade-up">
                {cardiacHealthContent?.attributes?.FifthComp?.content?.map(
                  (rec, index) => (
                    <div key={index} className="card features">
                      <img
                        src={rec?.icon_url}
                        loading="lazy"
                        width="119"
                        alt=""
                        className="icon"
                      />
                      <div>
                        <h5 className="">{rec?.name}</h5>
                        <div className="tab-body">{rec?.description}</div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <section className="section oldplandesign">
          <div className="w-layout-blockcontainer container-default w-container">
            <div
              data-w-id="37dc0cea-e3bc-f50d-3c5b-991f96e383a7"
              className="heading-flex align-bottom"
            >
              <div
                id="w-node-_37dc0cea-e3bc-f50d-3c5b-991f96e383a8-4d0e8e03"
                className="inner-container _690px _100---tablet"
              >
                <h2 className="display-2 mg-bottom-0" data-aos="fade-up">
                  Healthcare Plans
                </h2>
              </div>
              <div id="w-node-_37dc0cea-e3bc-f50d-3c5b-991f96e383ad-4d0e8e03">
                <a
                  href={APP_ROUTES.CAREPLANNEW}
                  className="btn-secondary w-button"
                  data-aos="fade-up"
                >
                  Explore More
                </a>
              </div>
            </div>
            <div className="div-block" data-aos="fade-up">
              {homePageContent?.data[0]?.attributes?.seccomp?.care_content
                ?.length > 0 &&
                homePageContent?.data[0]?.attributes?.seccomp?.care_content.map(
                  (rec, index) => (
                    <div
                      className="card blog-v1-card w-inline-block"
                      key={index}
                    >
                      <div className="card-picture-wrapper">
                        <img
                          src={rec?.image_url}
                          loading="lazy"
                          sizes="100vw"
                          // srcSet="images/Frame-9-p-500.png 500w, images/Frame-9.png 550w"
                          alt=""
                          className="image-3"
                        />
                      </div>
                      <div className="card-description blog-v1">
                        <h3 className="card-title-dark">{rec?.title}</h3>
                        <p className="color-neutral-600">{rec?.description}</p>
                        <h3
                          className="card-title-dark textcta cursor-pointer"
                          onClick={() => {
                            onReadMoreClick(rec?.plan_id);
                          }}
                        >
                          Explore More{" "}
                          <img
                            src={arrowicon}
                            loading="lazy"
                            alt=""
                            className="image-10"
                          />
                        </h3>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </section>
        {isPopupOpen && selectedPlan && (
          <div className="popup">
            <div className="popup-content">
              <button className="close-button" onClick={handleClosePopup}>
                X
              </button>
              <div className="sm:flex gap-1">
                <div className="sm:w-[80%] sm:text-left">
                  <p className="text-[#666666] text-[15px] mb-0 font-normal">
                    Plan selected
                  </p>
                  <h1 className="sm:text-[28px] text-[20px] font-bold\ mb-0 mt-0 text-[#373737]">
                    Cardiac care plan
                  </h1>
                  <p className="text-[#373737] text-[18px] mb-0 font-medium">
                    {" "}
                    {selectedPlan === "monthly" ? "monthly" : "yearly"}
                  </p>
                  {/* <p>
                    {selectedPlan === "Single plan"
                      ? "₹ 1,000 per month"
                      : "₹ 1,800 per month"}
                  </p>
                  <p>
                    {selectedPlan === "Single plan"
                      ? "Monthly plan"
                      : "Yearly plan"}
                  </p> */}
                </div>
                <div className="sm:w-[20%]">
                  <div
                    className="border border-[#666E82] rounded-[96px] sm:w-[80%] bg-[#004171] text-white mt-[20px] "
                    onClick={() =>
                      selectedPlan === "monthly"
                        ? addToCart("monthly")
                        : addToCart("yearly")
                    }
                  >
                    <button className="text-base p-4 ">Add to Cart</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
}

export default Cardiac;
