import { CAREPLANS } from "../../constants/careplansconstants";
import services from "../../services";
import careplanservices from "../../services/careplansservices";

export const healthCareService = async () => {
    const res = careplanservices.getHealthPlan()
    return res;
}
export const healtchCareService = async () => {
    const res = careplanservices.gethelthCareServices()
    return res;
}
export const healtchCareServicecms = async () => {
    const res = careplanservices.getNewhealthcareplan()
    return res;
}
export const getHowItWorksAction = () => async (dispatch) => {
    const res = await careplanservices.getHowItWorks();
    try {
        dispatch({
            type: CAREPLANS.HOW_IT_WORKS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: CAREPLANS.HOW_IT_WORKS,
            payload: err
        });
    }
}

export const exploreHealthCareDetials = async () => {
    const res = careplanservices.exploreHealth();
    return res;
}

export const exportComparePlan = async (e) => {
    const res = careplanservices.compareAllPlan(e)
    return res;
}

export const getBenifits = async () => {
    const res = careplanservices.getBenifits()
    return res;
}
export const fetchWhyOurPlans = async () => {
    const res = careplanservices.getWhyPlansWorks()
    return res;
}
export const fetchOurHealthSol = async () => {
    const res = careplanservices.getHealthSol()
    return res;
}
export const fetchOurHeartHealth = async () => {
    const res = careplanservices.getHeartHealth()
    return res;
}
export const fetchOurDiabeticsCare = async () => {
    const res = careplanservices.getDiabeticsCare()
    return res;
}
export const fetchGeneralHealth = async () => {
    const res = careplanservices.getGeneraalHealth()
    return res;
}
export const fetchOrthopedicCare = async () => {
    const res = careplanservices.getOrthopediCare()
    return res;
}
export const fetchWomensHealth = async () => {
    const res = careplanservices.getWomensHealthCare()
    return res;
}
export const fetchCarePlan = async () => {
    const res = careplanservices.getCarePlan()
    return res;
}

