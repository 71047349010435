import React, { useEffect, useState } from "react";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { fetchvitals } from "../../store/actions/myprofile";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment/moment";
import { Box, MenuItem, Select, Tab, TablePagination } from "@mui/material";
import { fetchCarePlan, fetchUserList } from "../../store/actions/dashboard";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, Link } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { CalculateAge, formatDateToDDMMYYYY } from "../common";
import BloodPressure from "../../assets/images/BP icon.svg";
import Newtrends from "../../assets/images/new trends.svg";
import Dataupdate from "../../assets/images/data update.svg";

import temperature from "../../assets/images/Temp icon.svg";
import heartrate from "../../assets/images/Heart Rate icon.svg";
import pulse from "../../assets/images/Respiration Rate ixon.svg";
import heights from "../../assets/images/Height icon.svg";
import weights from "../../assets/images/weight.svg";
import bmi from "../../assets/images/BMI icon.svg";
import spo2 from "../../assets/images/SPo2 icon.svg";
import BloodGlucose from "../../assets/images/Blood Glucose ixon.svg";
import { IconButton as MuiIconButton } from "@mui/material";
import { FormHelperText, Modal, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch } from "react-redux";
import axios from "axios";
import { saveVitalsdata } from "../../store/actions/login";
import { useSnackbar } from "../common/snackbar";

function MyVitals() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let today = new Date();
  let startDate1 = new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000);
  today.setDate(today.getDate());
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj.parentCode
    : null;
  const isParentLoggedIn = userObj?.parentCode ? true : false;
  console.log(isParentLoggedIn, "xdfghj");
  const [myVitals, setMyVital] = useState();
  const [value, setValue] = React.useState(null);
  const [age, setAge] = React.useState(0);
  const [age1, setAge1] = React.useState(1);
  const [selectedFromDate, setSelectedFromDate] = useState(startDate1);
  const [selectedToDate, setSelectedToDate] = useState(today);
  const [isaddvitalOpen, setIsaddvitalOpen] = useState(false);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();

  const [value1, setValue1] = React.useState(1);

  function formatDateTime(date) {
    if (!(date instanceof Date)) {
      return "";
    }

    const formattedDate = dayjs(date).format("DD-MM-YYYY HH:mm:ss");

    return formattedDate;
  }

  useEffect(() => {
    async function fetchMyVitalsDetails() {
      const myVitals = await fetchvitals({
        // "patientCode": age,
        // "startDate": formatDateTime(selectedFromDate),
        // "endDate": formatDateTime(selectedToDate),
        patientCode: age ? age : userCode ? userCode : null,
        startDate: formatDateToDDMMYYYY(selectedFromDate),
        endDate: formatDateToDDMMYYYY(selectedToDate),
        // "planId": age1
      });
      if (myVitals.status === 200 && myVitals?.data) {
        setMyVital(myVitals?.data);
      }
    }
    fetchMyVitalsDetails();
  }, [selectedFromDate, selectedToDate, age1, age]);

  const [result_obj, setResult_obj] = useState({});
  const handleChange2 = (event) => {
    setAge1(event.target.value);
  };
  useEffect(() => {
    const temp = {};
    for (const key in myVitals?.succesObject?.vitalsDetails) {
      const selectedDate1 = moment(value?.$d).format("DD-MM-YYYY");
      myVitals?.succesObject?.vitalsDetails[key]?.forEach((element) => {
        // if (
        //   moment(element.givenDate, "DD-MM-YYYY HH:mm:ss").format(
        //     "DD-MM-YYYY"
        //   ) === selectedDate1
        // ) {
        if (key === "BP") {
          temp[key] = element.data;
        } else temp[key] = element.value;
        //    setResult_obj({ ...result_obj, key });
        // }
      });
    }
    setResult_obj(temp);
  }, [value, myVitals?.succesObject?.vitalsDetails]);

  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "0.875rem",
      lineHeight: "3",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
  };

  const [users, setUsers] = useState();
  const [care, setCare] = useState();
  const handleChange1 = (event) => {
    setAge(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };

  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userCode,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        setUsers(temp);
        setAge(temp[0]?.code);
      }
    }
    fetchUserDetails();
  }, []);

  // useEffect(() => {
  //   async function fetchCares() {
  //     const res = await fetchCarePlan({
  //       "userCode": userCode,
  //       "patientcode": age
  //     })
  //     if (res?.status === 200 && res?.data) {
  //       setCare(res?.data)
  //       setAge1(res?.data?.succesObject[0]?.id)
  //     }

  //   }
  //   fetchCares()
  // }, [age])

  const viewHealthGraph = (key, value) => {
    let props1 = {
      name: key,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      patient: age,
    };
    navigate(APP_ROUTES.MYHCARDS, { state: props1 }, { replace: true });
  };

  const breadcrumbItems = [
    { label: "Home" },
    { label: "My Details", onClick: () => navigate(APP_ROUTES.MYDETAILS) },
    { label: "Health Records" },
    { label: "My Vitals", url: "/details" },
  ];

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);

    if (selectedToDate && date > selectedToDate) {
      setSelectedToDate(null);
    }
  };

  const handleTodateChange = (date) => {
    if (selectedFromDate && date < selectedFromDate) {
      setSelectedFromDate(null);
    } else {
      setSelectedToDate(date);
    }
  };

  const getBPStatus = (bpValue) => {
    const [systolic, diastolic] = bpValue.split("/").map(Number);
    // Your BP status logic here
    if (systolic <= 120 && diastolic <= 80) {
      return "Normal";
    } else if (
      systolic > 120 &&
      systolic <= 139 &&
      diastolic >= 81 &&
      diastolic <= 89
    ) {
      return "Pre-hypertension";
    } else if (
      systolic >= 140 &&
      systolic <= 159 &&
      diastolic >= 90 &&
      diastolic <= 99
    ) {
      return "Stage-1 Hypertension";
    } else if (systolic > 159 && diastolic > 99) {
      return "Stage-2 Hypertension";
    } else {
      return " ";
    }
  };

  const handleOpenvital = () => {
    setIsaddvitalOpen(true);
  };
  const handleClose = () => {
    setIsaddvitalOpen(false);
  };

  const IconButton = styled(MuiIconButton)({
    backgroundColor: "white",
    borderColor: "#000 !important",
    borderWidth: "1px !important",
    border: "solid",
    color: "#000",
    width: "26px",
    height: "26px",
  });
  const [systolic, diastolic] = (result_obj?.BP) ? result_obj?.BP.split('/') :[undefined, undefined];

  const [addvitals, setaddvitals] = useState({
    patientCode: age,
    givenDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    createdBy: "",
    updatedBy: "",
    status: 1,
    height: "",
    weight: "",
    bloodGroup: "",
    temperature: "",
    systolic: "",
    diastolic: "",
    spo2: "",
    heartRate: "",
    pulseRate: "",
    respirationRate: "",
    bloodGlucose: "",
    bmi: 0,
    bp: 0,
    hdl: 0,
    ldl: 0,
    medication: "",
    notes: "",
    notes1: "",
    notes2: "",
    totalCholesterol: 0,
    triglycerides: 0,
    appointmentId: 0,
  });
  // console.log(userCode, userObj.userCode, age, "dxcfvgbhnj");

  const savevitals = async (e) => {
    console.log(userCode, userObj.userCode, age, "dxcfvgbhnj");
    e.preventDefault();
    const bmi = (
      addvitals.weight /
      ((addvitals.height / 100) * (addvitals.height / 100))
    ).toFixed(2);

    // if (
    //   addvitals?.height == undefined ||
    //   addvitals?.height == "" ||
    //   addvitals?.height == null ||
    //   addvitals?.height == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Height", "error");
    //   return;
    // } else if (
    //   addvitals?.weight == undefined ||
    //   addvitals?.weight == "" ||
    //   addvitals?.weight == null ||
    //   addvitals?.weight == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Weight", "error");
    //   return;
    // } else if (
    //   addvitals?.temperature == undefined ||
    //   addvitals?.temperature == "" ||
    //   addvitals?.temperature == null ||
    //   addvitals?.temperature == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Temperature", "error");
    //   return;
    // } else if (
    //   addvitals?.respirationRate == undefined ||
    //   addvitals?.respirationRate == "" ||
    //   addvitals?.respirationRate == null ||
    //   addvitals?.respirationRate == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Respiration Rate", "error");
    //   return;
    // } else if (
    //   addvitals?.systolic == undefined ||
    //   addvitals?.systolic == "" ||
    //   addvitals?.systolic == null ||
    //   addvitals?.systolic == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Systolic", "error");
    //   return;
    // } else if (
    //   addvitals?.diastolic == undefined ||
    //   addvitals?.diastolic == "" ||
    //   addvitals?.diastolic == null ||
    //   addvitals?.diastolic == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Diastolic", "error");
    //   return;
    // } else if (
    //   addvitals?.spo2 == undefined ||
    //   addvitals?.spo2 == "" ||
    //   addvitals?.spo2 == null ||
    //   addvitals?.spo2 == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Pulse Ox", "error");
    //   return;
    // } else if (
    //   addvitals?.heartRate == undefined ||
    //   addvitals?.heartRate == "" ||
    //   addvitals?.heartRate == null ||
    //   addvitals?.heartRate == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Heart Rate", "error");
    //   return;
    // } else if (
    //   addvitals?.bloodGlucose == undefined ||
    //   addvitals?.bloodGlucose == "" ||
    //   addvitals?.bloodGlucose == null ||
    //   addvitals?.bloodGlucose == "0"
    // ) {
    //   setSnackbarMessage("Please Enter Blood Glucose", "error");
    //   return;
    // }

    const payload = {
      patientCode: age,
      givenDate: addvitals.givenDate,
      createdBy: isParentLoggedIn ? age : userCode,
      updatedBy: isParentLoggedIn ? age : userCode,
      status: addvitals.status,
      height: result_obj.Height,
      weight: addvitals?.weight === "" || addvitals?.weight === 0 ? result_obj.Weight : addvitals?.weight ,
      bloodGroup: addvitals.bloodGroup,
      temperature: addvitals.temperature ==="" ||addvitals.temperature === 0 ?  result_obj.Temperature :  addvitals.temperature,
      systolic: addvitals.systolic === ""||  addvitals.systolic === 0 ? systolic : addvitals.systolic,
      diastolic: addvitals.diastolic === "" || addvitals.diastolic === 0 ? diastolic : addvitals.diastolic,
      spo2: addvitals.spo2 === "" || addvitals.spo2 === 0 ? result_obj.SPo2 : addvitals.spo2 ,
      heartRate: addvitals.heartRate === "" ||  addvitals.heartRate === 0 ?  result_obj.HeartRate : addvitals.heartRate,
      pulseRate: addvitals.pulseRate ,
      respirationRate: addvitals.respirationRate === "" ||  addvitals.respirationRate=== 0 ? result_obj.RespirationRate : addvitals.respirationRate,
      bloodGlucose: addvitals.bloodGlucose ==="" ||  addvitals.bloodGlucose=== 0? result_obj.BloodGlucose :addvitals.bloodGlucose,
      bmi: addvitals.weight && addvitals.height ? bmi : "",
      bp: addvitals.bp,
      hdl: addvitals.hdl,
      ldl: addvitals.ldl,
      medication: addvitals.medication,
      notes: addvitals.notes,
      notes1: addvitals.notes1,
      notes2: addvitals.notes2,
      totalCholesterol: addvitals.totalCholesterol,
      triglycerides: addvitals.triglycerides,
      appointmentId: addvitals.appointmentId,
    };

    const res = await saveVitalsdata(payload);
    if (res?.data?.responseCode === "200") {
      setSnackbarMessage("Vitals Added Successfully.", "success");
      setIsaddvitalOpen(false);
      window.location.reload();
    }
  };
  const handlevitalChange = (e) => {
    setaddvitals({ ...addvitals, [e.target.name]: e.target.value });
  };

  return (
    <div className="p-8 ">
      {snackbarMessage}
      <div className="flex items-center mb-5 mt-[100px]">
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
              {item.onClick ? (
                <a onClick={item.onClick}>{item.label}</a>
              ) : (
                <a>{item.label}</a>
              )}
            </span>
            {index !== breadcrumbItems.length - 1 && (
              <ArrowForwardIosIcon
                sx={{ fontSize: 15 }}
                className="text-chathams-blue-500 mx-2"
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="flex flex-col sm:flex-row justify-between ">
        <h2 className="text-xl font-bold my-5 w-full text-chathams-blue-500">
          {/* My Profile - My Vitals */}
        </h2>
        <div className=" sm:h-20 mb-2 flex items-center justify-start mr-[33rem] w-full rounded-md flex-col sm:flex-row">
          <div className="flex flex-col mr-4 mb-[16px]">
            <p className="text-chathams-blue font-semibold text-base">
              Member Profile
            </p>
            <Select
              value={age}
              onChange={handleChange1}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2 rem",
                width: "13rem",
                width: "100%",
                boxShadow: "0 2px 28px rgba(0,0,0,.1)",
              }}
            >
              {users &&
                users?.map((value, index) => (
                  <MenuItem value={value.code} key={index}>
                    <span className="text-base font-semibold">
                      {value.name}
                    </span>
                  </MenuItem>
                ))}
            </Select>
          </div>
          {/* <div className='flex flex-col '>
            <p className='text-chathams-blue font-semibold text-base'>Careplans</p>
            <Select
              value={age1}
              onChange={handleChange2}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ height: "2 rem", width: "13rem" }}
            >
              {care && care?.succesObject?.map((value, index) => (
                <MenuItem value={value.id} key={index}>
                  <p className='text-base font-semibold'>{value.name}</p>
                </MenuItem>
              ))}

            </Select>
          </div> */}
        </div>
      </div>
      <div className="flex scroll-px-2">
        <div className="w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
          <MyprofileSidemenu />
        </div>

        <div className="sm:w-3/4 w-full ml-4 border sm:p-8 p-3 rounded-md items-center flex flex-col">
          <div className="flex justify-between items-center w-full flex-col sm:flex-row">
            <div className="flex items-center flex-col sm:flex-row">
              <p className="text-md sm:pr-4 pt-4">Select Date</p>
              <div className="flex flex-col sm:pr-4">
                <p className="text-md">From</p>
                <DatePicker
                  selected={selectedFromDate}
                  onChange={handleFromDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<input style={datePickerStyles.input} />}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-md">To</p>
                <DatePicker
                  selected={selectedToDate}
                  onChange={handleTodateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<input style={datePickerStyles.input} />}
                  minDate={selectedFromDate}
                />
              </div>
            </div>

            <p
              className="font-medium text-sm text-[#005D8D] cursor-pointer"
              onClick={() => handleOpenvital()}
            >
              <i className="fa fa-plus addvital" aria-hidden="true"></i> Add New
              Vitals
            </p>
          </div>

          <Grid container spacing={2} sx={{ marginTop: "20px" }}>
            {Object.entries(result_obj).map(([key, value2]) => (
              <>
                {" "}
                {key == "BloodGlucose" ? (
                  ""
                ) : (
                  <Grid item xs={12} sm={6} md={4} key={key}>
                    <Card
                      sx={{
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 28px",
                        width: "100%",
                        paddingX: "2%",
                        borderRadius: "7px",
                        padding: "8px 12px",
                        height: "169px",
                      }}
                    >
                      <div class="flex justify-between align-center sm:mt-1">
                        <div>
                          <p class="text-black-900 font-semibold font-poppins text-lg ">
                            {key}
                          </p>
                          <div className="mt-2">
                            <h2 class="text-medium font-poppins font-medium text-2xl text-chathams-blue sm:mb-3">
                              {value2}
                              &nbsp;
                              {key == "Temperature" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  °F
                                </span>
                              ) : key == "HeartRate" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  mmHg
                                </span>
                              ) : key == "BloodGlucose" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center "></span>
                              ) : key == "SPo2" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  %
                                </span>
                              ) : key == "Height" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  cm
                                </span>
                              ) : key == "Weight" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  kg
                                </span>
                              ) : key == "RespirationRate" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  breath/min
                                </span>
                              ) : key == "BP" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  mmHg
                                </span>
                              ) : key == "BMI" ? (
                                <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                  kg/m2
                                </span>
                              ) : (
                                ""
                              )}
                            </h2>
                          </div>
                        </div>
                        <div>
                          {key == "Temperature" ? (
                            <img className="w-[30px]" src={temperature} />
                          ) : key == "HeartRate" ? (
                            <img className="w-[30px]" src={heartrate} />
                          ) : key == "BloodGlucose" ? (
                            <img className="w-[30px]" src={BloodGlucose} />
                          ) : key == "SPo2" ? (
                            <img className="w-[30px]" src={spo2} />
                          ) : key == "Height" ? (
                            <img className="w-[30px]" src={heights} />
                          ) : key == "Weight" ? (
                            <img className="w-[30px]" src={weights} />
                          ) : key == "RespirationRate" ? (
                            <img className="w-[34px]" src={pulse} />
                          ) : key == "BP" ? (
                            <img className="w-[30px]" src={BloodPressure} />
                          ) : key == "BMI" ? (
                            <img className="w-[30px]" src={bmi} />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <hr
                        style={{ borderColor: "#CDE6FF", borderWidth: "1px" }}
                      />

                      <div className="flex justify-between align-center mt-3 sm:mb-4">
                        <div className="flex align-center">
                          {key == "HeartRate" ? (
                            <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {value2 >= 60 && value2 <= 100 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Normal)
                                </span>
                              ) : value2 >= 101 && value2 <= 119 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Abnormal)
                                </span>
                              ) : value2 >= 120 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Critical)
                                </span>
                              ) : null}
                            </span>
                          ) : key == "Temperature" ? (
                            <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {value2 >= 97 && value2 <= 99 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Normal)
                                </span>
                              ) : (value2 < 97 && value2 >= 94) ||
                                (value2 >= 100 && value2 <= 106) ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Abnormal)
                                </span>
                              ) : value2 > 106 || value2 < 94 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Critical)
                                </span>
                              ) : null}
                            </span>
                          ) : key == "SPo2" ? (
                            <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {value2 >= 95 && value2 <= 100 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Normal)
                                </span>
                              ) : value2 >= 85 && value2 <= 94 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Abnormal)
                                </span>
                              ) : value2 < 85 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Critical)
                                </span>
                              ) : null}
                            </span>
                          ) : key == "RespirationRate" ? (
                            <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {value2 >= 12 && value2 <= 18 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Normal)
                                </span>
                              ) : value2 >= 19 && value2 <= 25 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Abnormal)
                                </span>
                              ) : value2 > 25 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Critical)
                                </span>
                              ) : null}
                            </span>
                          ) : key == "BMI" ? (
                            <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {value2 >= 18.5 && value2 <= 25 ? (
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Normal)
                                </span>
                              ) : value2 >= 25.1 && value2 <= 29.9 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Abnormal)
                                </span>
                              ) : value2 >= 30 && value2 <= 35 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Obese)
                                </span>
                              ) : value2 > 35 ? (
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}
                                >
                                  (Morbidly Obese)
                                </span>
                              ) : null}
                            </span>
                          ) : key == "BP" ? (
                            <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {value2.split("/").length === 2 ? (
                                // Call the function to calculate BP status
                                getBPStatus(value2) === "Normal" ? (
                                  <span
                                    style={{
                                      color: "green",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    (Normal)
                                  </span>
                                ) : getBPStatus(value2) ===
                                  "Pre-hypertension" ? (
                                  <span
                                    style={{
                                      color: "orange",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    (Pre-hypertension)
                                  </span>
                                ) : getBPStatus(value2) ===
                                  "Stage-1 Hypertension" ? (
                                  <span
                                    style={{
                                      color: "red",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    (Stage-1 Hypertension)
                                  </span>
                                ) : getBPStatus(value2) ===
                                  "Stage-1 Hypertension" ? (
                                  <span
                                    style={{
                                      color: "purple",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                  >
                                    (Stage-2 Hypertension)
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : null}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <button
                          onClick={() => {
                            viewHealthGraph(key, value2);
                          }}
                          style={{ fontSize: "16px", fontWeight: "600" }}
                          className=" flex update-font text-seegreen  font-semibold "
                        >
                          {" "}
                          <img
                            src={Newtrends}
                            width="20px"
                            className="mr-2 cursor"
                          />{" "}
                          Track Progress
                        </button>
                      </div>
                    </Card>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </div>
      </div>
      <Modal open={isaddvitalOpen} onClose={handleClose}>
        <>
          <div
            className="fixed inset-0 flex items-center justify-center "
            style={{
              borderBottom: "1px solid #80808042",
              paddingBottom: "10px",
              marginBottom: "20px",
            }}
          >
            <div className="w-[79rem] sm:h-[30rem] h-[33rem] bg-white p-4 relative flex flex-col overflow-y-auto">
              <div className="w-full flex justify-between items-center">
                <h2 className="text-xl font-bold text-chathams-blue-500">
                  Add Vitals
                </h2>
                <IconButton onClick={handleClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="sm:px-4">
                <div className="grid sm:grid-cols-4 grid-cols-2  sm:gap-x-10 gap-x-4 gap-y-3">
                  {/* <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Height<spam style={{ color: "#F34040" }}> * </spam>
                    </InputLabel>

                    <TextField
                      fullWidth
                      size="small"
                      id="height"
                      name="height"
                      type="text"
                      value={addvitals.height}
                      onChange={handlevitalChange}
                      placeholder="Type here"
                      className="text-[#000]"
                      inputProps={{ maxLength: 6 }}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              cm
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div> */}
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Weight
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="weight"
                      name="weight"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      value={addvitals.weight}
                      onChange={handlevitalChange}
                      inputProps={{ maxLength: 6 }}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              kg
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Temperature
                     
                      {addvitals.temperature && (
                        <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          {addvitals.temperature >= 97 &&
                          addvitals.temperature <= 99 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              (Normal)
                            </span>
                          ) : (addvitals.temperature < 97 &&
                              addvitals.temperature >= 94) ||
                            (addvitals.temperature >= 100 &&
                              addvitals.temperature <= 106) ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              (Abnormal)
                            </span>
                          ) : addvitals.temperature > 106 ||
                            addvitals.temperature < 94 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Critical)
                            </span>
                          ) : null}
                        </span>
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="temperature"
                      name="temperature"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      inputProps={{ maxLength: 6 }}
                      value={addvitals.temperature}
                      onChange={handlevitalChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              °F
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Respiration Rate
                    
                      {addvitals.respirationRate && (
                        <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          {addvitals.respirationRate >= 12 &&
                          addvitals.respirationRate <= 18 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              (Normal)
                            </span>
                          ) : addvitals.respirationRate >= 19 &&
                            addvitals.respirationRate <= 25 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              (Abnormal)
                            </span>
                          ) : addvitals.respirationRate > 25 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              (Critical)
                            </span>
                          ) : null}
                        </span>
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="respirationRate"
                      name="respirationRate"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      inputProps={{ maxLength: 6 }}
                      value={addvitals.respirationRate}
                      onChange={handlevitalChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              breaths/min
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Blood Pressure (Systolic)
                    
                      {addvitals.systolic && (
                        <span className="font-montserrat text-xs font-normal ml-1">
                          {addvitals.systolic <= 120 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Normal
                            </span>
                          ) : addvitals.systolic > 120 &&
                            addvitals.systolic <= 139 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              Pre-hypertension
                            </span>
                          ) : addvitals.systolic >= 140 &&
                            addvitals.systolic <= 159 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Stage-1 Hypertension
                            </span>
                          ) : addvitals.systolic > 159 ? (
                            <span
                              style={{ color: "purple", fontWeight: "bold" }}
                            >
                              Stage-2 Hypertension
                            </span>
                          ) : null}
                        </span>
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="systolic"
                      name="systolic"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      inputProps={{ maxLength: 6 }}
                      value={addvitals.systolic}
                      onChange={handlevitalChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              mmHg
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Blood Pressure (Diastolic)
                     
                      {addvitals.diastolic && (
                        <span class="font-montserrat text-xs font-normal ml-1">
                          (
                          {addvitals.diastolic <= 80 ? (
                            <span class="text-green-500 font-bold">
                              (Normal)
                            </span>
                          ) : addvitals.diastolic >= 81 &&
                            addvitals.diastolic <= 89 ? (
                            <span class="text-yellow-500 font-bold">
                              (Pre-hypertension)
                            </span>
                          ) : addvitals.diastolic >= 90 &&
                            addvitals.diastolic <= 99 ? (
                            <span class="text-orange-500 font-bold">
                              (Stage-1 Hypertension)
                            </span>
                          ) : addvitals.diastolic > 99 ? (
                            <span class="text-red-500 font-bold">
                              (Stage-2 Hypertension)
                            </span>
                          ) : null}
                          )
                        </span>
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="diastolic"
                      name="diastolic"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      value={addvitals.diastolic}
                      onChange={handlevitalChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{ maxLength: 6 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              mmHg
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Pulse Ox.
                     
                      {addvitals.spo2 && (
                        <span class="font-montserrat text-xs font-normal ml-1">
                          (
                          {addvitals.spo2 >= 95 && addvitals.spo2 <= 100 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Normal
                            </span>
                          ) : addvitals.spo2 >= 85 && addvitals.spo2 <= 94 ? (
                            <span
                              style={{ color: "orange", fontWeight: "bold" }}
                            >
                              Abnormal
                            </span>
                          ) : addvitals.spo2 < 85 ? (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Critical
                            </span>
                          ) : null}
                          )
                        </span>
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="spo2"
                      name="spo2"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      value={addvitals.spo2}
                      onChange={handlevitalChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{ maxLength: 6 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              %
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Heart Rate
                    
                      {addvitals.heartRate && (
                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                          (
                          {addvitals.heartRate >= 60 &&
                          addvitals.heartRate <= 100 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Normal
                            </span>
                          ) : addvitals.heartRate >= 101 &&
                            addvitals.heartRate <= 119 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Abnormal
                            </span>
                          ) : addvitals.heartRate >= 120 ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              Critical
                            </span>
                          ) : null}
                          )
                        </span>
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="heartRate"
                      name="heartRate"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      value={addvitals.heartRate}
                      onChange={handlevitalChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{ maxLength: 6 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              Beats/min
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div sx={{ marginTop: "33px" }} item xs={12} sm={3} md={3}>
                    <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                      Blood Glucose
                    
                    </InputLabel>
                    <TextField
                      fullWidth
                      size="small"
                      id="bloodGlucose"
                      name="bloodGlucose"
                      type="text"
                      placeholder="Type here"
                      className="text-[#000]"
                      value={addvitals.bloodGlucose}
                      onChange={handlevitalChange}
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      inputProps={{ maxLength: 6 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2" color="textSecondary">
                              mg/dL
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="bg-[#004171] text-white sm:mt-14 mt-7 px-5 py-1 rounded-[20px]"
                    onClick={savevitals}
                  >
                    Save Data
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
}

export default MyVitals;
