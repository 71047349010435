import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Button, TablePagination } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dashboardservice from "../../store/services/dashboardservice";
import Profileimg from "../../images/video-call-icon.svg";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import mydetailsservice from "../../store/services/mydetailsservice";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import ROC from "./requestChange";
import { IconButton as MuiIconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useSnackbar } from "../common/snackbar";
import { styled } from "@mui/material/styles";
import phone from "../../assets/images/phone.svg";
import home from "../../assets/images/home visit.png";
import vdo from "../../assets/images/vdo.svg";

const IconButton = styled(MuiIconButton)({
  backgroundColor: "white",
  borderColor: "#66B889 !important",
  borderWidth: "1px !important",
  border: "solid",
  color: "#66B889",
  "&:hover": {
    backgroundColor: "#66B889",
    color: "white",
  },
});

//   console.log( createData('India', 'IN', 1324171354, 3287263))

const UpcomingAppointments = ({ user, start, end, planId }) => {
  const [close, setClose] = useState(false);
  const handleClose = () => {
    setClose(true);
  };

  const Chip = ({ value }) => {
    return (
      <>
        {value === "Completed" ? (
          <p className="w-24 rounded-md h-6 text-base font-semibold flex justify-center items-center bg-green-200 text-green-500">
            {value}
          </p>
        ) : value === "Appointment Booked" ? (
          <p className="w-52 rounded-md h-6 text-base font-semibold flex justify-center items-center bg-[#8eadd196] text-[#4d9cf8]">
            {value}
          </p>
        ) : value === "Yet to Start" || value === "Request for Update" ? (
          <p className="w-24 rounded-md h-6 text-base font-semibold flex justify-center items-center bg-yellow-100 text-yellow-300">
            {value}
          </p>
        ) : (
          <p className="w-24 rounded-md h-6 text-base font-semibold flex justify-center items-center">
            {value}
          </p>
        )}
      </>
    );
  };

  const Button1 = ({ value }) => {
    const [selectedItem, setSelectedItem] = useState();
    const [isROCOpen, setIsROCOpen] = useState();

    const handleRequestChange = (item) => {
      setSelectedItem(item);
      setIsROCOpen(true);
    };
    const handleCloseROC = async () => {
      setIsROCOpen(false);
    };
    return (
      <>
        <button
          variant="text"
          onClick={() => handleRequestChange(value)}
          style={{ margin: "23px 14px 11px 13px" }}
          className="  rounded-md  py-2 border text-[#0272DA] border-[#0272DA] text-blue-500 hover:bg-royal-blue hover:text-white  duration-300 text-xs sm:text-base font-semibold "
        >
          Request For Change
        </button>

        {isROCOpen && selectedItem ? (
          <ROC open={isROCOpen} props={selectedItem} onClose={handleCloseROC} />
        ) : null}
      </>
    );
  };
  const View = ({ value }) => {
    return (
      <>
        <p className="text-bluish text-base underline cursor-pointer ">
          {value}
          <KeyboardArrowDownIcon fontSize="small" />
        </p>
      </>
    );
  };

  const [url, setUrl] = useState("");

  console.log(start);
  console.log(end);
  const [patientData, setpatientData] = useState();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj.parentCode
    : null;
  const navigate = useNavigate();

  const videoCall = (e) => {
    setClose(false);
    const header = {
      secretKey: "CcJYfbsgItHpTQPFr5lg",
    };

    const data = {
      clientCode: "CCM@@202!",
      userType: "HOST",
      meetingKey: e.id,
      memberName: e.name,
      memberEmail: "anus@gmail.com",
      memberMobile: "1111114000",
    };
    axios
      .post(
        "https://cb.maggieplus.com/admin-api/client/meeting/authentication",
        data,
        { headers: header }
      )
      .then((result) => {
        setUrl(result.data.response.url);

        //  setTimeout(() => {
        document.getElementById("ccc").style.display = "block";
        //  }, 6000);
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };
  useEffect(() => {
    getUpcoming();
  }, [user, start, end, planId]);
  const getUpcoming = () => {
    let payload = {
      patientCode: user,
      userCode: userCode,
      planId: planId,
      appointmentType: 1,
      startDate: start,
      endDate: end,
    };
    dashboardservice.getappointment(payload).then((res) => {
      // if (start && end) {
      //     console.log('hii')
      //     const temp = res?.data?.succesObject?.filter(item => {
      //         const date = new Date(item?.whenAppointment)

      //         if (date >= start && date <= end) {
      //             console.log(item)
      //             return item
      //         }
      //     });
      //     console.log(temp)
      //     setpatientData(temp)
      // }
      // else
      setpatientData(res?.data?.succesObject);
      console.log(res?.data);
    });
  };
  console.log(patientData);

  const columns = [
    {
      id: "name",
      label: "Specialist Name",
    },
    {
      id: "whenAppointment",
      label: "Date",
    },
    {
      id: "combinedTime",
      label: "Scheduled Time",
    },
    {
      id: "consultationsType",
      label: "Consultation Type",
    },
    {
      id: "id",
      label: "Ref No.",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "changeRequest",
      label: "Change Request",
    },
    {
      id: "",
      label: "Video Consultation",
    },
  ];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const downloadReport = (e) => {
    let payload = {
      appointmentId: e.id,

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
    mydetailsservice
      .getpresDocName(payload)
      .then((res) => {
        if (res.data.succesObject[0]?.prescriptionDoc) {
          let docName = res.data.succesObject[0].prescriptionDoc;
          let payload1 = {
            fileName: docName,
          };
          mydetailsservice
            .downloadLabDoc(payload1)
            .then((res) => {
              let payload2 = {
                fileData: res?.data?.succesObject.fileData,
                fileName: "Test",
              };
              // Get the base64 string from your API response
              const base64String = payload2.fileData;

              // Convert the base64 string to a Blob object
              const byteCharacters = atob(base64String);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], {
                type: "application/octet-stream",
              });

              // Create a URL object from the Blob object
              const url = window.URL.createObjectURL(blob);

              // Create a link element and programmatically click it to initiate the file download
              const a = document.createElement("a");
              a.href = url;
              a.download = payload2.fileName;
              document.body.appendChild(a);
              a.click();
              a.remove();
            })
            .catch((error) => {
              console.error("Failed to fetch lab reports:", error);
            });
        } else {
          let epres = res?.data?.succesObject[0];
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: epres },
            { replace: true }
          );
        }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  console.log(patientData, "patienkjhjktData");
  return (
    <div className="">
      <p className="pb-5">
        Total No Of Appointment:{" "}
        <span className="font-bold">{patientData?.length} </span>{" "}
      </p>
      <div className="sm:grid-cols-3 grid-cols-1 grid gap-x-6 gap-y-8">
        {patientData &&
          patientData.map((value) => (
            <div
              className="rounded-md grid  border-2   pt-1 sm:pt-0"
              style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px" }}
            >
              <div className="flex justify-between ">
                <div className="text-center md:text-start  pl-[22px] pt-[24px] flex">
                  <i
                    class="fa fa-user-md text-[39px] text-[#2380ec] "
                    aria-hidden="true"
                  ></i>
                  <div className="pl-3 leading-7">
                    <p className="font-semibold text-[18px]">
                      {value.userSalutation} {value.name}
                    </p>
                  </div>
                </div>
                <div className="pr-6 pt-6  text-[15px]">
                  <p>({value?.userQualification})</p>
                </div>
              </div>
              <div className=" pt-3">
                <p className=" mb-4  text-[15px]  sm:pl-5 pl-2 ">
                  appointment date :{" "}
                  <span className=" text-[15px]  ">
                    {value.whenAppointment}
                  </span>
                </p>
                <p className=" mb-4  text-[15px]  sm:pl-5 pl-2">
                  Scheduled time :{" "}
                  <span className=" text-[15px]  ">
                    {" "}
                    {value?.fromTime}-{value?.toTime}
                  </span>
                </p>
                <p className="flex mb-4  text-[15px]   sm:pl-5 pl-2">
                  Status :&nbsp;
                  {value.status == 0 ? (
                    <Chip value="Expired" />
                  ) : value.status == 1 ? (
                    <Chip value="Yet to Start" />
                  ) : value.status === 2 ? (
                    <Chip value="Appointment Booked" />
                  ) : value.status === 4 ? (
                    <Chip value="Request for Update" />
                  ) : value.status === 5 ? (
                    <Chip value="Cancelled" />
                  ) : (
                    ""
                  )}
                </p>

                <p className="mb-4  text-[15px]   sm:pl-5 pl-2">
                  consultation type :{" "}
                  <span className="text-[15px]  ">
                    {" "}
                    {value.consultationsType == "V"
                      ? "Video Consultation"
                      : value.consultationsType == "H"
                      ? "Home Visit"
                      : value.consultationsType === "P"
                      ? "Phone Consultation"
                      : ""}
                  </span>
                </p>

                <p className="mb-4  text-[15px]   sm:pl-5 pl-2">
                  Reference No. :{" "}
                  <span className=" text-[15px]  "> {value.appointmentId}</span>
                </p>
              </div>{" "}
              <Button1 value={value} />
              <div className="flex justify-center w-full">
                {value.consultationsType == "V" ? (
                  <img
                    src={vdo}
                    className="rounded-md   relative sm:top-6 top-4"
                  />
                ) : value.consultationsType == "H" ? (
                  <img
                    src={home}
                    className=" rounded-md   relative sm:top-6 top-4"
                  />
                ) : value.consultationsType === "P" ? (
                  <img
                    src={phone}
                    className="rounded-md   relative sm:top-6 top-4"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
      </div>

      {url && !close && (
        <Dialog
          open={url}
          fullWidth={true}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": { width: 10 / 12, maxWidth: "100%" },
            },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="p-2 text-[40px] font-bold text-chathams-blue-500  ">
              Video Consultation
            </p>
            <IconButton onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <div id="videoframe">
              <div
                dangerouslySetInnerHTML={{
                  __html: `<iframe id="ccc" height="465" style="width: 100%;"  src=${url} allow="camera;microphone"></iframe>`,
                }}
              ></div>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default UpcomingAppointments;
